import React from "react";
import { useDispatch } from "react-redux";
import { ordersActions } from "../../../../redux/actions";

import styles from "./styles.module.scss";

const Payment = ( { formikProps } ) =>
{
  const dispatch = useDispatch();
  return (
    <section className={ styles.payment }>
      <p>Payment channel</p>

      <div>
        <label style={ { marginRight: "20px" } }>
          <input
            type="radio"
            name="payment.channel"
            value="CASH"
            onChange={ () =>
              dispatch( ordersActions.updateOrderValues( "channelId", "CASH" ) )
            }
          />
          &nbsp; Cash
        </label>

        <label style={ { marginRight: "20px" } }>
          <input
            type="radio"
            name="payment.channel"
            value="CARD"
            onChange={ () =>
              dispatch( ordersActions.updateOrderValues( "channelId", "CARD" ) )
            }
          />
          &nbsp; Card
        </label>

        <label style={ { marginRight: "20px" } }>
          <input
            type="radio"
            name="payment.channel"
            value="TRAN"
            onChange={ () =>
              dispatch( ordersActions.updateOrderValues( "channelId", "TRAN" ) )
            }
          />
          &nbsp; Transfer
        </label>


        <label style={ { marginRight: "20px" } }>
          <input
            type="radio"
            name="payment.channel"
            value="WALLET"
            onChange={ () =>
              dispatch( ordersActions.updateOrderValues( "channelId", "WALLET" ) )
            }
          />
          &nbsp; Wallet
        </label>
      </div>

      {/* <ErrorMessage component="small" name={`payment.channel`} /> */ }
    </section>
  );
};

export { Payment };
