import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Axios from "axios";

import { Dialog } from "evergreen-ui";
import { Form, Formik } from "formik";
import { cargoSchema, initialValues } from "./orderSchema";
import { /* customerActions,*/ ordersActions } from "../../../redux/actions";
import { addUpInsurance, formatCurrency } from "../../../utils";
import {
  Delivery,
  SenderReceiver,
  Items,
  Payment,
  Others,
  ExtraCharges,
} from "./components";
import styles from "./MakeOrder.module.scss";
import { useJsApiLoader } from "@react-google-maps/api";
import { uuid } from "uuidv4";
import { ordersTypes } from "../../../redux/actionTypes";
import { toast } from "react-hot-toast";

const configureState = (location) => {
  return {
    ...initialValues,
    delivery: {
      ...initialValues.delivery,
      dropOff: location,
    },
  };
};

const libraries = ["places"];
const MakeOrder = () => {
  const taxId = localStorage.getItem("pos-vat") || "VAT";

  const { isLoaded } = useJsApiLoader({
    id: "__google-map-script",
    googleMapsApiKey: "AIzaSyABwMHgoSSNIViPdd0268PtYgJ3xWJ9_ms",
    libraries,
  });

  const history = useHistory();
  const [showOrderModal, setShowOrderModal] = useState(false);

  const [showModal /*setShowModal */] = useState(false);
  const [showErr, setShowErr] = useState(false);
  const [modalErrMsg, setModalErrMsg] = useState("An error occurred");
  const [successMsg /*setSuccessMsg*/] = useState("");
  const [, /*proceed*/ setProceed] = useState(false);
  const [totalCharges, setTotalCharges] = useState(0);
  const [submitting, setSubmitting] = useState(false);
  const [createColl, setCreateColl] = useState(false);
  const [extraCharges, setExtraCharges] = useState([]);
  const [selectedCharges, setSelectedCharges] = useState([]);
  const { newOrder, chargeTypes } = useSelector((state) => state.orders);

  const [discountInfo, setDiscountInfo] = useState({
    status: "idle",
    discount: null,
    error: null,
  });

  const { profile, location } = useSelector((state) => state.user);
  const [totalAmount, setTotalAmount] = useState(0);
  const [discountCode, setDiscountCode] = useState("");

  const handleSelectedCharges = (list) => {
    setSelectedCharges(list);
  };

  const dispatch = useDispatch();

  const getTotalFreightPrice = (withFormatting = true) => {
    let totalAmount = 0;
    if (newOrder?.items?.length === 0) return formatCurrency(0);

    newOrder.items.map((item) => {
      totalAmount += item.total;
      return item;
    });

    if (withFormatting) {
      return formatCurrency(totalAmount);
    }

    return totalAmount;
  };

  const validateDiscount = async () => {
    if (discountCode) {
      setDiscountInfo({
        status: "pending",
        discount: null,
        error: null,
      });

      const token = localStorage.getItem("pos-token");

      try {
        const resp = await Axios.get(
          `https://api.abccargoxpress.com/api/v1/DiscountCodes/GetByCode?Code=${discountCode}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setDiscountInfo({
          status: "resolved",
          discount: resp?.data ? resp.data : null,
          error: null,
        });
      } catch (error) {
        setDiscountInfo({
          status: "rejected",
          discount: null,
          error: "Unable to verify discount code",
        });
      }
    }
  };

  const submitHandler = () => {
    setSubmitting(true);
    const {
      receiverLastName,
      receiverFirstName,
      receiverEmail,
      receiverPhoneNumber,
      receiverBusinessName,
    } = newOrder;

    const code =
      discountInfo.status === "rejected" ||
      !discountInfo?.discount ||
      discountInfo?.discount?.isUsed ||
      discountInfo?.discount?.isExpired
        ? undefined
        : discountInfo.discount.code;

    const items = newOrder.items
      .filter((it) => !!newOrder.insuranceIdList[it.itemId])
      .map((it) => ({
        ...it,
        insuranceId: newOrder.insuranceIdList[it.itemId],
      }));

    const body = {
      discountCode: code,
      customerId: newOrder.customerId,
      orderId: "string",
      amount: `${totalAmount}`,
      transactionId: "new",
      vehicleTypeId: "car_1",
      deliveryTypeId: newOrder.deliveryTypeId,
      serviceTypeId: newOrder.serviceTypeId,
      sourceId: newOrder.sourceId,
      channelId: newOrder.channelId,
      paymentTypeId: "Normal",
      items,
      originHubId: profile.hubId,
      destinationHubId: newOrder.destinationHubId,
      staffId: profile.id,
      chargeIds: selectedCharges,
      packagingCostId: newOrder.packagingCostId,
      //insuranceId: "Insurance_2",
      taxId,
      receiverLastName,
      receiverFirstName,
      receiverEmail,
      receiverBusinessName,
      receiverPhoneNumber,
      pickupLocation: newOrder.destinationHubAddress,
      deliveryLocation:
        newOrder.deliveryTypeId === "HH"
          ? newOrder.destinationHubAddress
          : newOrder.receiverAddress,
      totalKilometers:
        newOrder.deliveryTypeId === "HH"
          ? undefined
          : +localStorage.getItem("totalKilometers"),
      extraCharges: extraCharges,
    };

    //console.log(body);

    const onSuccess = (response) => {
      localStorage.removeItem("totalKilometers");
      const orderId = response.split(": ")[1];
      const declared = newOrder.items.reduce(
        (sum, item) => sum + item.declared,
        0
      );
      const collection = {
        id: uuid(),
        orderId,
        baseAmount: totalAmount,
        collectionAmount: declared,
        totalAmount: totalAmount + declared,
      };

      if (createColl) {
        dispatch(
          ordersActions.createCollection(
            collection,
            () => {
              setSubmitting(false);
              history.push(`/dashboard/receipt/${orderId}`);
              toast.success(
                "Created collection for order with ID = " + orderId,
                {
                  duration: 8000,
                }
              );
            },
            () => {
              setSubmitting(false);
              history.push(`/dashboard/receipt/${orderId}`);
              toast.error(
                "Unable to create collection for order with id " + orderId,
                {
                  duration: 8000,
                }
              );
            }
          )
        );
      } else {
        setSubmitting(false);
        history.push(`/dashboard/receipt/${orderId}`);
      }
    };

    const onFail = (msg = "Unable to create order") => {
      if (typeof msg === "object") {
        msg = msg.title;
      }
      setShowErr(true);
      setModalErrMsg(msg);
      setSubmitting(false);
    };

    //console.log( body );

    dispatch(ordersActions.placeOrder(body, onSuccess, onFail));
  };

  const calculateAmount = () => {
    return formatCurrency(totalAmount);
  };

  const editCharges = (list) => {
    setExtraCharges(list);
  };

  const handleDiscount = (e) => {
    setDiscountInfo({
      status: "idle",
      discount: null,
      error: null,
    });
    setDiscountCode(e.target.value);
  };

  useEffect(() => {
    let totalItemsAmount = 0;
    if (newOrder.items.length === 0) return formatCurrency(0);

    newOrder.items.map((item) => {
      totalItemsAmount += item.total;
      return item;
    });

    setTotalAmount(totalItemsAmount + totalCharges);
  }, [newOrder.items, totalCharges]);

  useEffect(() => {
    if (
      newOrder?.items?.length &&
      discountInfo?.discount &&
      !(discountInfo?.discount?.isUsed || discountInfo?.discount?.isExpired)
    ) {
      const discountCode = discountInfo?.discount?.code;

      const token = localStorage.getItem("pos-token");
      const fn = async () => {
        dispatch({
          type: ordersTypes.START_LOADING_ORDER_PRICE,
        });

        let totalFreight =
          newOrder.items?.reduce((sum, it) => {
            sum += it.total;
            return sum;
          }, 0) ?? 0;

        const body = {
          discountCodeDigits: discountCode,
          taxId,
          currentTotal: totalFreight,
          chargeValues: extraCharges.length
            ? extraCharges.map((c) => c.amount)
            : [0],
          declaredAndInsurances: newOrder.items.length
            ? newOrder.items
                .map((it) => {
                  if (it.declared && newOrder.insuranceIdList[it.itemId]) {
                    const val = {
                      declared: it.declared,
                      insuranceId: newOrder.insuranceIdList[it.itemId],
                    };

                    return val;
                  }

                  return null;
                })
                .filter((x) => x)
                .map((val) => ({
                  declared: val.declared,
                  insuranceId: val.insuranceId,
                }))
            : [
                {
                  declared: 0,
                  insuranceId: "Insurance_2",
                },
              ],
        };

        const sumExtra = extraCharges.reduce((sum, c) => (sum += c.amount), 0);

        const url = `https://api.abccargoxpress.com/api/v1/Orders/GetDiscountedValue`;

        ////console.log(url);

        try {
          const resp = await Axios.post(url, body, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          const value = resp.data;

          dispatch(ordersActions.updateOrderValues("taxId", value.taxValue));

          const charges = addUpInsurance(newOrder) + value.taxValue + sumExtra;

          setTotalCharges(charges);

          setTotalAmount(value.amount);
          dispatch({
            type: ordersTypes.END_LOADING_ORDER_PRICE,
          });
        } catch (error) {
          console.log(error);
        }
      };

      if (token) {
        fn();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newOrder?.items, discountInfo, dispatch, extraCharges, totalCharges]);

  return (
    <>
      <header className={styles.header}>
        <h2>Book An Item</h2>
      </header>
      <Formik
        initialValues={configureState(location)}
        validationSchema={cargoSchema}
        validateOnMount={true}
        onSubmit={(values, { setSubmitting }) => {
          //const details = { ...values };
          setShowOrderModal(true);
          //submitHandler( details );
          //setSubmitting( false );
        }}
      >
        {(props) => (
          <Form className={styles.form}>
            <Delivery formikProps={props} />
            <SenderReceiver formikProps={props} mapsLoaded={isLoaded} />

            <Items
              formikProps={props}
              setShowErr={setShowErr}
              setModalErrMsg={setModalErrMsg}
              setProceed={setProceed}
              totalCharges={totalCharges}
              setTotalCharges={setTotalCharges}
              extraCharges={extraCharges}
              selectedCharges={selectedCharges}
            />

            <Others
              formikProps={props}
              totalCharges={totalCharges}
              setTotalCharges={setTotalCharges}
              setProceed={setProceed}
              mapsLoaded={isLoaded}
              extraCharges={extraCharges}
              selectedCharges={selectedCharges}
              handleSelectedCharges={handleSelectedCharges}
              discountInfo={discountInfo}
            />

            <ExtraCharges
              chargeTypes={chargeTypes}
              extraCharges={extraCharges}
              editCharges={editCharges}
            />

            <p className={styles.total}>Discount </p>
            <div className={styles.extra}>
              <label htmlFor={`discountCode`}>Discount Code</label>
              <input onChange={handleDiscount} value={discountCode} />
              &nbsp; &nbsp;
              <button
                disabled={!discountCode}
                onClick={validateDiscount}
                type="button"
              >
                {discountInfo.status === "pending" ? "Verifying" : "Verify"}{" "}
                code
              </button>
              {discountInfo.status === "resolved" ? (
                <div>
                  <br />
                  {discountInfo.discount && !discountInfo.discount?.isUsed ? (
                    <div>
                      <p>Code is valid</p>
                      <h4>
                        Discount amount :
                        {discountInfo.discount?.discountType
                          ?.toLowerCase()
                          ?.includes("percent")
                          ? `${discountInfo.discount?.amount}%`
                          : formatCurrency(discountInfo.discount?.amount)}
                      </h4>
                    </div>
                  ) : (
                    <p>Code has expired or been used or does not exist</p>
                  )}
                </div>
              ) : null}
              {discountInfo.status === "rejected" ? (
                <div>
                  <br />
                  <p>Unable to verify code</p>
                </div>
              ) : null}
            </div>

            <p className={styles.total}>Cash on collection E-commerce</p>
            <br />
            <div>
              <label style={{ marginRight: "20px" }}>
                <input
                  type="radio"
                  name="COLLECTION"
                  value={true}
                  onChange={() => setCreateColl(true)}
                />
                &nbsp; Yes
              </label>

              <label style={{ marginRight: "20px" }}>
                <input
                  type="radio"
                  name="COLLECTION"
                  checked={!createColl}
                  value={false}
                  onChange={() => setCreateColl(false)}
                />
                &nbsp; No
              </label>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                margin: "20px 0",
              }}
            >
              <Payment formikProps={props} />
            </div>

            <p className={styles.total}>Total amount: {calculateAmount()}</p>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <button
                type="submit"
                style={{ height: "50px", padding: "0 20px", margin: "60px 0" }}
                onClick={() => setShowOrderModal(true)}
                disabled={
                  submitting ||
                  newOrder.items.length === 0 ||
                  //totalCharges === 0 ||
                  !newOrder.serviceTypeId ||
                  !newOrder.deliveryTypeId ||
                  !newOrder.channelId ||
                  !newOrder.receiverLastName ||
                  !newOrder.receiverFirstName ||
                  !newOrder.receiverPhoneNumber
                }
              >
                Proceed to payment
              </button>
            </div>
          </Form>
        )}
      </Formik>

      <Dialog
        isShown={showOrderModal}
        title="Order Details"
        onConfirm={submitHandler}
        isConfirmLoading={submitting}
        isConfirmDisabled={submitting}
        onCloseComplete={() => setShowOrderModal(false)}
        confirmLabel={submitting ? "Processing..." : "Proceed"}
        intent="info"
      >
        <p>
          {" "}
          <strong>Customer phone number</strong> : {newOrder.phoneNumber}
        </p>

        <p>
          {" "}
          <strong>Receiver phone number</strong> :{" "}
          {newOrder.receiverPhoneNumber}
        </p>

        <p>
          {" "}
          <strong>Sales channel</strong> : {newOrder.channelId}
        </p>

        <p>
          {" "}
          <strong>Freight charge</strong> : {getTotalFreightPrice()}
        </p>

        <p>
          {" "}
          <strong>VAT</strong> : {formatCurrency(newOrder.taxId)}
        </p>

        <p>
          {" "}
          <strong>Insurance</strong> :{" "}
          {formatCurrency(addUpInsurance(newOrder))}
        </p>

        <p>
          {" "}
          <strong>Total charges (VAT + Insurance + Extra)</strong> :{" "}
          {formatCurrency(totalCharges)}
        </p>

        {discountInfo.status === "rejected" ||
        !discountInfo?.discount ||
        discountInfo?.discount?.isUsed ||
        discountInfo?.discount?.isExpired ? null : (
          <p>
            {" "}
            <strong>Discount</strong> :{" "}
            {discountInfo?.discount?.discountType
              ?.toLowerCase()
              ?.includes("percent")
              ? `${discountInfo.discount?.amount}%`
              : formatCurrency(discountInfo.discount?.amount)}
          </p>
        )}

        <p>
          {" "}
          <strong>Total amount</strong> :{calculateAmount()}{" "}
        </p>

        {/* <button type="button" onClick={ submitHandler }>
          { submitting ? "Processing..." : "Proceed" }
        </button> */}
      </Dialog>

      <Dialog
        isShown={showModal}
        title="Successful"
        onCloseComplete={() => history.push("/dashboard/orders")}
        confirmLabel="Close"
      >
        {successMsg}
      </Dialog>

      <Dialog
        isShown={showErr}
        title="Error"
        onCloseComplete={() => setShowErr(false)}
        confirmLabel="Close"
        intent="danger"
      >
        {modalErrMsg}
      </Dialog>
    </>
  );
};

export default MakeOrder;
