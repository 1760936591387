import Axios from "axios";
import { ordersTypes } from "../actionTypes";

const baseUrl = "https://abcapi.fusionintel.io/api/v1/";

const fetchAllOrders = () => async (dispatch) => {
  dispatch({
    type: ordersTypes.LOADING,
    payload: true,
  });
  try {
    const token = localStorage.getItem("pos-token");
    const response = await Axios.get(
      `${baseUrl}Orders/ViewAllOrders`,

      { headers: { Authorization: `Bearer ${token}` } }
    );

    dispatch({
      type: ordersTypes.FETCH_ORDERS,
      payload: response.data,
    });
    dispatch({
      type: ordersTypes.LOADING,
      payload: false,
    });
  } catch (error) {
    dispatch({
      type: ordersTypes.LOADING,
      payload: false,
    });
  }
};

const fetchOrdersByStartHub = (hubId, DateFrom, DateTo) => async (dispatch) => {
  dispatch({
    type: ordersTypes.LOADING,
    payload: true,
  });
  try {
    const token = localStorage.getItem("pos-token");
    const response = await Axios.get(
      `${baseUrl}Orders/ViewOrdersByStartingHub?startingHubId=${hubId}&DateFrom=${DateFrom}&DateTo=${DateTo}`,

      { headers: { Authorization: `Bearer ${token}` } }
    );

    dispatch({
      type: ordersTypes.FETCH_ORDERS,
      payload: response.data,
    });
    dispatch({
      type: ordersTypes.LOADING,
      payload: false,
    });
  } catch (error) {
    dispatch({
      type: ordersTypes.LOADING,
      payload: false,
    });
  }
};

const fetchOrdersByEndHub = (hubId, DateFrom, DateTo) => async (dispatch) => {
  dispatch({
    type: ordersTypes.LOADING,
    payload: true,
  });
  try {
    const token = localStorage.getItem("pos-token");
    const response = await Axios.get(
      `${baseUrl}Orders/ViewOrdersByEndingHub?endingHubId=${hubId}&DateFrom=${DateFrom}&DateTo=${DateTo}`,

      { headers: { Authorization: `Bearer ${token}` } }
    );

    dispatch({
      type: ordersTypes.FETCH_ORDERS,
      payload: response.data,
    });
    dispatch({
      type: ordersTypes.LOADING,
      payload: false,
    });
  } catch (error) {
    dispatch({
      type: ordersTypes.LOADING,
      payload: false,
    });
  }
};

const fetchOrdersByDate = (date) => async (dispatch) => {
  dispatch({
    type: ordersTypes.LOADING,
    payload: true,
  });
  try {
    const token = localStorage.getItem("pos-token");
    const response = await Axios.post(`${baseUrl}Orders/ListByDate`, date, {
      headers: { Authorization: `Bearer ${token}` },
    });

    dispatch({
      type: ordersTypes.FETCH_ORDERS,
      payload: response.data,
    });
    dispatch({
      type: ordersTypes.LOADING,
      payload: false,
    });
  } catch (error) {
    dispatch({
      type: ordersTypes.LOADING,
      payload: false,
    });
  }
};

const setOrders = () => async (dispatch) =>
  dispatch({
    type: ordersTypes.FETCH_ORDERS,
    payload: [],
  });

const updateOrder = (order) => async (dispatch) =>
  dispatch({
    type: ordersTypes.UPDATE_ORDER,
    payload: order,
  });

const updateDesc = (desc) => async (dispatch) =>
  dispatch({
    type: ordersTypes.UPDATE_ORDER,
    payload: desc,
  });

const fetchOrdersByStaff = (body) => async (dispatch) => {
  console.log(body);
  dispatch({
    type: ordersTypes.LOADING,
    payload: true,
  });
  try {
    const token = localStorage.getItem("pos-token");
    const response = await Axios.post(
      `${baseUrl}Orders/ViewOrdersByStaff`,
      body,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    dispatch({
      type: ordersTypes.LOADING,
      payload: false,
    });

    dispatch({
      type: ordersTypes.FETCH_ORDERS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: ordersTypes.LOADING,
      payload: false,
    });

    dispatch({
      type: ordersTypes.FETCH_ORDERS,
      payload: [],
    });
  }
};

const fetchOrderById = (id) => async (dispatch) => {
  dispatch({
    type: ordersTypes.LOADING,
    payload: true,
  });
  try {
    const token = localStorage.getItem("pos-token");
    const response = await Axios.get(
      `${baseUrl}Orders/BreakdownOrder?OrderId=${id}`,

      { headers: { Authorization: `Bearer ${token}` } }
    );

    dispatch({
      type: ordersTypes.FETCH_ORDER_BY_ID,
      payload: response.data,
    });
    dispatch({
      type: ordersTypes.LOADING,
      payload: false,
    });
  } catch (error) {
    dispatch({
      type: ordersTypes.LOADING,
      payload: false,
    });
  }
};

const fetchOrderByCode = (code) => async (dispatch) => {
  dispatch({
    type: ordersTypes.LOADING,
    payload: true,
  });
  try {
    const token = localStorage.getItem("pos-token");
    const response = await Axios.get(
      `${baseUrl}Orders/GetByRandomCode?code=${code}`,

      { headers: { Authorization: `Bearer ${token}` } }
    );

    dispatch({
      type: ordersTypes.FETCH_ORDER_BY_ID,
      payload: response.data,
    });
    dispatch({
      type: ordersTypes.LOADING,
      payload: false,
    });
  } catch (error) {
    dispatch({
      type: ordersTypes.FETCH_ORDER_BY_ID_FAIL,
      payload: error.response.data || "Error fetching waybill",
    });
    dispatch({
      type: ordersTypes.LOADING,
      payload: false,
    });
  }
};

const fetchDeliveryTypes = () => async (dispatch) => {
  try {
    const token = localStorage.getItem("pos-token");
    const response = await Axios.get(
      `${baseUrl}DeliveryTypes/ViewAllDeliveryTypes`,

      { headers: { Authorization: `Bearer ${token}` } }
    );

    dispatch({
      type: ordersTypes.FETCH_DELIVERY_TYPES,
      payload: response.data,
    });
  } catch (error) {
    console.log(error);
  }
};

const fetchVehicleTypes = () => async (dispatch) => {
  try {
    const token = localStorage.getItem("pos-token");
    const response = await Axios.get(
      `${baseUrl}VehicleTypes/ViewAllVehicleTypes`,

      { headers: { Authorization: `Bearer ${token}` } }
    );

    dispatch({
      type: ordersTypes.FETCH_VEHICLE_TYPES,
      payload: response.data,
    });
  } catch (error) {
    console.log(error);
  }
};

const fetchShipmentTypes = () => async (dispatch) => {
  try {
    const token = localStorage.getItem("pos-token");
    const response = await Axios.get(
      `${baseUrl}ShipmentTypes/ViewAllShipmentTypes`,

      { headers: { Authorization: `Bearer ${token}` } }
    );

    dispatch({
      type: ordersTypes.FETCH_SHIPMENT_TYPES,
      payload: response.data.filter((p) => p.isActive),
    });
  } catch (error) {
    console.log(error);
  }
};

const fetchServiceTypes = () => async (dispatch) => {
  try {
    const token = localStorage.getItem("pos-token");
    const response = await Axios.get(
      `${baseUrl}ServiceTypes/ViewAllServiceTypes`,

      { headers: { Authorization: `Bearer ${token}` } }
    );

    dispatch({
      type: ordersTypes.FETCH_SERVICE_TYPES,
      payload: response.data,
    });
  } catch (error) {
    console.log(error);
  }
};

const fetchSources = () => async (dispatch) => {
  try {
    const token = localStorage.getItem("pos-token");
    const response = await Axios.get(
      `${baseUrl}Sources/ViewAllSources`,

      { headers: { Authorization: `Bearer ${token}` } }
    );

    dispatch({
      type: ordersTypes.FETCH_SOURCES,
      payload: response.data,
    });
  } catch (error) {
    console.log(error);
  }
};

const fetchChannels = () => async (dispatch) => {
  try {
    const token = localStorage.getItem("pos-token");
    const response = await Axios.get(
      `${baseUrl}Channels/ViewAllChannels`,

      { headers: { Authorization: `Bearer ${token}` } }
    );

    dispatch({
      type: ordersTypes.FETCH_CHANNELS,
      payload: response.data,
    });
  } catch (error) {
    console.log(error);
  }
};

const fetchCharges = () => async (dispatch) => {
  try {
    const token = localStorage.getItem("pos-token");
    const response = await Axios.get(
      `${baseUrl}Charges/ViewAllCharges`,

      { headers: { Authorization: `Bearer ${token}` } }
    );

    dispatch({
      type: ordersTypes.FETCH_CHARGES,
      payload: response.data,
    });
  } catch (error) {
    console.log(error);
  }
};

const fetchChargesByType =
  (chargeTypeId, onSuccess, onFail) => async (dispatch) => {
    try {
      const token = localStorage.getItem("pos-token");
      const response = await Axios.get(
        `${baseUrl}Charges/ListChargesByType?chargeTypeId=${chargeTypeId}`,

        { headers: { Authorization: `Bearer ${token}` } }
      );

      onSuccess(chargeTypeId, response?.data);
    } catch (error) {
      onFail(chargeTypeId, error?.response?.data);
    }
  };

const fetchChargeTypes = () => async (dispatch) => {
  try {
    const token = localStorage.getItem("pos-token");
    const response = await Axios.get(
      `${baseUrl}ChargeTypes/ViewAllChargeTypes`,

      { headers: { Authorization: `Bearer ${token}` } }
    );

    dispatch({
      type: ordersTypes.FETCH_CHARGETYPES,
      payload: response.data.filter((p) => p.isActive),
    });
  } catch (error) {
    console.log(error);
  }
};

const fetchItemStatus = () => async (dispatch) => {
  try {
    const token = localStorage.getItem("pos-token");
    const response = await Axios.get(
      `${baseUrl}ItemStatuss/ViewAllItemStatuses`,

      { headers: { Authorization: `Bearer ${token}` } }
    );

    dispatch({
      type: ordersTypes.FETCH_ITEM_STATUS,
      payload: response.data,
    });
  } catch (error) {
    console.log(error);
  }
};

const fetchItemTypes = () => async (dispatch) => {
  try {
    const token = localStorage.getItem("pos-token");
    const response = await Axios.get(
      `${baseUrl}ItemTypes/ViewAllItemTypes`,

      { headers: { Authorization: `Bearer ${token}` } }
    );

    dispatch({
      type: ordersTypes.FETCH_ITEM_TYPES,
      payload: response.data.filter((p) => p.isActive),
    });
  } catch (error) {
    console.log(error);
  }
};

const fetchTaxes = () => async (dispatch) => {
  try {
    const token = localStorage.getItem("pos-token");
    const response = await Axios.get(
      `${baseUrl}Taxs/ViewAllTaxes`,

      { headers: { Authorization: `Bearer ${token}` } }
    );

    dispatch({
      type: ordersTypes.FETCH_TAXES,
      payload: response.data,
    });
  } catch (error) {
    console.log(error);
  }
};

const fetchInsurances = () => async (dispatch) => {
  try {
    const token = localStorage.getItem("pos-token");
    const response = await Axios.get(
      `${baseUrl}Insurances/ViewAllInsurances`,

      { headers: { Authorization: `Bearer ${token}` } }
    );

    dispatch({
      type: ordersTypes.FETCH_INSURANCES,
      payload: response.data,
    });
  } catch (error) {
    console.log(error);
  }
};

const fetchPackagingCosts = () => async (dispatch) => {
  try {
    const token = localStorage.getItem("pos-token");
    const response = await Axios.get(
      `${baseUrl}PackagingCosts/ViewAllPackagingCosts`,

      { headers: { Authorization: `Bearer ${token}` } }
    );

    dispatch({
      type: ordersTypes.FETCH_PACKAGING_COSTS,
      payload: response.data,
    });
  } catch (error) {
    console.log(error);
  }
};

const checkCustomer = (id, onSuccess, onFail) => async (dispatch) => {
  try {
    const token = localStorage.getItem("pos-token");
    const response = await Axios.get(
      `${baseUrl}Customers/ViewCustomerById?customerId=${id}`,

      { headers: { Authorization: `Bearer ${token}` } }
    );
    return onSuccess(response);
  } catch (error) {
    return onFail();
  }
};

const updateOrderValues = (target, value) => (dispatch) => {
  dispatch({
    type: ordersTypes.UPDATE_ORDERS_VALUES,
    payload: {
      target,
      value,
    },
  });
};

const updateOrderItemValues = (values) => (dispatch) => {
  dispatch({
    type: ordersTypes.UPDATE_ORDER_ITEM_VALUES,
    payload: values,
  });
};

const placeOrder = (body, onSuccess, onFail) => async (dispatch) => {
  try {
    const token = localStorage.getItem("pos-token");
    const response = await Axios.post(`${baseUrl}Orders/CreateOrder`, body, {
      headers: { Authorization: `Bearer ${token}` },
    });
    dispatch({
      type: ordersTypes.CLEAR_ORDER,
    });
    return onSuccess(response.data);
  } catch (error) {
    return onFail(error?.response?.data);
  }
};

const calculateOrdersPrice = (body, onSuccess, onFail) => async (dispatch) => {
  dispatch({
    type: ordersTypes.START_LOADING_ORDER_PRICE,
  });

  try {
    const token = localStorage.getItem("pos-token");
    const response = await Axios.post(
      `${baseUrl}Orders/CalculateOrderPrice`,
      body,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    dispatch({
      type: ordersTypes.END_LOADING_ORDER_PRICE,
    });
    return onSuccess(response.data);
  } catch (error) {
    dispatch({
      type: ordersTypes.END_LOADING_ORDER_PRICE,
    });
    return onFail(error);
  }
};

const calculateItemPrice = (body, onSuccess, onFail) => async (dispatch) => {
  dispatch({
    type: ordersTypes.START_LOADING_ORDER_PRICE,
  });

  try {
    const token = localStorage.getItem("pos-token");
    const response = await Axios.post(
      `${baseUrl}Orders/CalculateItemPrice`,
      body,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    dispatch({
      type: ordersTypes.END_LOADING_ORDER_PRICE,
    });
    return onSuccess(response.data);
  } catch (error) {
    dispatch({
      type: ordersTypes.END_LOADING_ORDER_PRICE,
    });
    return onFail(error);
  }
};

const setOrderStatus = (body, onSuccess, onFail) => async (dispatch) => {
  try {
    const token = localStorage.getItem("pos-token");
    const response = await Axios.post(
      `${baseUrl}Orders/SetOrderStatus`,
      {
        orderId: body.orderId,
        statusId: body.statusId,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    return onSuccess(response.data);
  } catch (error) {
    return onFail(
      error?.response?.data ||
        error?.toString() ||
        "Unable to update order status."
    );
  }
};

const fetchOrderStatuses = () => async (dispatch) => {
  try {
    const token = localStorage.getItem("pos-token");
    const response = await Axios.get(
      `${baseUrl}ItemStatuss/ViewAllItemStatuses`,

      { headers: { Authorization: `Bearer ${token}` } }
    );
    dispatch({
      type: ordersTypes.FETCH_ORDER_STATUSES,
      payload: response.data,
    });
  } catch (error) {
    console.log(error);
  }
};

const createCollection = (body, onSuccess, onFail) => async (dispatch) => {
  try {
    const token = localStorage.getItem("pos-token");
    const response = await Axios.post(
      `${baseUrl}Collections/CreateCollection`,
      body,
      { headers: { Authorization: `Bearer ${token}` } }
    );

    onSuccess(response);
  } catch (error) {
    onFail();
  }
};

//exp

const setItemInsurance =
  ({ itemId, insuranceValue, insuranceId }) =>
  async (dispatch) => {
    dispatch({
      type: ordersTypes.UPDATE_ITEM_INSURANCES,
      payload: { itemId, insuranceValue, insuranceId },
    });
  };

//exp
const clearInsurance = () => async (dispatch) => {
  dispatch({
    type: ordersTypes.CLEAR_ITEM_INSURANCES,
  });
};

const setSearchBy =
  (mode = "Starting Hub") =>
  async (dispatch) =>
    dispatch({
      type: ordersTypes.SET_SEARCHBY,
      payload: mode,
    });

export const ordersActions = {
  fetchAllOrders,
  fetchOrdersByStaff,
  setOrders,
  setSearchBy,
  fetchOrdersByStartHub,
  fetchOrdersByEndHub,
  fetchOrdersByDate,
  fetchOrderById,
  fetchDeliveryTypes,
  fetchVehicleTypes,
  fetchShipmentTypes,
  fetchServiceTypes,
  fetchSources,
  fetchChannels,
  fetchCharges,
  fetchItemStatus,
  fetchItemTypes,
  fetchTaxes,
  fetchInsurances,
  fetchPackagingCosts,
  checkCustomer,
  updateOrderValues,
  updateOrderItemValues,
  placeOrder,
  calculateItemPrice,
  calculateOrdersPrice,
  setOrderStatus,
  fetchOrderStatuses,
  fetchChargeTypes,
  fetchChargesByType,
  createCollection,
  fetchOrderByCode,
  updateOrder,
  setItemInsurance,
  clearInsurance,
  updateDesc,
};
