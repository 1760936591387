import { userTypes } from "../actionTypes";

const initState = {
  loading: false,
  profile: [],
  currencyId: "",
};

export const reducer = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case userTypes.SAVE_PROFILE:
      return { ...state, profile: payload };
    case userTypes.SET_CURRENCY:
      return { ...state, currencyId: payload };
    case userTypes.LOADING: {
      return { ...state, loading: payload };
    }
    default:
      return state;
  }
};
