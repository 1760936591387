import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Axios from "axios";

import logo from "../../assets/images/logoo.png";
import styles from "./Auth.module.scss";

const schema = Yup.object().shape({
  staffId: Yup.string().required("Staff id is required"),
});

const initValues = {
  staffId: "",
};

const ForgotPassword = () => {
  const [info, setInfo] = useState({
    status: "idle",
    data: "",
    error: "",
  });

  const handleSubmit = async (values, { resetForm }) => {
    setInfo({
      status: "pending",
      error: "",
    });

    try {
      await Axios.get(
        `https://api.abccargoxpress.com/api/v1/Staffs/ResetPassword?staffId=${values.staffId}`
      );

      setInfo({
        status: "resolved",
        error: "",
      });

      resetForm();
    } catch (error) {
      const err = { ...error };

      setInfo({
        status: "rejected",
        data: "good",
        error: err?.response?.data?.message || "Unable to reset password",
      });
    }
  };

  return (
    <Formik
      initialValues={initValues}
      validationSchema={schema}
      onSubmit={handleSubmit}
    >
      {() => (
        <div className={styles.body}>
          <Form className={styles.Form}>
            <h2 className={styles.FormTitle}>
              <div>
                <img src={logo} alt="logo" />
              </div>
              <span></span>
            </h2>

            <p>
              {" "}
              {info.status === "resolved"
                ? "Check your email for updated information"
                : null}{" "}
            </p>

            <div className={styles.FieldGroup}>
              <label htmlFor="staffId">Staff Id</label>
              <Field name="staffId" />
              <ErrorMessage
                className={styles.FieldErr}
                component="span"
                name="staffId"
              />
            </div>

            <button
              className={styles.Submit}
              type="submit"
              disabled={info.status === "pending"}
            >
              {info.status === "pending" ? "Please Wait..." : "Submit"}
            </button>

            {info.status === "resolved" ? (
              <NavLink className={styles.link} to="/">
                Sign In
              </NavLink>
            ) : null}
          </Form>
        </div>
      )}
    </Formik>
  );
};

export default ForgotPassword;
