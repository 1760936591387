const baseStyle = {}

const appearances = {}

const sizes = {}

export default {
  baseStyle,
  appearances,
  sizes
}
