import { ordersTypes } from "../actionTypes";

const taxId = localStorage.getItem("pos-vat") || "VAT";

const initState = {
  orders: [],
  selectedOrder: {},
  deliveryTypes: [],
  vehicleTypes: [],
  shipmentTypes: [],
  sources: [],
  channels: [],
  serviceTypes: [],
  charges: [],
  chargeTypes: [],
  itemStatus: [],
  itemTypes: [],
  taxes: [],
  insurances: [],
  packagingCosts: [],
  loading: false,
  orderStatuses: [],
  searchBy: "Starting Hub",
  loadingOrderPrice: false,
  newOrder: {
    customerId: null,
    customerTypeId: null,
    transactionId: null,
    vehicleTypeId: "car_1",
    deliveryTypeId: null,
    shipmentTypeId: null,
    serviceTypeId: null,
    sourceId: "POS",
    channelId: null,
    originHubId: null,
    destinationHubId: null,
    staffId: null,
    chargeIds: [],
    totalKilometers: 0,
    insuranceId: "Insurance_2",
    taxId,
    orderId: "orderonea",
    items: [],
    receiverPhoneNumber: null,
    receiverEmail: null,
    receiverFirstName: null,
    receiverLastName: null,
    receiverAddress: null,
    receiverBusinessName: "",
    deliveryHubAddress: null,
    freightPricingId: "",
    insuranceList: {},
    insuranceIdList: {},
  },
};

export const orders = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ordersTypes.SET_SEARCHBY:
      return { ...state, searchBy: payload };

    case ordersTypes.LOADING:
      const selectedOrder = payload ? {} : state.selectedOrder
      return { ...state, loading: payload , selectedOrder};

    case ordersTypes.FETCH_ORDERS:
      return { ...state, orders: payload };

    case ordersTypes.FETCH_ORDER_BY_ID:
      return { ...state, selectedOrder: payload };

      
    case ordersTypes.FETCH_ORDER_BY_ID_FAIL:
      return { ...state, error: payload };


    case ordersTypes.FETCH_DELIVERY_TYPES:
      return { ...state, deliveryTypes: payload };

    case ordersTypes.FETCH_VEHICLE_TYPES:
      return { ...state, vehicleTypes: payload };

    case ordersTypes.FETCH_SHIPMENT_TYPES:
      return { ...state, shipmentTypes: payload };

    case ordersTypes.FETCH_SOURCES:
      return { ...state, sources: payload };

    case ordersTypes.FETCH_CHANNELS:
      return { ...state, channels: payload };

    case ordersTypes.FETCH_SERVICE_TYPES:
      return { ...state, serviceTypes: payload };

    case ordersTypes.FETCH_CHARGES:
      return { ...state, charges: payload };

    case ordersTypes.FETCH_ITEM_STATUS:
      return { ...state, itemStatus: payload };

    case ordersTypes.FETCH_ITEM_TYPES:
      return { ...state, itemTypes: payload };

    case ordersTypes.FETCH_TAXES:
      return { ...state, taxes: payload };

    case ordersTypes.FETCH_INSURANCES:
      return { ...state, insurances: payload };

    case ordersTypes.FETCH_PACKAGING_COSTS:
      return { ...state, packagingCosts: payload };

    case ordersTypes.UPDATE_ORDERS_VALUES:
      return {
        ...state,
        newOrder: {
          ...state.newOrder,
          [payload.target]: payload.value,
        },
      };

    case ordersTypes.UPDATE_ORDER_ITEM_VALUES:
      return {
        ...state,
        newOrder: {
          ...state.newOrder,
          items: payload,
          freightPricingId: payload[0]?.freightPricingId || "",
        },
      };

    case ordersTypes.FETCH_ORDER_STATUSES:
      return {
        ...state,
        orderStatuses: payload,
      };
    case ordersTypes.FETCH_CHARGETYPES:
      return {
        ...state,
        chargeTypes: payload,
      };

    case ordersTypes.UPDATE_ORDER:
      return {
        ...state,
        selectedOrder: payload,
      };

    case ordersTypes.UPDATE_ITEM_INSURANCES:
      const newOrder = {
        ...state.newOrder,
      };

      newOrder.insuranceList[action.payload.itemId] =
        +action.payload.insuranceValue;

      newOrder.insuranceIdList[action.payload.itemId] =
        action.payload.insuranceId;

      return {
        ...state,
        newOrder,
      };

    case ordersTypes.CLEAR_ITEM_INSURANCES:
      const newOrder2 = {
        ...state.newOrder,
      };

      newOrder2.insuranceList = {};
      newOrder2.insuranceIdList = {};

      return {
        ...state,
        newOrder: newOrder2,
      };

    case ordersTypes.START_LOADING_ORDER_PRICE:
      return {
        ...state,
        loadingOrderPrice: true,
      };

    case ordersTypes.END_LOADING_ORDER_PRICE:
      return {
        ...state,
        loadingOrderPrice: false,
      };

    case ordersTypes.CLEAR_ORDER:
      return {
        ...state,
        loadingOrderPrice: false,
        newOrder: {
          customerId: null,
          transactionId: null,
          vehicleTypeId: "car_1",
          deliveryTypeId: null,
          shipmentTypeId: null,
          serviceTypeId: null,
          sourceId: "POS",
          channelId: null,
          originHubId: null,
          destinationHubId: null,
          staffId: null,
          chargeIds: [],
          totalKilometers: 0,
          insuranceId: "",
          taxId: "VAT",
          orderId: "orderonea",
          items: [],
          receiverPhoneNumber: null,
          receiverEmail: null,
          receiverFirstName: null,
          receiverLastName: null,
          receiverAddress: null,
          deliveryHubAddress: null,
          freightPricingId: "",
          insuranceList: {},
          insuranceIdList: {},
        },
      };

    default:
      return state;
  }
};
