import React, { useState } from "react";
import { NavLink, useHistory } from "react-router-dom";

import styles from "./Header.module.scss";

const Header = ( { title, children, showW = true } ) =>
{
  const [ val, setVal ] = useState( "" );
  const history = useHistory();

  const handleSearchById = () =>
  {
    if ( !val ) return;
    history.push( `/dashboard/orders/${ val }` );
  };

  return (
    <div className={ styles.Header }>
      <p className={ styles.title }>{ title }</p>

      { children }

      <div className={ styles.notif }>
        {/* <span>
          <BsBellFill size={ 20 } />
        </span> */}

        <NavLink to="/dashboard/make-order">Contract waybill</NavLink>

      </div>

      {
        showW ? <NavLink className={ styles.wallet } to="/dashboard/top-wallet">Top Wallet</NavLink>
          : null
      }

      <div className={ styles.search }>
        <input
          type="search"
          value={ val }
          onChange={ ( e ) => setVal( e.target.value ) }
          placeholder="Search order by ID"
        />
        <button onClick={ handleSearchById }>Search</button>
      </div>


    </div>
  );
};

export { Header };
