import React, { useState, useEffect } from "react";
import {
  Dialog,
  Pane,
  Button,
  TextInputField,
  TextareaField,
  Spinner,
  Alert,
  SelectField,
} from "evergreen-ui";
import Axios from "axios";

const baseUrl = "https://api.abccargoxpress.com/api/v1/";

const EditIrregularity = ({
  isShown,
  setIsShown,
  irregularity,
  setIrregularity,
  go,
  types,
}) => {
  const [state, setState] = useState({
    error: null,
    status: "idle",
  });

  const [field, setFields] = useState({
    ...irregularity,
  });

  const [fieldErrs, setFieldErrs] = useState({
    name: false,
    description: false,
  });

  useEffect(() => {
    if (irregularity?.id) {
      setFields(irregularity);
    }
  }, [irregularity]);

  const handleClose = () => {
    setIsShown(false);
    setIrregularity(null);

    setIsShown(false);
    setFields({
      name: "",
      description: "",
    });
    setFieldErrs({
      name: false,
      description: false,
    });

    setState({
      error: null,
      status: "idle",
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFields({
      ...field,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    if (field.name && field.description) {
      setState({
        error: null,
        status: "pending",
      });

      try {
        const token = localStorage.getItem("pos-token");
        const staffId = localStorage.getItem("pos-user");

        const body = {
          ...field,
          irregularityTypeId: field.irregularityTypeId || "missing",
          staffId,
          name: field.name || irregularity.name,
          description: field.description || irregularity.description,
          resolution: field.resolution || irregularity.resolution,
        };

        await Axios.put(
          `${baseUrl}IrregularityReports/Update/${irregularity.id}`,
          body,
          { headers: { Authorization: `Bearer ${token}` } }
        );

        setState({
          error: null,
          status: "resolved",
        });

        go();
      } catch (error) {
        setState({
          status: "rejected",
          error:
            error.response?.data ||
            error.toString() ||
            "Unable to create irregularity",
        });
      }
    } else {
      setFieldErrs({
        name: !field.name,
        description: !field.description,
      });
    }
  };

  return (
    <Pane>
      <Dialog
        isShown={isShown}
        title="Edit Irregularity Report"
        onCloseComplete={handleClose}
        preventBodyScrolling
        hasFooter={false}
      >
        {state.status === "rejected" ? (
          <Alert intent="danger" title="Error editing report" marginBottom={20}>
            {state.error}
          </Alert>
        ) : null}

        {state.status === "resolved" ? (
          <Alert
            intent="success"
            title="Edited report successfully"
            marginBottom={20}
            isRemoveable
            onRemove={handleClose}
          >
            {state.error}
          </Alert>
        ) : null}
        <TextInputField
          label="Order Id"
          disabled
          value={irregularity?.orderId}
        />

        <TextInputField
          label="Name"
          placeholder="This is a description name."
          isInvalid={fieldErrs.name}
          required
          value={field.name}
          onChange={handleChange}
          name="name"
          validationMessage={
            fieldErrs.description && "Name of irregularity is required."
          }
        />

        <SelectField
          value={field.irregularityTypeId}
          onChange={handleChange}
          label="Irregularity type"
          required
          name="irregularityTypeId"
        >
          {types.map((t) => (
            <option key={t.id} value={t.id}>
              {t.name}
            </option>
          ))}
        </SelectField>

        <TextareaField
          isInvalid={fieldErrs.description}
          value={field.description}
          label="Description"
          required
          validationMessage={
            fieldErrs.description && "Description of irregularity is required."
          }
          name="description"
          onChange={handleChange}
          placeholder="Description of irregularity..."
        />

        <TextareaField
          value={field.resolution}
          label="Resolution"
          name="description"
          onChange={handleChange}
          placeholder="Resolution of irregularity..."
        />

        {state.status === "pending" ? (
          <Spinner size={16} />
        ) : (
          <Button onClick={handleSubmit}>Edit report</Button>
        )}

        <br />
      </Dialog>
    </Pane>
  );
};

export default EditIrregularity;
