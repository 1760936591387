import React, { useState } from "react";
import Axios from "axios";

import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";

import { Header } from "../../../components";

import styles from "./ResetPassword.module.scss";

const initValues = {
  oldPassword: "",
  newPassword: "",
};

const schema = Yup.object().shape({
  oldPassword: Yup.string().required("Old Password is required"),
  newPassword: Yup.string()
    .min(5, "Password must be at least 5 characters")
    .max(20, "Password must be at most 20 characters")
    .required("New Password is required"),
});

const ResetPassword = () => {
  const { profile } = useSelector((state) => state.user);
  const [show, setShow] = useState(false);

  const [info, setInfo] = useState({
    status: "idle",
    data: "",
    error: "",
  });

  const handleSubmit = async (values, { resetForm }) => {
    setInfo({
      status: "pending",
      data: "",
      error: "",
    });

    const body = {
      ...values,
      staffId: profile.id,
    };

    try {
      const token = localStorage.getItem("pos-token");
      const resp = await Axios.post(
        `https://api.abccargoxpress.com/api/v1/Staffs/ChangePassword`,
        body,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      setInfo({
        status: "resolved",
        data: resp.data.message,
        error: "",
      });

      resetForm();
    } catch (error) {
      const err = { ...error };

      setInfo({
        status: "rejected",
        data: "good",
        error: err?.response?.data?.message || "Unable to reset password",
      });
    }
  };

  return (
    <>
      <Header title="Reset password" />

      <div className={styles.wrapper}>
        <Formik
          initialValues={initValues}
          validationSchema={schema}
          onSubmit={handleSubmit}
        >
          {() => (
            <Form>
              <p className={styles.error}>
                {" "}
                {info.status === "rejected" ? info.error : null}{" "}
              </p>

              <p className={styles.success}>
                {" "}
                {info.status === "resolved" ? info.data : null}{" "}
              </p>

              <br />

              <div className={styles.field}>
                <label htmlFor="oldPassword">Old Password</label>
                <Field type={show ? "text" : "password"} name="oldPassword" />
                <ErrorMessage
                  className={styles.error}
                  component="span"
                  name="oldPassword"
                />
              </div>

              <div className={styles.field}>
                <label htmlFor="newPassword">New Password</label>
                <Field type={show ? "text" : "password"} name="newPassword" />
                <ErrorMessage
                  className={styles.error}
                  component="span"
                  name="newPassword"
                />
              </div>

              <button
                className={styles.toggle}
                type="button"
                onClick={() => setShow((s) => !s)}
              >
                {show ? "Hide" : "Show"} passwords
              </button>

              <button
                className={styles.submit}
                type="submit"
                disabled={info.status === "pending"}
              >
                {info.status === "pending" ? "Please Wait..." : "Submit"}
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default ResetPassword;
