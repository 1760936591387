import { useState, useEffect } from "react";
import { NavLink, useRouteMatch, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import classNames from "classnames";
import { userActions } from "../../redux/actions";

import { RiDashboardFill, RiArrowLeftRightLine } from "react-icons/ri";
import { HiOutlineUserGroup } from "react-icons/hi";
import { TiClipboard } from "react-icons/ti";
import { BiLogOut } from "react-icons/bi";
import { BsClockFill } from "react-icons/bs";
import { RiAdminLine } from "react-icons/ri";
import { GiBrokenPottery } from "react-icons/gi";

import logo from "../../assets/images/logooo.png";
import styles from "./SideBar.module.scss";

const navItems = [
  {
    path: "",
    icon: RiDashboardFill,
  },
  {
    path: "customers",
    text: "Customers",
    icon: HiOutlineUserGroup,
  },
  {
    path: "orders",
    text: "Waybills",
    icon: TiClipboard,
  },
  {
    path: "irregularity",
    text: "Irregularity",
    icon: GiBrokenPottery,
  },
  {
    path: "transaction-history",
    text: "Transactions",
    icon: RiArrowLeftRightLine,
  },
  {
    path: "sessions",
    text: "Sessions",
    icon: BsClockFill,
  },
  {
    path: "reset-password",
    text: "Reset password",
    icon: RiAdminLine,
  },
];

const SideBar = () => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const toggleMenu = () => setOpen((o) => !o);
  let match = useRouteMatch();

  const logout = () => {
    dispatch(userActions.logout());
    history.push("/");
  };

  useEffect(() => {
    const resize = window.addEventListener("resize", () => setOpen(false));

    return () => {
      window.removeEventListener("resize", resize);
    };
  }, []);

  return (
    <>
      <button
        onClick={toggleMenu}
        className={classNames(styles.Toggler, { [styles.open]: open })}
      >
        <i className="las la-angle-double-down"></i>
      </button>

      <nav className={classNames(styles.Sidebar, { [styles.open]: open })}>
        <NavLink onClick={toggleMenu} to={match.url} className={styles.Logo}>
          <div>
            <img src={logo} alt="step logo" />
          </div>
        </NavLink>
        <ul className={styles.NavList}>
          {navItems.map((n, ind) => (
            <li key={ind} onClick={toggleMenu} className={styles.NavItem}>
              <NavLink
                activeClassName={styles.active}
                exact
                to={!n.path ? match.url : `${match.url}/${n.path}`}
              >
                <n.icon />
                <p>{n.text || "Home"}</p>
              </NavLink>
            </li>
          ))}
          <li className={styles.NavItem}>
            <button onClick={logout} className={styles.NavItem}>
              <BiLogOut color="#fff" />
            </button>
          </li>
        </ul>
      </nav>
      <div
        onClick={toggleMenu}
        className={classNames(styles.Overlay, { [styles.open]: open })}
      />
    </>
  );
};

export { SideBar };
