import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import
{
  Header,
  Table,
  HeaderSearch,
  FetchingState,
  EmptyState,
} from "../../../components";
import { formatCurrency, formatDate } from "../../../utils";
import styles from "../styles.module.scss";
import { sessionsActions } from "../../../redux/actions";

const columns = [
  {
    Header: "Date",
    accessor: "dateTimeClosed",
  },

  {
    Header: "Closed by",
    accessor: "closedByName",
  },
  {
    Header: "SessionID",
    accessor: "id",
  },
  {
    Header: "Staff Name",
    accessor: "staff",
  },
  {
    Header: "Hub",
    accessor: "hubId",
  },
  {
    Header: "Cash",
    accessor: "expectedCash",
  },
  {
    Header: "Cash Variance",
    accessor: "cashVariance",
  },
  {
    Header: "Card",
    accessor: "expectedCard",
  },
  {
    Header: "Card Variance",
    accessor: "cardVariance",
  },

  {
    Header: "Total Variance",
    accessor: "totalValueVariance",
  },
];

const formatData = ( data ) =>
{
  return data.map( ( d ) => ( {
    ...d,
    dateTimeClosed: formatDate( d.dateTimeClosed ),
    cash: `₦ ${ formatCurrency( d.cash ) }`,
    card: `₦ ${ formatCurrency( d.card ) }`,
    total: `₦ ${ formatCurrency( d.total ) }`,
    staff: `${ d.staff.aspNetUser.firstName } ${ d.staff.aspNetUser.lastName }`,
    closedByName: `${ d.closedBy?.aspNetUser?.firstName || "-" } ${ d.closedBy?.aspNetUser?.lastName || "-"
      } `,
  } ) );
};

const Sessions = () =>
{
  const dispatch = useDispatch();
  const { profile } = useSelector( ( state ) => state.user );

  useEffect( () =>
  {
    dispatch( sessionsActions.fetchAllSessions( profile.id ) );
  }, [ dispatch, profile ] );

  const { sessions, loading } = useSelector( ( state ) => state.sessions );

  return (
    <div>
      <Header title="Session">
        <HeaderSearch
          onStartDateChange={ () => { } }
          onEndDateChange={ () => { } }
          useSearch={ false }
        />
      </Header>

      { loading ? (
        <FetchingState />
      ) : sessions.length === 0 ? (
        <EmptyState text="No new session" />
      ) : (
        <Table
          data={ formatData( sessions ) }
          paginate
          bordered
          wrapperClass={ styles.container }
          columns={ columns }
        />
      ) }
    </div>
  );
};

export default Sessions;
