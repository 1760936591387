import React, { useState, useEffect } from "react";
import {
  Dialog,
  Pane,
  Button,
  TextareaField,
  Spinner,
  Alert,
} from "evergreen-ui";
import Axios from "axios";

const baseUrl = "https://api.abccargoxpress.com/api/v1/";

const ResolveIrregularity = ({
  isShown,
  setIsShown,
  irregularity,
  setIrregularity,
  go,
}) => {
  const [state, setState] = useState({
    error: null,
    status: "idle",
  });

  const [field, setFields] = useState("");

  const handleClose = () => {
    setIsShown(false);
    setIrregularity(null);
    setIsShown(false);
    setFields("");
    setState({
      error: null,
      status: "idle",
    });
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setFields(value);
  };

  const handleSubmit = async () => {
    setState({
      error: null,
      status: "pending",
    });

    try {
      const token = localStorage.getItem("pos-token");

      const body = {
        reportId: irregularity.id,
        resolution: field,
      };

      //alert( JSON.stringify( body, null, 2 ) );

      await Axios.post(`${baseUrl}IrregularityReports/Resolve`, body, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setState({
        error: null,
        status: "resolved",
      });

      go();
    } catch (error) {
      setState({
        status: "rejected",
        error:
          error.response?.data ||
          error.toString() ||
          "Unable to create irregularity",
      });
    }
  };

  useEffect(() => {
    if (irregularity?.id) {
      setFields(irregularity.resolution);
    }
  }, [irregularity]);

  return (
    <Pane>
      <Dialog
        isShown={isShown}
        title="Resolve Irregularity Report"
        onCloseComplete={handleClose}
        preventBodyScrolling
        hasFooter={false}
      >
        {state.status === "rejected" ? (
          <Alert
            intent="danger"
            title="Error resolving report"
            marginBottom={20}
          >
            {state.error}
          </Alert>
        ) : null}

        {state.status === "resolved" ? (
          <Alert
            intent="success"
            title="Resolved report successfully"
            marginBottom={20}
            isRemoveable
            onRemove={handleClose}
          >
            {state.error}
          </Alert>
        ) : null}
        <TextareaField
          value={field}
          label="Resolution"
          required
          name="description"
          onChange={handleChange}
          placeholder="Resolution message..."
        />

        {state.status === "pending" ? (
          <Spinner size={16} />
        ) : (
          <Button onClick={handleSubmit}>Resolve report</Button>
        )}

        <br />
      </Dialog>
    </Pane>
  );
};

export default ResolveIrregularity;
