import Axios from "axios";
import { userTypes } from "../actionTypes";

const baseUrl = "https://abcapi.fusionintel.io/api/v1/";

const login = (body, onSuccess, onFail) => async (dispatch) => {
  try {
    const response = await Axios.post(`${baseUrl}Home/Authrorize`, body);
    localStorage.setItem("pos-token", response.data.token);
    localStorage.setItem("pos-user", response.data.userId);
    localStorage.setItem("pos-vat", response.data.vatToUse);

    onSuccess(response.data);
  } catch (error) {
    onFail(error?.response?.data || "Unable to login");
  }
};

const getStaffProfile = (staffId, token) => async (dispatch) => {
  dispatch({
    type: userTypes.LOADING,
    payload: true,
  });

  try {
    const response = await Axios.get(
      `${baseUrl}Staffs/ViewStaffById?staffId=${staffId}`,

      { headers: { Authorization: `Bearer ${token}` } }
    );

    //console.log( response.data, "poiuyh" );
    dispatch({
      type: userTypes.SAVE_PROFILE,
      payload: response.data,
    });
    dispatch({
      type: userTypes.LOADING,
      payload: false,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: userTypes.LOADING,
      payload: false,
    });
  }
};

const setUserCurrency = (currencyId) => async (dispatch) => {
  dispatch({
    type: userTypes.SET_CURRENCY,
    payload: currencyId,
  });
};

const logout = () => (dispatch) => {
  localStorage.removeItem("pos-user");

  localStorage.removeItem("pos-lastLoggedIn");

  dispatch({
    type: userTypes.LOGOUT,
  });
};

const staffListByHub = (hubId, setState) => async () => {
  const token = localStorage.getItem("pos-token");
  try {
    const response = await Axios.get(
      `${baseUrl}Staffs/ViewStaffsByHub?hubId=${hubId}`,

      { headers: { Authorization: `Bearer ${token}` } }
    );

    setState({
      status: "resolved",
      staff: response.data,
      error: null,
    });
  } catch (error) {
    setState({
      status: "rejected",
      staff: [],
      error:
        error.response?.data ||
        error.toString() ||
        "Unable to retrieve staff by hub",
    });
  }
};

const getIrregularities =
  ({ dateFrom, dateTo, irregularityType, setState }) =>
  async () => {
    const token = localStorage.getItem("pos-token");
    try {
      const response = await Axios.get(
        `${baseUrl}IrregularityReports/ListAll?dateFrom=${dateFrom}&dateTo=${dateTo}&irregularityType=${irregularityType}`,

        { headers: { Authorization: `Bearer ${token}` } }
      );

      setState({
        status: "resolved",
        irregularities: response.data
          .filter((o) => o.reportTypeId === "irregularity_report" && o.orderId)
          .reverse(),
        error: null,
      });
    } catch (error) {
      setState({
        status: "rejected",
        irregularities: [],
        error:
          error.response?.data ||
          error.toString() ||
          "Unable to retrieve irregularities",
      });
    }
  };

export const userActions = {
  login,
  logout,
  getStaffProfile,
  staffListByHub,
  getIrregularities,
  setUserCurrency,
};
