import React from "react";
import styles from "./Table.module.scss";

const Paginate = ( {
  canPreviousPage,
  canNextPage,
  gotoPage,
  nextPage,
  pageCount,
  previousPage,
  pageIndex,
  pageOptions,
  setPageSize,
  pageSize,
  maxSize
} ) =>
{
  return (
    <div className={ styles.pagination }>
      <button onClick={ () => gotoPage( 0 ) } disabled={ !canPreviousPage }>
        { "<<" }
      </button>
      <button onClick={ () => previousPage() } disabled={ !canPreviousPage }>
        { "<" }
      </button>
      <button onClick={ () => nextPage() } disabled={ !canNextPage }>
        { ">" }
      </button>
      <button onClick={ () => gotoPage( pageCount - 1 ) } disabled={ !canNextPage }>
        { ">>" }
      </button>

      <span>
        &nbsp; Page &nbsp;
        <strong>
          { pageIndex + 1 } of { pageOptions.length }{ " " }
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </strong>
      </span>
      <span>
        Go to page &nbsp;&nbsp;
        <input
          type="number"
          defaultValue={ pageIndex + 1 }
          onChange={ ( e ) =>
          {
            const page = e.target.value ? Number( e.target.value ) - 1 : 0;
            gotoPage( page );
          } }
          style={ { width: "100px" } }
        />
      </span>
      <select
        value={ pageSize }
        onChange={ ( e ) =>
        {
          setPageSize( Number( e.target.value ) );
        } }
      >
        { [ 10, 20, 30, 40, 50 ].map( ( pageSize ) => (
          <option key={ pageSize } value={ pageSize }>
            Show { pageSize }
          </option>
        ) ) }

        <option value={ maxSize }>
          Show all
        </option>
      </select>
    </div>
  );
};

export default Paginate;
