import React, { useLayoutEffect, useEffect, useState } from "react";
import {
  Switch,
  Route,
  useRouteMatch,
  useHistory,
  Redirect,
  useLocation,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Layout, SideBar, Footer } from "../../components";
import { userActions } from "../../redux/actions";
import MakeOrder from "./MakeOrder/MakeOrder";
import Receipt from "./MakeOrder/OrderReceipt";
import Customers from "./Customers/Customers";
import CreateCustomer from "./Customers/CreateCustomer";
import Home from "./Home/Home";
import Orders from "./Orders/Orders";
import OrderDetails from "./Orders/OrderDetails";
import Transactions from "./Transactions/Transactions";
import Sessions from "./Sessions/Sessions";
import TopWallet from "./TopWallet/TopWallet";
import Irregularity from "./Irregularity";
import ResetPassword from "./ResetPassword";
import axios from "axios";
import { toast } from "react-hot-toast";
import differenceInMinutes from "date-fns/differenceInMinutes";

const Routes = () => {
  let match = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${match.path}/receipt/:id`}>
        <Receipt />
      </Route>
      <Route exact path={`${match.path}/make-order`}>
        <MakeOrder />
      </Route>
      <Route exact path={`${match.path}/customers`}>
        <Customers />
      </Route>
      <Route exact path={`${match.path}/create-customer`}>
        <CreateCustomer />
      </Route>
      <Route exact path={`${match.path}/orders/:id`}>
        <OrderDetails />
      </Route>

      <Route exact path={`${match.path}/orders`}>
        <Orders />
      </Route>

      <Route exact path={`${match.path}/sessions`}>
        <Sessions />
      </Route>

      <Route exact path={`${match.path}/transaction-history`}>
        <Transactions />
      </Route>

      <Route exact path={`${match.path}/top-wallet`}>
        <TopWallet />
      </Route>

      <Route exact path={`${match.path}/irregularity`}>
        <Irregularity />
      </Route>

      <Route exact path={`${match.path}/reset-password`}>
        <ResetPassword />
      </Route>

      <Route exact path={match.path}>
        <Home />
      </Route>

      <Route>
        <Home />
      </Route>

      <Route exact path={match.path}>
        <Redirect to={match.path} />
      </Route>
    </Switch>
  );
};

const Dashboard = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [shouldRender, setShouldRender] = useState(false);

  const location = useLocation();

  const { profile } = useSelector((state) => state.user);

  useEffect(() => {
    const lastLoggedIn = localStorage.getItem("pos-lastLoggedIn")
      ? localStorage.getItem("pos-lastLoggedIn")
      : "2023-01-10T00:00:00.000Z";
    const interval = differenceInMinutes(new Date(), new Date(lastLoggedIn));

    if (interval > 30) {
      setShouldRender(false);
    }
  }, [location.pathname]);

  useLayoutEffect(() => {
    const user = localStorage.getItem("pos-user");
    const lastLoggedIn = localStorage.getItem("pos-lastLoggedIn")
      ? localStorage.getItem("pos-lastLoggedIn")
      : "2023-01-10T00:00:00.000Z";
    const token = localStorage.getItem("pos-token");
    if (!profile?.id || !token) {
      toast.error("Session has expired", {
        duration: 5000,
      });
      dispatch(userActions.logout());
      history.push("/auth");
      return;
    }

    //check that existing token is still valid
    if (token || user) {
      const interval = differenceInMinutes(new Date(), new Date(lastLoggedIn));

      if (interval > 30) {
        axios
          .get(
            `https://api.abccargoxpress.com/api/v1/Staffs/ViewStaffById?staffId=${
              profile.id || user
            }`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then(() => {
            setShouldRender(true);
            localStorage.setItem("pos-lastLoggedIn", new Date().toISOString());
            return;
          })
          .catch((err) => {
            toast.error("Session has expired", {
              duration: 5000,
            });
            dispatch(userActions.logout());
            history.push("/auth");
            return;
          });
      } else {
        setTimeout(() => {
          setShouldRender(true);
        }, 300);

        return;
      }
    }
  }, [history, dispatch, profile, location.pathname]);

  return (
    <>
      <Layout
        footerComp={<Footer />}
        navComp={<SideBar />}
        mainComp={
          shouldRender ? (
            <Routes />
          ) : (
            <h3
              style={{
                padding: "10px",
              }}
            >
              Loading...
            </h3>
          )
        }
      />
    </>
  );
};

export { Dashboard };
