import React from "react";

import styles from "./UpcomingOrders.module.scss";

const Item = ( { id, originHubId, destinationHubId } ) =>
{
  return (
    <div className={ styles.item }>
      <p className={ styles.id }>{ id }</p>

      <p className={ styles.addressHeader }>Pick up from</p>

      <p className={ styles.address }>{ originHubId }</p>

      <p className={ styles.addressHeader }>Deliver to</p>
      <div></div>
      <p className={ styles.address }>{ destinationHubId }</p>
    </div>
  );
};

export default Item;
