import React from "react";
import { Spinner } from "../../assets/images";
import styles from "./fetchingstate.module.scss";

function FetchingState({ text, handleClick, buttonText, showButton }) {
  return (
    <div className={styles.fetching}>
      <Spinner />
    </div>
  );
}

export { FetchingState };
