import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, NavLink } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { customerActions, userActions } from "../../redux/actions";
import logo from "../../assets/images/logoo.png";
import styles from "./Auth.module.scss";

const LoginSchema = Yup.object().shape({
  password: Yup.string()
    .min(5, "Password must be at least 5 characters")
    .max(20, "Password must be at most 20 characters")
    .required("Password is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
});

const initValues = {
  email: "",
  password: "",
};

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState("");

  const [show, setShow] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const handleSubmit = (values) => {
    setLoading(true);
    setMsg("");
    const body = {
      userName: values.email,
      password: values.password,
      platform: "POS",
    };

    const onSuccess = (data) => {
      setLoading(false);
      dispatch(userActions.setUserCurrency(data?.currencyId));
      dispatch(userActions.getStaffProfile(data?.userId, data.token));
      dispatch(customerActions.fetchCustomerGroups());
      history.push("/dashboard");
    };

    const onFail = (msg) => {
      setLoading(false);
      setMsg(msg);
    };

    dispatch(userActions.login(body, onSuccess, onFail));
  };

  return (
    <Formik
      initialValues={initValues}
      validationSchema={LoginSchema}
      onSubmit={handleSubmit}
    >
      {() => (
        <div className={styles.body}>
          <Form className={styles.Form}>
            <h2 className={styles.FormTitle}>
              <div>
                <img src={logo} alt="logo" />
              </div>
              <span></span>
            </h2>

            {msg ? (
              <>
                <small>{msg || "Unable to login"}</small>
                <br />
              </>
            ) : null}

            <div className={styles.FieldGroup}>
              <label htmlFor="email">Email</label>
              <Field name="email" type="email" />
              <ErrorMessage
                className={styles.FieldErr}
                component="span"
                name="email"
              />
            </div>

            <div className={styles.FieldGroup}>
              <label htmlFor="password">Password</label>
              <Field type={show ? "text" : "password"} name="password" />
              <ErrorMessage
                className={styles.FieldErr}
                component="span"
                name="password"
              />
            </div>

            <button
              className={styles.toggle}
              type="button"
              onClick={() => setShow((s) => !s)}
            >
              {show ? "Hide" : "Show"} password
            </button>

            <button className={styles.Submit} type="submit" disabled={loading}>
              {loading ? "Please Wait..." : "Sign in"}
            </button>

            <br />

            <NavLink className={styles.link} to="/auth/forgot-password">
              Forgot password
            </NavLink>
          </Form>
        </div>
      )}
    </Formik>
  );
};

export default Login;
