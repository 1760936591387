import { applyMiddleware, combineReducers, createStore, compose } from "redux";
import { persistStore, persistReducer, createMigrate } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import { reducer as user } from "./user.reducer";
import { hubs } from "./hub.reducer";
import { customers } from "./customers.reducer";
import { sessions } from "./sessions.reducer";
import { transactions } from "./transactions.reducer";
import { orders } from "./orders.reducer";
import { sms } from "./sms.reducer";

const migrations = {};

const persistConfig = {
  key: "root",
  blacklist: ["orders", "sms"],
  storage,
  version: 1,

  migrate: createMigrate(migrations, { debug: false }),
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
  user,
  hubs,
  customers,
  orders,
  sessions,
  transactions,
  sms,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

//const store = createStore(persistedReducer, applyMiddleware(thunk));

const store = createStore(
  persistedReducer,
  /* preloadedState, */ composeEnhancers(compose(applyMiddleware(thunk)))
);

const persistor = persistStore(store);

export { persistor, store };
