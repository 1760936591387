const baseStyle = {
  color: 'colors.default'
}

const appearances = {}
const sizes = {}

export default {
  baseStyle,
  appearances,
  sizes
}
