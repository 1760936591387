import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { ordersActions, customerActions } from "../../redux/actions";
import { PhoneInput } from "../PhoneInput";

const SearchCustomerById = ({ setPerson }) => {
  const dispatch = useDispatch();
  const [number, setNumber] = useState(null);
  const [err, setErr] = useState(null);

  useEffect(() => {
    if (!number || number.length !== 13) return;
    setErr("");
    if (number) {
      setPerson({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
      });
      const onSuccess = (customer) => {
        dispatch(ordersActions.updateOrderValues("customerId", customer.id));
        dispatch(
          ordersActions.updateOrderValues(
            "phoneNumber",
            customer.aspNetUser.phoneNumber
          )
        );
        dispatch(
          ordersActions.updateOrderValues(
            "customerTypeId",
            customer.customerTypeId
          )
        );
        setPerson({
          ...customer.aspNetUser,
          businessName: customer.businessName,
          balance: customer.balance,
          customerGroupId: customer.customerGroupId,
        });
      };

      const onFail = () => {
        setErr("Customer not found");
      };
      dispatch(customerActions.fetchCustomerByPhone(number, onSuccess, onFail));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [number, dispatch]);

  return (
    <div>
      <PhoneInput onChangeInput={(val) => setNumber(val)} />
      {err ? <p style={{ color: "red" }}>{err}</p> : null}
    </div>
  );
};

export { SearchCustomerById };
