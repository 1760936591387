import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import classNames from "classnames";

import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import {
  Header,
  Table,
  EmptyState,
  FetchingState,
  SelectSearchDropDown,
} from "../../../components";
import {
  ordersActions,
  userActions,
  hubsActions,
  customerActions,
} from "../../../redux/actions";
import {
  formatCurrency,
  formatDate,
  formatData,
  waybillByStaffCols,
  waybillColumns,
  formatDataStaff,
} from "../../../utils";
import { PrintOrderTable } from "./PrintOrderTable";
import styles from "./Orders.module.scss";

const mapHubs = (hubs) => {
  return hubs.map((o) => ({
    ...o,
    value: o.id,
  }));
};

const mapStaff = (staff) => {
  return staff.map((o) => ({
    name: `${o.aspNetUser.lastName} ${o.aspNetUser.firstName} (${o.aspNetUser.userName})`,
    value: o.aspNetUserId,
  }));
};

const Orders = () => {
  const history = useHistory();
  const printRef = useRef();

  const { customerGroups, customerGroupsErr, customerGroupsStatus } =
    useSelector((state) => state.customers);

  const [searched, setSearched] = useState(false);

  const [customerGroupId, setCustomerGroupId] = useState("");

  const [searchId, setSearchId] = useState("");
  const [activeStaff, setActiveStaff] = useState("");
  const [activeHub, setActiveHub] = useState("");
  const [staffListByHub, setStaffListByHub] = useState({
    staff: [],
    err: null,
    status: "idle",
  });

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  const dispatch = useDispatch();

  const { orders, loading, searchBy } = useSelector((state) => state.orders);
  const { hubs } = useSelector((state) => state.hubs);
  const { profile } = useSelector((state) => state.user);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    if (!hubs.length) {
      dispatch(hubsActions.fetchAllHubs());
    }
    if (!customerGroups?.length) {
      dispatch(customerActions.fetchCustomerGroups());
    }
  }, [hubs, dispatch, customerGroups]);

  const setSearch = (e) => {
    dispatch(ordersActions.setOrders());
    // setStartDate( null );
    // setEndDate( null );
    setActiveStaff("");
    setActiveHub("");
    dispatch(ordersActions.setSearchBy(e.target.value));
  };

  const setCustomerGroup = (e) => {
    setCustomerGroupId(e.target.value);
  };

  const go = () => {
    if (startDate && endDate) {
      switch (searchBy) {
        case "All":
          dispatch(
            ordersActions.fetchOrdersByStaff({
              dateFrom: formatDate(startDate, "LL/dd/yyyy"),
              dateTo: formatDate(endDate, "LL/dd/yyyy"),
              staffId: activeStaff,
              ...(customerGroupId ? { customerGroupId } : {}),
            })
          );
          break;

        case "Date":
          dispatch(
            ordersActions.fetchOrdersByStaff({
              dateFrom: formatDate(startDate, "LL/dd/yyyy"),
              dateTo: formatDate(endDate, "LL/dd/yyyy"),
              staffId: profile.id,
              ...(customerGroupId ? { customerGroupId } : {}),
            })
          );
          break;

        case "Starting Hub":
          dispatch(
            ordersActions.fetchOrdersByStartHub(
              activeHub,
              formatDate(startDate, "LL-dd-yyyy"),
              formatDate(endDate, "LL-dd-yyyy")
            )
          );
          break;

        default:
          dispatch(
            ordersActions.fetchOrdersByEndHub(
              activeHub,
              formatDate(startDate, "LL-dd-yyyy"),
              formatDate(endDate, "LL-dd-yyyy")
            )
          );
      }

      setSearched(true);
    }
  };

  const getStaffName = () => {
    if (activeStaff) {
      const o = staffListByHub.staff.find(
        (s) => s.aspNetUserId === activeStaff
      );

      if (o) {
        const name = `${o.aspNetUser.lastName} ${o.aspNetUser.firstName} (${o.aspNetUser.userName})`;

        return name;
      }
    }

    if (searchBy === "Date") {
      const name = `${profile.aspNetUser.lastName} ${profile.aspNetUser.firstName} (${profile.aspNetUser.userName})`;

      return name;
    }
  };

  const goTo = (row) => {
    const id = row.orderId || row.id;
    history.push(`/dashboard/orders/${id}`);
  };

  const handleSearchById = () => {
    if (!searchId) return;
    history.push(`/dashboard/orders/${searchId}`);
  };

  const getStaffByHub = async (activeHub) => {
    if (activeHub) {
      setStaffListByHub({
        staff: [],
        error: null,
        status: "pending",
      });

      dispatch(userActions.staffListByHub(activeHub, setStaffListByHub));
    }
  };

  return (
    <>
      <Header title="Waybills" />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "50px auto 30px",
          flexWrap: "wrap",
          padding: "5px 20px",
        }}
      >
        <select
          name="searchBy"
          id=""
          value={searchBy}
          className={styles.select}
          onChange={setSearch}
        >
          <option value="Starting Hub">Search By Starting Hub</option>
          <option value="Ending Hub">Search By Ending Hub</option>
          <option value="ID">Search By ID</option>
          <option value="Date">Search By Date logged in staff</option>
          <option value="All">Search By Date (any staff)</option>
        </select>

        {searchBy === "All" || searchBy === "Date" ? (
          <div>
            {customerGroupsStatus === "rejected" ? (
              <small>{customerGroupsErr}</small>
            ) : null}

            {customerGroupsStatus === "pending" ? (
              <small>Loading customer groups...</small>
            ) : null}

            {customerGroupsStatus === "resolved" && customerGroups?.length ? (
              <select
                name="customerGroupId"
                id="customerGroupId"
                value={customerGroupId}
                className={styles.select}
                onChange={setCustomerGroup}
              >
                <option value="">Select customer group...</option>
                {customerGroups?.map((c) => (
                  <option key={c.id} value={c.id}>
                    {c.name}
                  </option>
                ))}
              </select>
            ) : null}
          </div>
        ) : null}

        {searchBy?.includes("Hub") && hubs.length ? (
          <SelectSearchDropDown
            options={mapHubs(hubs)}
            placeholder="Select hub"
            disabled={!hubs?.length}
            value={activeHub}
            onChange={(value) => {
              setActiveHub(value);
            }}
          />
        ) : null}

        {searchBy.includes("Hub") && !hubs.length ? (
          <p>Loading hubs...</p>
        ) : null}

        {searchBy === "All" ? (
          <div>
            <SelectSearchDropDown
              options={mapHubs(hubs)}
              placeholder="Select staff hub"
              disabled={!hubs?.length}
              value={activeHub}
              onChange={(value) => {
                setActiveHub(value);
                dispatch(ordersActions.setOrders());
                getStaffByHub(value);
              }}
            />

            <SelectSearchDropDown
              options={mapStaff(staffListByHub.staff)}
              disabled={!staffListByHub.staff?.length}
              placeholder="Select staff"
              value={activeStaff}
              onChange={(value) => setActiveStaff(value)}
            />
          </div>
        ) : null}

        {searchBy !== "ID" ? (
          <div
            style={{
              margin: "auto 10px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className={styles.dropDown}>
              <DayPickerInput
                placeholder="Date From"
                onDayChange={(date) => setStartDate(date)}
              />
            </div>
            <div className={styles.dropDown}>
              <DayPickerInput
                placeholder="Date To"
                onDayChange={(date) => setEndDate(date)}
              />
            </div>

            <button className={styles.go} onClick={go}>
              Go
            </button>
          </div>
        ) : (
          <div className={styles.search}>
            <input
              type="search"
              onChange={(e) => setSearchId(e.target.value)}
              placeholder="Search order by ID"
            />
            <button onClick={handleSearchById}>Search</button>
          </div>
        )}
      </div>

      <div>
        {loading ? <FetchingState /> : null}

        {searched && !loading && !orders?.length ? (
          <EmptyState text="No waybills" />
        ) : null}

        {!loading && orders?.length ? (
          <>
            <br />
            <h3>
              &nbsp; &nbsp; Waybills contracted{" "}
              {formatDate(startDate, "yyyy/LL/dd")} to{" "}
              {formatDate(endDate, "yyyy/LL/dd")}
            </h3>
            <br />

            <>
              <div>
                <div
                  className={classNames({
                    [styles.withDesc]: true,
                  })}
                >
                  <Table
                    data={
                      searchBy?.includes("Hub")
                        ? formatData(orders)
                        : formatDataStaff(orders)
                    }
                    paginate
                    bordered
                    rowClickHandler={goTo}
                    wrapperClass={styles.container}
                    columns={
                      !searchBy?.includes("Hub")
                        ? waybillByStaffCols
                        : waybillColumns
                    }
                    showTotal
                    showSubTotal
                    fieldForTotal="amt"
                    formatter={formatCurrency}
                    enableFiltersOn={{ items: true }}
                  />
                </div>
              </div>

              {searchBy?.includes("All") || searchBy?.includes("Date") ? (
                <div>
                  &nbsp; &nbsp;{" "}
                  <button className={styles.go} onClick={handlePrint}>
                    Print Report
                  </button>
                  <div style={{ display: "none" }}>
                    <PrintOrderTable
                      ref={printRef}
                      formatter={formatCurrency}
                      header={
                        <>
                          <br />
                          <h3>
                            &nbsp; &nbsp; Waybills contracted by{" "}
                            {getStaffName()} from{" "}
                            {formatDate(startDate, "yyyy/LL/dd")} to{" "}
                            {formatDate(endDate, "yyyy/LL/dd")}
                          </h3>
                          <br />
                        </>
                      }
                      orders={formatDataStaff(orders)}
                    />
                  </div>
                </div>
              ) : null}
            </>
          </>
        ) : null}
      </div>
    </>
  );
};

export default Orders;
