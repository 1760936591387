import * as Yup from "yup";
import { isPossiblePhoneNumber } from "react-phone-number-input";

export const item = {
  description: "",
  shipmentType: "",
  itemCondition: "",
  weight: "",
  value: "",
  quantity: "",
};

export const shipmentTypes = [
  {
    id: 1,
    name: "Parcel (small items)",
  },
  {
    id: 2,
    name: "Documents",
  },
  {
    id: 3,
    name: "Special shipment",
  },
  {
    id: 4,
    name: "Cargo (bulky items)",
  },
  {
    id: 5,
    name: "Food stuff",
  },
  {
    id: 6,
    name: "E-commerce",
  },
  {
    id: 7,
    name: "Rice",
  },
  {
    id: 8,
    name: "TV set",
  },
  {
    id: 9,
    name: "Furniture",
  },
  {
    id: 10,
    name: "Others",
  },
];

export const charge = {
  description: "",
  itemCharges: [
    {
      chargeName: "",
      chargeAmt: "",
    },
  ],
};

function isValidPhone(message = "Please input a valid phone number") {
  return this.test("isValidPhone", message, function (value) {
    const { path, createError } = this;

    if (!value) {
      return createError({ path, message });
    }

    const num = value[0] === "+" ? value : `+${value}`;

    if (value && !isPossiblePhoneNumber(num)) {
      return createError({ path, message });
    }

    return true;
  });
}
Yup.addMethod(Yup.mixed, "isValidPhone", isValidPhone);

export const updateRecSchema = Yup.object().shape({
  orderId: Yup.string().required("OrderId is required "),
  secondaryReceiverFirstName: Yup.string().required(
    "Receiver first name is required"
  ),
  secondaryReceiverLastName: Yup.string().required(
    "Receiver last name is required"
  ),
  // secondaryReceiverEmail: Yup.string()
  //   .required("Receiver email is required")
  //   .email("Please input a valid email"),
  secondaryReceiverPhoneNumber: Yup.string()
    .required("Receiver phone number is required")
    .isValidPhone(),
  //secondaryReceiverBusinessName: Yup.string().optional(),
});

export const cargoSchema = Yup.object().shape({
  sender: Yup.object().shape({
    firstName: Yup.string().required("Sender first name is required"),
    lastName: Yup.string().required("Sender last name is required"),
    email: Yup.string()
      .required("Sender email is required")
      .email("Please input a valid email"),
    phone: Yup.string().required("Sender phone number is required"),
  }),
  receiver: Yup.object().shape({
    firstName: Yup.string().required("Receiver first name is required"),
    lastName: Yup.string().required("Receiver last name is required"),
    email: Yup.string()
      .required("Receiver email is required")
      .email("Please input a valid email"),
    phone: Yup.string().required("Receiver phone number is required"),
  }),
  charges: Yup.array().of(
    Yup.object().shape({
      description: Yup.string(),
      itemCharges: Yup.array().of(
        Yup.object().shape({
          chargeName: Yup.string(),
          chargeAmt: Yup.number(),
        })
      ),
    })
  ),
  items: Yup.array().of(
    Yup.object().shape({
      description: Yup.string(),
      shipmentType: Yup.string().required("shipment type is required"),
      itemCondition: Yup.string().required("item condition is required"),
      weight: Yup.number(),
      value: Yup.number(),
      quantity: Yup.number().required("Please input quantity"),
      packagingCostId: Yup.string().required("Please select a packaging"),
    })
  ),
  payment: Yup.object().shape({
    channel: Yup.string().required("Please select a payment channel"),
  }),
  delivery: Yup.object().shape({
    deliveryType: Yup.string().required("Please select a delivery type"),
    destinationHub: Yup.string().required("Please select a destination hub"),
    finalAddress: Yup.string().optional(
      "Please input final address of receiver"
    ),
    dropOff: Yup.string().required("Please input address drop off hub"),
  }),
});

export const initialValues = {
  sender: {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
  },
  receiver: {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
  },
  charges: [{ ...charge }],
  items: [{ ...item }],
  payment: {
    channel: "",
  },
  delivery: {
    deliveryType: "",
    destinationHub: "",
    finalAddress: "",
    dropOff: "",
  },
};

export const deliveryTypes = [
  {
    id: 1,
    name: "Hub to Hub",
  },
  {
    id: 2,
    name: "Hub to Door",
  },
];
