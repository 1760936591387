import { transactionTypes } from "../actionTypes";

const initState = {
  transactions: [],
  staffTransactions: [],
  card: 0,
  cash: 0,
  trans: 0,
  wallet: 0,
  loading: false,
};

export const transactions = ( state = initState, action ) =>
{
  const { type, payload } = action;
  switch ( type )
  {
    case transactionTypes.FETCH_TRANSACTIONS:
      return { ...state, transactions: payload };

    case transactionTypes.FETCH_TRANSACTIONS_STAFF:
      return { ...state, staffTransactions: payload };

    case transactionTypes.FETCH_TRANSACTIONS_TALLY:
      return { ...state, [ payload.channelId ]: payload.data };

    case transactionTypes.LOADING:
      return { ...state, loading: payload };
    default:
      return state;
  }
};
