import React, { useCallback } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { ordersActions } from "../../../redux/actions";
import { Dialog } from "evergreen-ui";
import Axios from "axios";
import { formatCurrency } from "../../../utils";

import styles from "./Orders.module.scss";

const channels = [
  {
    id: "CASH",
    text: "Cash",
  },
  {
    id: "CARD",
    text: "Card",
  },
  {
    id: "TRAN",
    text: "Transfer",
  },
  {
    id: "WALLET",
    text: "Wallet",
  },
];

const UpdateItemWeight = ({
  oldPrice = 0,
  items = [],
  show,
  onClose,
  channel = "",
}) => {
  //normal spread did not affect the nested objects, so had to map
  const [modalItems, setModalItems] = useState(
    items.map((it) => ({ ...it, weight: +it.weight || "" }))
  );
  const [status, setStatus] = useState("idle");
  const [paymentChannel, setPaymentChannel] = useState(channel);
  const [error, setError] = useState(undefined);
  const [prices, setPrices] = useState({
    oldPrice,
    newPrice: 0,
  });
  const [activeSection, setActiveSection] = useState(0);
  const [showNewPrice, setShowNewPrice] = useState(false);
  const dispatch = useDispatch();

  const reCalculateWeight = async () => {
    setStatus("pending");
    setError(undefined);
    setShowNewPrice(false);

    const itemsToChange = modalItems.map((it) => ({
      itemId: it.itemId,
      weight: +it.weight,
    }));

    const body = {
      paymentChannel,
      itemsToChange,
    };

    try {
      const token = localStorage.getItem("pos-token");
      const resp = await Axios.post(
        `https://api.abccargoxpress.com/api/v1/Orders/ReCalculateOrder`,
        body,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setStatus("resolved");
      setPrices({
        ...prices,
        newPrice: resp.data.totalAmount,
      });
      setShowNewPrice(true);

      dispatch(ordersActions.updateOrder(resp.data));
    } catch (error) {
      setStatus("rejected");
      setError(
        `Unable to update weights:\n\n${error?.response?.data?.toString()}`
      );
    }
  };

  const updateChannel = useCallback(
    (val) => () => {
      setStatus("idle");
      setPaymentChannel(val);
    },
    []
  );

  const updateItem = useCallback(
    (index) => (e) => {
      setStatus("idle");

      setModalItems((items) => {
        const newItems = [...items];
        newItems[index].weight = e.target.value;
        return newItems;
      });
    },
    []
  );

  return (
    <Dialog
      isShown={show}
      title={
        activeSection === 0
          ? "ReCalculate Item Weight(s)"
          : "Update Payment Channel"
      }
      onCloseComplete={onClose}
      hasFooter={false}
    >
      <div className={activeSection === 0 ? styles.moveUp : ""}>
        {activeSection === 0 ? (
          <section>
            {modalItems.map((item, index) => (
              <div key={index} className={styles.itemContainer__content}>
                <h4>{index + 1}</h4>
                <div>
                  <div className={styles.itemContainer__content__item}>
                    <h5>Description:</h5>
                    <p>{item.iTemDescription}</p>
                  </div>

                  <div className={styles.itemContainer__content__item}>
                    <h5>Item Type Id:</h5>
                    <p>{item.itemTypeId}</p>
                  </div>

                  <div className={styles.itemContainer__content__item}>
                    <h5>Item Type Name:</h5>
                    <p>{item.itemTypeName}</p>
                  </div>

                  <div className={styles.itemContainer__content__item}>
                    <h5>Update Weight:</h5>
                    <input
                      min="0"
                      type="number"
                      onChange={updateItem(index)}
                      value={item.weight}
                      className={styles.updateInp}
                    />
                  </div>
                </div>
              </div>
            ))}

            {showNewPrice ? (
              <div className={styles.priceSummary}>
                <hr />

                <div className={styles.itemContainer__content__item}>
                  <h5>Old Price:</h5>
                  <p>{formatCurrency(prices.oldPrice)}</p>
                </div>

                <div className={styles.itemContainer__content__item}>
                  <h5>New Price</h5>
                  <p>{formatCurrency(prices.newPrice)}</p>
                </div>

                <div className={styles.itemContainer__content__item}>
                  <h5>Difference (new - price) :</h5>
                  <p>
                    {
                      <p>
                        {formatCurrency(
                          (prices.newPrice - prices.oldPrice).toFixed(2)
                        )}
                      </p>
                    }
                  </p>
                </div>
              </div>
            ) : null}

            <div className={styles.btn}>
              <button onClick={reCalculateWeight}>
                {status === "pending"
                  ? "Updating Information..."
                  : "Update Item Weights"}
              </button>
              &nbsp; &nbsp;
              <button onClick={() => setActiveSection(1)}>
                Continue to update payment channel
              </button>
            </div>
            <p className={styles.error}>
              <small>{error ? error : null}</small>
            </p>
          </section>
        ) : null}

        {activeSection === 1 ? (
          <>
            <h4>Payment channel</h4>

            <section className={styles.channels}>
              {channels.map((c) => (
                <label key={c.id} htmlFor={c.id}>
                  &nbsp;
                  <input
                    type="radio"
                    name={c.text}
                    value={c.id}
                    checked={c.id === paymentChannel}
                    id={c.id}
                    onChange={updateChannel(c.id)}
                  />
                  &nbsp;{c.text}
                  &nbsp;
                </label>
              ))}
            </section>

            {showNewPrice ? (
              <div className={styles.priceSummary}>
                <br />
                <hr />
                <div className={styles.itemContainer__content__item}>
                  <h5>Old Price:</h5>
                  <p>{formatCurrency(prices.oldPrice)}</p>
                </div>

                <div className={styles.itemContainer__content__item}>
                  <h5>New Price</h5>
                  <p>{formatCurrency(prices.newPrice)}</p>
                </div>

                <div className={styles.itemContainer__content__item}>
                  <h5>Difference (new - price) :</h5>
                  <p>
                    {
                      <p>
                        {formatCurrency(
                          (prices.newPrice - prices.oldPrice).toFixed(2)
                        )}
                      </p>
                    }
                  </p>
                </div>
              </div>
            ) : null}

            <div className={styles.btn}>
              <button onClick={() => setActiveSection(0)}>Back</button>
              &nbsp; &nbsp;
              <button onClick={reCalculateWeight}>
                {status === "pending"
                  ? "Updating Information..."
                  : "Update Payment Channel"}
              </button>
            </div>
            <p className={styles.error}>
              <small>{error ? error : null}</small>
            </p>
          </>
        ) : null}
      </div>
    </Dialog>
  );
};

export default UpdateItemWeight;
