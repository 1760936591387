import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { Header, PhoneInput } from "../../../components";
import { customerActions } from "../../../redux/actions";

import styles from "./TopWallet.module.scss";
import { Redirect } from "react-router";
import { formatCurrency } from "../../../utils";
import { toast } from "react-hot-toast";

const channels = [
  {
    label: "Cash",
    id: "CASH",
  },
  {
    label: "Card",
    id: "CARD",
  },
  {
    label: "Transfer",
    id: "TRAN",
  },
];

const canSubmit = (channelId, transferId, user, amt) => {
  if (channelId === "TRAN" && !transferId) {
    return true;
  }

  return !(amt && user.user.id && channelId);
};

const TopWallet = () => {
  const dispatch = useDispatch();

  const [user, setUser] = useState({
    loading: false,
    error: "",
    status: "idle",
    phone: "",
  });

  const [amt, setAmt] = useState("");
  const [transferId, setTransferId] = useState("");
  const [channelId, setCI] = useState("");

  const [sms, setSms] = useState({
    loading: false,
    error: "",
    status: "idle",
  });

  const topWallet = async (e) => {
    e.preventDefault();
    setSms({
      loading: true,
      error: "",
      status: "pending",
    });
    const body = {
      customerId: user.user.id,
      amount: amt,
      description: `Customer ${user.user.id} top up of ${amt}`,
      sourceId: "POS",
      channelId: channelId,
      paymentTypeId: "Normal",
      transactionTypeId: "topup",
      phoneNumber: user.user.phoneNumber,
      transferId,
    };

    dispatch(
      customerActions.topWallet(body, () => {
        toast.success("Customer wallet has been successfully credited.");
      })
    );
  };

  const getUser = () => {
    if (user.phone.length === 13) {
      setSms({
        loading: false,
        error: "",
        status: "idle",
      });

      setUser({
        loading: true,
        error: "",
        status: "pending",
        phone: user.phone,
      });

      const onSuccess = (data) => {
        setUser({
          loading: false,
          error: "",
          status: "resolved",
          phone: user.phone,
          user: {
            ...data.aspNetUser,
            balance: data.balance,
            businessName: data.businessName,
          },
        });
      };

      const onFail = (msg = "Unable to retrieve user") => {
        setUser({
          loading: false,
          status: "rejected",
          phone: user.phone,
          user: null,
          error: msg,
        });
      };

      dispatch(
        customerActions.fetchCustomerByPhone(user.phone, onSuccess, onFail)
      );
    }
  };

  const changePhone = (value) => {
    setUser({ ...user, phone: value });
  };

  const handleAmt = (e) => {
    const { value } = e.target;
    setAmt(+value ? +value : "");
  };

  const handleCI = (e) => {
    const { value } = e.target;
    setCI(value);
    setTransferId("");
  };

  const handleTransfer = (e) => {
    const { value } = e.target;
    setTransferId(value);
  };

  if (sms?.status === "pending") {
    return <Redirect to="/dashboard" />;
  }

  return (
    <div>
      <Header showW={false} title="Top up customer wallet" />

      <div className={styles.page}>
        <p className={styles.p}>Type customer phone number to search</p>
        <div className={styles.phone}>
          <PhoneInput
            inputStyle={{ height: "50px", maxWidth: "35vw", padding: "5px" }}
            onChangeInput={changePhone}
          />

          <button onClick={getUser}>Go</button>
        </div>

        {user.status === "pending" ? <p>Fetching user</p> : null}

        {user.status === "rejected" ? <p>{user.error}</p> : null}

        {user.status === "resolved" && !user.user.id ? (
          <p>User not found</p>
        ) : null}

        {user.status === "resolved" && user.user.id ? (
          <section>
            <form onSubmit={topWallet}>
              <div className={styles.fieldGrp}>
                <label htmlFor={`customerId`}>id</label>
                <input readOnly value={user.user.id} type="text" />
              </div>

              <div className={styles.fieldGrp}>
                <label htmlFor={`name`}>Name</label>
                <input
                  readOnly
                  value={`${user.user.firstName} ${user.user.lastName}`}
                  type="text"
                />
              </div>

              <div className={styles.fieldGrp}>
                <label htmlFor={`phone`}>Phone</label>
                <input readOnly value={user.user.phoneNumber} type="text" />
              </div>

              <div className={styles.fieldGrp}>
                <label htmlFor={`businessName`}>Business name</label>
                <input
                  readOnly
                  value={user.user.businessName || "N/A"}
                  type="text"
                />
              </div>

              <p>Current Balance : {formatCurrency(user.user.balance)} </p>

              <div className={styles.fieldGrp}>
                <label htmlFor={`phone`}>Amount</label>
                <input onChange={handleAmt} value={amt} type="number" />
              </div>

              <div className={styles.fieldGrp}>
                <label>Payment method</label>

                {channels.map((p) => (
                  <label key={p.id} style={{ marginRight: "20px" }}>
                    <input
                      type="radio"
                      name="channelId"
                      value={p.id}
                      onChange={handleCI}
                    />
                    &nbsp; {p.label}
                  </label>
                ))}
              </div>

              {channelId === "TRAN" ? (
                <div className={styles.fieldGrp}>
                  <label htmlFor={`transferId`}>Cheque/Transfer ID</label>
                  <input
                    onChange={handleTransfer}
                    value={transferId}
                    type="text"
                  />
                </div>
              ) : null}

              <button
                className={styles.submit}
                disabled={canSubmit(channelId, transferId, user, amt)}
                type="submit"
              >
                Top up
              </button>
            </form>
          </section>
        ) : null}
        <br />
        <br />

        {sms.status === "pending" && user.user ? <h4>Sending SMS</h4> : null}

        {sms.status === "resolved" && user.user ? (
          <h4>SMS of Success topup sent</h4>
        ) : null}
      </div>
    </div>
  );
};

export default TopWallet;
