import { sessionsTypes } from "../actionTypes";

const initState = {
  sessions: [],
  loading: false,
};

export const sessions = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case sessionsTypes.LOADING:
      return { ...state, loading: payload };

    case sessionsTypes.FETCH_SESSIONS:
      return { ...state, sessions: payload };

    default:
      return state;
  }
};
