import React from "react";
import styles from "./SmsNotif.module.scss";

const SmsNotif = ({ msg, close }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.body}>
        <button onClick={close}>Close</button>

        <p>{msg}</p>
      </div>
    </div>
  );
};

export { SmsNotif };
