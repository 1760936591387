import React, { useState /*, useEffect*/ } from "react";
import { useDispatch /*, useSelector*/ } from "react-redux";
import { Dialog } from "evergreen-ui";
import { useHistory, useLocation } from "react-router-dom";
import { customerActions } from "../../../redux/actions";
import styles from "../../Authentication/Auth.module.scss";
import { PhoneInput } from "../../../components";

const getDetails = (user) => {
  return {
    firstName: user?.aspNetUser?.firstName || "",
    lastName: user?.aspNetUser?.lastName || "",
    userName: user?.aspNetUser?.userName || "",
    phoneNumber: user?.aspNetUser?.phoneNumber || "",
    //customerTypeId: user?.customerTypeId || "",
    businessName: user?.businessName || "",
  };
};

const CreateCustomer = () => {
  const { state } = useLocation();

  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isEdit] = useState(state ? true : false);

  const [successMsg, setSuccessMsg] = useState("");
  const [details, setDetails] = useState(getDetails(state));
  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    userName: "",
    phoneNumber: "",
    businessName: "",
    //customerTypeId: "",
  });

  const history = useHistory();
  const dispatch = useDispatch();

  //const { customersTypes } = useSelector( ( state ) => state.customers );

  // useEffect( () =>
  // {
  //   dispatch( customerActions.fetchCustomersTypes() );
  // }, [ dispatch ] );

  const handleSubmit = (e) => {
    e.preventDefault();
    const {
      firstName,
      lastName,
      userName,
      phoneNumber,
      //customerTypeId,
      //businessName
    } = details;
    if (firstName === "") {
      return setErrors({ ...errors, firstName: "First name is required" });
    } else if (lastName === "") {
      return setErrors({ ...errors, lastName: "Last name is required" });
    } else if (userName === "") {
      return setErrors({ ...errors, userName: "User name is required" });
    } else if (phoneNumber === "") {
      return setErrors({ ...errors, phoneNumber: "Phone Number is required" });
    }
    //else if ( customerTypeId === "" )
    // {
    //   return setErrors( { ...errors, phoneNumber: "customerTypeId is required" } );
    // }
    // else if ( !businessName )
    // {
    //   return setErrors( {
    //     ...errors,
    //     businessName: "Enter a business name",
    //   } );
    // }
    else {
      const body = {
        customerId: isEdit ? state?.aspNetUserId : undefined,
        ...state?.aspNetUser,
        businessName: state?.businessName,
        ...details,
        customerTypeId: state?.customerTypeId || "Regular",
        password: isEdit ? undefined : phoneNumber,
        balance: undefined,
      };

      setLoading(true);

      const onSuccess = (response) => {
        if (isEdit) {
          setSuccessMsg(`Customer edited successfully`);
        } else {
          setSuccessMsg(`Customer created:  Username is ${details.userName}`);
        }

        setShowModal(true);
        setLoading(false);
      };

      const onFail = (err) => {
        setLoading(false);

        if (isEdit) {
          alert(err?.toString() || `Unable to edit customer`);
        } else {
          alert(err?.toString() || `Unable to create customer`);
        }
      };

      if (isEdit) {
        dispatch(
          customerActions.updateCustomerAccount(body, onSuccess, onFail)
        );
      } else {
        dispatch(
          customerActions.createCustomerAccount(body, onSuccess, onFail)
        );
      }
    }
  };
  return (
    <>
      <br />

      <h1> &nbsp; &nbsp; {isEdit ? "Edit" : "Create"} Customer </h1>

      <br />
      <div className={styles.body} style={{ background: "#ffffff" }}>
        <form className={styles.Form} onSubmit={handleSubmit}>
          <div className={styles.FieldGroup}>
            <label htmlFor="firstName">First Name</label>
            <input
              name="firstName"
              value={details.firstName}
              onChange={(e) => {
                setDetails({ ...details, firstName: e.target.value });
                setErrors({ ...errors, firstName: "" });
              }}
            />
            <p style={{ color: "red" }}>{errors.firstName}</p>
          </div>
          <div className={styles.FieldGroup}>
            <label htmlFor="lastName">Last Name</label>
            <input
              name="lastName"
              value={details.lastName}
              onChange={(e) => {
                setDetails({ ...details, lastName: e.target.value });
                setErrors({ ...errors, lastName: "" });
              }}
            />
            <p style={{ color: "red" }}>{errors.lastName}</p>
          </div>
          <div className={styles.FieldGroup}>
            <label htmlFor="userName">User Name</label>
            <input
              name="userName"
              value={details.userName}
              onChange={(e) => {
                setDetails({ ...details, userName: e.target.value });
                setErrors({ ...errors, userName: "" });
              }}
            />
            <p style={{ color: "red" }}>{errors.userName}</p>
          </div>
          <div className={styles.FieldGroup}>
            <label htmlFor="email">Email</label>
            <input
              name="email"
              type="email"
              value={details.email}
              onChange={(e) => {
                setDetails({ ...details, email: e.target.value });
                setErrors({ ...errors, email: "" });
              }}
            />
            <p style={{ color: "red" }}>{errors.email}</p>
          </div>

          <div className={styles.phone}>
            <label htmlFor="phoneNumber">Phone number</label>

            <PhoneInput
              onChangeInput={(value) => {
                setDetails({ ...details, phoneNumber: value });
                setErrors({ ...errors, phoneNumber: "" });
              }}
            />
            <p style={{ color: "red" }}>{errors.phoneNumber}</p>
          </div>

          <div className={styles.FieldGroup}>
            <label htmlFor="businessName">Buisiness Name</label>
            <input
              name="businessName"
              value={details.businessName}
              onChange={(e) => {
                setDetails({ ...details, businessName: e.target.value });
                setErrors({ ...errors, businessName: "" });
              }}
            />
            <p style={{ color: "red" }}>{errors.businessName}</p>
          </div>

          {/* <div className={ styles.FieldGroup }>
            <label htmlFor={ `customer.type` }>Customer Type</label>
            <select
              id={ `customer.type` }
              name={ `customer.type` }
              onChange={ ( e ) =>
              {
                setDetails( { ...details, customerTypeId: e.target.value } );
                setErrors( { ...errors, customerTypeId: "" } );
              } }
              value={ details.customerTypeId }
            >
              <option value="">Select type </option>
              { customersTypes.map( ( d, i ) => (
                <option
                  key={ i }
                  value={ d.id }
                  onChange={ ( e ) =>
                    setDetails( { ...details, customerTypeId: e.target.value } )
                  }
                >
                  { d.name }
                </option>
              ) ) }
            </select>
            <p>{ errors.customerType }</p>
          </div> */}

          <button
            className={styles.Submit}
            variant="contained"
            type="submit"
            disabled={loading}
          >
            {loading
              ? "Please Wait..."
              : isEdit
              ? "Edit Customer"
              : "Create Customer"}
          </button>
        </form>

        <Dialog
          isShown={showModal}
          title="Successful"
          onCloseComplete={() => history.push("/dashboard")}
          confirmLabel="Close"
        >
          {successMsg}
        </Dialog>
      </div>

      <br />
    </>
  );
};

export default CreateCustomer;
