import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import {
  Header,
  Table,
  HeaderSearch,
  EmptyState,
  PhoneInput,
  CustomerOrders,
} from "../../../components";
import { formatDate, formatCurrency } from "../../../utils";
import styles from "./customers.module.scss";
import { customerActions } from "../../../redux/actions";

const nullify = (value) => (value ? value : "N/A");

const columns = [
  {
    Header: "Operation ID",
    accessor: "operationId",
  },
  {
    Header: "ID",
    accessor: "id",
  },
  {
    Header: "Type",
    accessor: "transactionTypeId",
  },
  {
    Header: "Channel",
    accessor: "channelId",
  },

  {
    Header: "Source",
    accessor: "sourceId",
  },
  {
    Header: "Date ordered",
    accessor: "createdAt",
    Cell: ({ cell }) => {
      return formatDate(cell.value);
    },
  },
  {
    Header: "Amount",
    accessor: "amount",
    Cell: ({ cell }) => {
      return formatCurrency(cell.value);
    },
  },
  {
    Header: "Balance",
    accessor: "customerBalance",
    Cell: ({ cell }) => {
      return formatCurrency(cell.value);
    },
  },
  {
    Header: "Credit limit",
    accessor: "customer.creditLimit",
    Cell: ({ cell }) => {
      return formatCurrency(cell.value);
    },
  },
];

const t = formatDate(new Date(), "yyyy/LL/dd");

const Customers = () => {
  const dispatch = useDispatch();
  const [searching, setSearching] = useState(false);
  const [searchedUser, setSearchedUser] = useState(null);
  const [searchError, setSearchError] = useState(false);
  const [list, setList] = useState({
    loading: false,
    transactions: [],
    error: null,
    status: "idle",
  });

  const { profile } = useSelector((state) => state.user);

  const [dateFrom, setDF] = useState(t);
  const [dateTo, setDT] = useState(t);

  const onStartDateChange = (date) => {
    setDF(formatDate(date, "yyyy/LL/dd"));
  };

  const onEndDateChange = (date) => {
    setDT(formatDate(date, "yyyy/LL/dd"));
  };

  const onSearch = (number) => {
    setSearchedUser(null);
    setList({
      loading: false,
      transactions: [],
      error: null,
    });
    if (number.length !== 13) return;
    setSearching(true);
    const onSuccess = (data) => {
      setSearching(false);
      if (data) {
        setSearchedUser(data);
      } else {
        setSearchError("Customer not found");
        setSearching(false);
      }
    };
    const onFail = (msg) => {
      setSearchError(msg);
      setSearching(false);
    };
    dispatch(customerActions.fetchCustomerByPhone(number, onSuccess, onFail));
  };

  const getTransHistory = () => {
    const hubId = profile.hubId;
    setList({
      loading: true,
      transactions: [],
      error: null,
    });

    const body = {
      dateFrom: dateFrom,
      dateTo: dateTo,
      hubId,
      customerId: searchedUser.aspNetUser.id,
    };

    const onSuccess = (data) => {
      data.reverse();

      setList({
        loading: false,
        transactions: data,
        error: null,
        status: "resolved",
      });
    };

    const onFail = (msg = "Unable to retrieve transactions") => {
      setList({
        loading: false,
        transactions: [],
        error: msg,
      });
    };

    dispatch(customerActions.fetchTransactions(body, onSuccess, onFail));
  };

  const aspNetUser = searchedUser?.aspNetUser || {};
  return (
    <div>
      <Header title="Customers" />

      <div className={styles.searchpage}>
        <h3>Search customer By Phone Number</h3>
        <div className={styles.searchpageInput}>
          <PhoneInput
            hideLabel
            inputStyle={{ height: "50px", width: "35vw", padding: "5px" }}
            onChangeInput={(val) => {
              setSearchError("");
              onSearch(val);
            }}
          />
        </div>

        {searching && <p>Searching...</p>}

        {searchError && <p style={{ color: "red" }}>{searchError}</p>}

        {searchedUser ? (
          <>
            <div className={styles.user}>
              <div className={styles.item}>
                <p>
                  {" "}
                  <strong>Name</strong>{" "}
                </p>
                <p>
                  {nullify(aspNetUser.firstName)} {nullify(aspNetUser.lastName)}
                </p>
              </div>
              <div className={styles.item}>
                <p>
                  {" "}
                  <strong>Email</strong>{" "}
                </p>
                <p>{nullify(aspNetUser.email)}</p>
              </div>

              <div className={styles.item}>
                <p>
                  {" "}
                  <strong>Phone</strong>{" "}
                </p>
                <p>{nullify(aspNetUser.phoneNumber)}</p>
              </div>
            </div>

            <div className={styles.user}>
              <div className={styles.item}>
                <p>
                  {" "}
                  <strong>Username</strong>{" "}
                </p>
                <p>{nullify(aspNetUser.userName)}</p>
              </div>

              <div className={styles.item}>
                <p>
                  {" "}
                  <strong>Business name</strong>{" "}
                </p>
                <p>{nullify(searchedUser.businessName)}</p>
              </div>

              <div className={styles.item}>
                <p>
                  {" "}
                  <strong>Balance</strong>{" "}
                </p>
                <p>{formatCurrency(searchedUser.balance)}</p>
              </div>

              <div className={styles.item}>
                <p>
                  {" "}
                  <strong>Customer Type</strong>{" "}
                </p>
                <p>{searchedUser.customerTypeId}</p>
              </div>

              <div className={styles.item}>
                <p>
                  {" "}
                  <strong>Customer Group</strong>{" "}
                </p>
                <p>{searchedUser.customerGroupId}</p>
              </div>
            </div>
            <br />

            <Link
              style={{ color: "#000" }}
              to={{
                pathname: "/dashboard/create-customer",
                state: searchedUser,
              }}
            >
              <strong>Edit user</strong>
            </Link>

            <br />
          </>
        ) : null}

        {searchedUser ? (
          <section className={styles.getTrans}>
            <p>
              See all transactions and waybills by {aspNetUser.firstName} &nbsp;{" "}
            </p>
            <HeaderSearch
              useSearch={false}
              onStartDateChange={onStartDateChange}
              onEndDateChange={onEndDateChange}
              disabledDays={{ after: new Date() }}
            />

            <button onClick={getTransHistory}>Go</button>
          </section>
        ) : null}
        <br />

        {searchedUser && list.loading ? (
          <section>
            <p>
              Fetching transactions and waybills by {aspNetUser.firstName} from{" "}
              {dateFrom} to {dateTo}
            </p>
          </section>
        ) : null}

        {searchedUser && list.status === "resolved" ? (
          <section>
            <h4>
              Transactions from {dateFrom} to {dateTo}
            </h4>
            <br />

            {list.transactions.length === 0 ? (
              <EmptyState text="No transactions in this range." />
            ) : (
              <Table
                data={list.transactions}
                paginate
                bordered
                wrapperClass={styles.container}
                columns={columns}
              />
            )}

            <br />
            <br />

            <CustomerOrders
              dateFrom={dateFrom}
              dateTo={dateTo}
              hubId={profile.hubId}
              customerId={searchedUser.aspNetUser.id}
            />
          </section>
        ) : null}
      </div>
    </div>
  );
};

export default Customers;
