import React, { useState } from "react";

import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import styles from "./HeaderSearch.module.scss";

const HeaderSearch = ( {
  isDateA = true,
  listA = [],
  onStartDateChange,
  onEndDateChange,
  onListBChange,
  onSearch,
  searchType = "search",
  searchPlaceholder = "Search",
  loading,
  disabledDays,
  useSearch = true
} ) =>
{
  const [ searchTerm, setSearchTerm ] = useState( "" );
  return (
    <>

      {
        useSearch ? (
          <div className={ styles.search }>
            <input
              type={ searchType }
              value={ searchTerm }
              onChange={ ( e ) => setSearchTerm( e.target.value ) }
              placeholder={ searchPlaceholder }
            />
            <button onClick={ () => onSearch( searchTerm ) } disabled={ loading }>
              { loading ? "Searching..." : "Search" }
            </button>
          </div>
        ) : null
      }


      <div className={ styles.dropDown }>
        { isDateA ? (
          <DayPickerInput
            placeholder="Date From"
            disabledDays={ disabledDays }
            onDayChange={ ( date ) => onStartDateChange( date ) }
          />
        ) : (
          <select>
            <option value="">All shipments</option>
            { listA.map( ( o ) => (
              <option key={ o.label } value={ o.value }>
                { o.label }
              </option>
            ) ) }
          </select>
        ) }
      </div>

      &nbsp;

      <div className={ styles.dropDown }>
        <DayPickerInput
          placeholder="Date To"
          onDayChange={ ( date ) => onEndDateChange( date ) }
        />
      </div>
    </>
  );
};

export { HeaderSearch };
