import React, { useState, useEffect } from "react";
import { useTable, usePagination, useFilters } from "react-table";
import classNames from "classnames";

import
{
  fuzzyTextFilterFn, DefaultColumnFilter
} from '../../utils';


import Paginate from "./Paginate";
import styles from "./Table.module.scss";




const Table = ( {
  columns,
  data,
  bordered,
  wrapperClass,
  rowClickHandler = false,
  showTotal,
  showSubTotal,
  fieldForTotal = "amount",
  formatter,
  totalClass,
  subTotalClass,
  enableFiltersOn
} ) =>
{

  const filterTypes = React.useMemo(
    () => ( {
      fuzzyText: fuzzyTextFilterFn
    } ),
    []
  );
  const defaultColumn = React.useMemo(
    () => ( {
      Filter: DefaultColumnFilter,
    } ),
    []
  );



  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize }
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
      filterTypes,
      defaultColumn
    },
    useFilters,
    usePagination
  );



  const [ paginate, setPaginate ] = useState( false );
  const limit = 10;

  useEffect( () =>
  {
    if ( data.length > limit )
    {
      setPaginate( true );
    }
  }, [ limit, data ] );


  const getTotal = () =>
  {

    const total = data.reduce( ( sum, o ) =>
    {
      sum = sum + ( +o[ ( fieldForTotal ) ] );

      return sum;
    }, 0 );

    return formatter ? formatter( total ) : total;
  };

  const getSubTotal = () =>
  {
    const start = pageSize * pageIndex;
    const end = start + pageSize;

    const total = data.slice( start, end ).reduce( ( sum, o ) =>
    {
      sum = sum + ( +o[ fieldForTotal ] );
      return sum;
    }, 0 );
    return formatter ? formatter( total ) : total;
  };



  return (
    <div className={ classNames( styles.wrapper, wrapperClass ) }>
      <table
        className={ classNames( { [ styles.useBorder ]: bordered } ) }
        { ...getTableProps() }
      >
        <thead>
          { headerGroups.map( ( headerGroup ) => (
            <tr { ...headerGroup.getHeaderGroupProps() }>
              { headerGroup.headers.map( ( column ) => (
                <th { ...column.getHeaderProps() }>
                  { column.render( "Header" ) }

                  <div>{ ( enableFiltersOn && enableFiltersOn[ column?.id ] ) ? column.render( 'Filter' ) : null }</div>

                </th>
              ) ) }
            </tr>
          ) ) }
        </thead>
        <tbody { ...getTableBodyProps() }>
          { page.map( ( row, i ) =>
          {
            prepareRow( row );
            return (
              <tr
                className={ classNames( { [ styles.rowClick ]: rowClickHandler } ) }
                onClick={ () =>
                  rowClickHandler ? rowClickHandler( row.original ) : null
                }
                { ...row.getRowProps() }
              >
                { row.cells.map( ( cell ) =>
                {
                  return (
                    <td { ...cell.getCellProps() }>{ cell.render( "Cell" ) }</td>
                  );
                } ) }
              </tr>
            );
          } ) }
        </tbody>
      </table>


      {
        showSubTotal ? <h4 className={ classNames( styles.subTotal, subTotalClass ) }>
          Sub Total : { getSubTotal() }
        </h4> : null
      }

      {
        showTotal ?
          <h3 className={ classNames( styles.total, totalClass ) }>Total : { getTotal() }</h3> : null
      }



      { paginate ? (
        <Paginate
          canPreviousPage={ canPreviousPage }
          canNextPage={ canNextPage }
          gotoPage={ gotoPage }
          nextPage={ nextPage }
          pageCount={ pageCount }
          previousPage={ previousPage }
          pageIndex={ pageIndex }
          pageOptions={ pageOptions }
          setPageSize={ setPageSize }
          pageSize={ pageSize }
          maxSize={ data.length }
        />
      ) : null }
    </div>
  );
};

export { Table };
