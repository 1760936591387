import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Field } from "formik";
import Section from "./Section";

import { SearchCustomerById, PhoneInput } from "../../../../components";
import { customerActions, ordersActions } from "../../../../redux/actions";
import styles from "./styles.module.scss";

const SenderReceiver = ({ formikProps, mapsLoaded }) => {
  const [loading, setLoading] = useState(false);
  const [disableCustomer, setDisableCustomer] = useState(false);

  const { customerGroups } = useSelector((state) => state.customers);
  const { profile } = useSelector((state) => state.user);

  const dispatch = useDispatch();
  const {
    receiverLastName,
    receiverFirstName,
    receiverEmail,
    receiverPhoneNumber,
    receiverBusinessName,
    deliveryTypeId,
  } = useSelector((state) => state.orders.newOrder);

  const [details, setDetails] = useState({
    firstName: "",
    lastName: "",
    userName: "",
    phoneNumber: "",
    email: "",
    customerTypeId: "Regular",
    businessName: "",
  });

  const [balance, setBalance] = useState(0);
  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    businessName: "",
  });

  const [existing, setExisting] = useState("");

  const handleSubmit = (e) => {
    const hubId = profile.hubId;

    const { firstName, lastName, userName, phoneNumber, email } = details;

    if (firstName === "") {
      return setErrors({ ...errors, firstName: "First name is required" });
    } else if (lastName === "") {
      return setErrors({ ...errors, lastName: "Last name is required" });
    } else if (phoneNumber.length !== 13) {
      return setErrors({
        ...errors,
        phoneNumber: "Enter a valid phone number",
      });
    } else {
      const body = {
        ...details,
        password: phoneNumber,
        userName: userName || phoneNumber,
        phoneNumber,
        hubId,
        email: email || `${phoneNumber}@mail.com`,
        customerGroupId: customerGroups?.length
          ? customerGroups.find((g) => g?.name?.toLowerCase()?.includes("walk"))
              ?.id
          : "walkin",
      };

      setLoading(true);

      const onSuccess = (customer) => {
        dispatch(ordersActions.updateOrderValues("customerId", customer.id));

        dispatch(
          ordersActions.updateOrderValues(
            "phoneNumber",
            customer?.aspNetUser?.phoneNumber || phoneNumber
          )
        );

        dispatch(
          ordersActions.updateOrderValues(
            "customerTypeId",
            customer?.customerTypeId
          )
        );

        setDisableCustomer(true);
        setLoading(false);
      };

      const onFail = (err) => {
        setLoading(false);
        alert(err?.toString() || "Unable to create customer");
      };

      dispatch(customerActions.createCustomerAccount(body, onSuccess, onFail));
    }
  };

  const changeExisting = (value, props) => {
    if (value === "yes") {
      setExisting("yes");
    } else {
      setExisting("no");
    }

    setBalance(0);
    //clear field
    props.setFieldValue("sender.firstName", undefined);

    props.setFieldValue("sender.lastName", undefined);

    props.setFieldValue("sender.email", undefined);

    props.setFieldValue("sender.phoneNumber", undefined);

    props.setFieldValue("sender.customerGroupId", undefined);

    //props.setFieldValue( "sender.businessName", undefined );

    //remove existing err
    props.setTouched({
      "sender.firstName": false,
      "sender.lastName": false,
      "sender.email": false,
      "sender.phone": false,
    });

    setDisableCustomer(false);

    setDetails({
      firstName: "",
      lastName: "",
      userName: "",
      phoneNumber: "",
      customerTypeId: "Regular",
      businessName: "",
    });
  };

  return (
    <div className={styles.sendReceiver}>
      <Section title="Customer's details">
        <div className={styles.pad}>
          <div className={styles.radioGrp}>
            <label>New Customer?</label>
            &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp;
            <label>
              <input
                onChange={() => {
                  changeExisting("no", formikProps);
                }}
                checked={existing === "no"}
                type="radio"
                name="existing"
                id=""
              />
              &nbsp; Yes &nbsp; &nbsp;
            </label>
            <label>
              <input
                onChange={() => changeExisting("yes", formikProps)}
                checked={existing === "yes"}
                type="radio"
                name="existing"
                id=""
              />
              &nbsp; &nbsp; No
            </label>
          </div>

          {existing === "yes" ? (
            <div className={styles.flex1}>
              <div
                style={{ alignSelf: "flex-end", marginRight: "10px" }}
                // className={styles.fieldGrp}
              >
                <SearchCustomerById
                  setPerson={(person) => {
                    const {
                      phoneNumber,
                      email,
                      lastName,
                      firstName,
                      businessName,
                      balance,
                      customerGroupId,
                    } = person;
                    formikProps.setFieldValue(
                      "sender.firstName",
                      firstName,
                      true
                    );

                    formikProps.setFieldValue(
                      "sender.lastName",
                      lastName,
                      true
                    );

                    formikProps.setFieldValue(
                      "sender.customerGroupId",
                      customerGroupId,
                      true
                    );

                    formikProps.setFieldValue("sender.email", email, true);

                    formikProps.setFieldValue(
                      "sender.phoneNumber",
                      phoneNumber,
                      true
                    );

                    formikProps.setFieldValue(
                      "sender.businessName",
                      businessName,
                      false
                    );

                    setBalance(balance);
                  }}
                />
              </div>

              <div className={styles.fieldGrp}>
                <label htmlFor={`sender.firstName`}>First name</label>
                <Field
                  readOnly
                  id={`sender.firstName`}
                  name={`sender.firstName`}
                />
              </div>

              <div className={styles.fieldGrp}>
                <label htmlFor={`sender.lastName`}>Last name</label>
                <Field
                  readOnly
                  id={`sender.lastName`}
                  name={`sender.lastName`}
                />
              </div>

              <div className={styles.fieldGrp}>
                <label htmlFor={`sender.phoneNumber`}>Phone Number</label>
                <Field
                  readOnly
                  id={`sender.phoneNumber`}
                  name={`sender.phoneNumber`}
                />
              </div>

              <div className={styles.fieldGrp}>
                <label htmlFor={`sender.email`}>E-mail</label>
                <Field readOnly id={`sender.email`} name={`sender.email`} />
              </div>

              {/* <div className={ styles.fieldGrp }>
                <label htmlFor="sender.businessName">Business Name</label>
                <Field readOnly id={ `sender.businessName` } name={ `sender.businessName` } />

              </div> */}

              <div className={styles.fieldGrp}>
                <label htmlFor="balance">Wallet balance</label>
                <Field
                  readOnly
                  value={balance}
                  id={`balance`}
                  name={`balance`}
                />
              </div>

              <div className={styles.fieldGrp}>
                <label htmlFor={`sender.customerGroupId`}>Customer Group</label>
                <Field
                  readOnly
                  id={`sender.customerGroupId`}
                  name={`sender.customerGroupId`}
                />
              </div>
            </div>
          ) : null}

          {existing === "no" ? (
            <div>
              <div className={styles.pad}>
                <div className={styles.flex1}>
                  <div className={styles.fieldGrp}>
                    <label htmlFor="firstName">First Name</label>
                    <input
                      disabled={disableCustomer}
                      name="firstName"
                      value={details.firstName}
                      onChange={(e) => {
                        setDetails({ ...details, firstName: e.target.value });
                        setErrors({ ...errors, firstName: "" });
                      }}
                    />
                    <p style={{ color: "red" }}>{errors.firstName}</p>
                  </div>
                  <div className={styles.fieldGrp}>
                    <label htmlFor="lastName">Last Name</label>
                    <input
                      disabled={disableCustomer}
                      name="lastName"
                      value={details.lastName}
                      onChange={(e) => {
                        setDetails({ ...details, lastName: e.target.value });
                        setErrors({ ...errors, lastName: "" });
                      }}
                    />
                    <p style={{ color: "red" }}>{errors.lastName}</p>
                  </div>
                  <div className={styles.fieldGrp}>
                    <label htmlFor="userName">User Name</label>
                    <input
                      disabled={disableCustomer}
                      name="userName"
                      value={details.userName}
                      onChange={(e) => {
                        setDetails({ ...details, userName: e.target.value });
                        setErrors({ ...errors, userName: "" });
                      }}
                    />
                    <p style={{ color: "red" }}>{errors.userName}</p>
                  </div>
                  <div className={styles.fieldGrp}>
                    <label htmlFor="email">Email</label>
                    <input
                      disabled={disableCustomer}
                      name="email"
                      type="email"
                      value={details.email}
                      onChange={(e) => {
                        setDetails({ ...details, email: e.target.value });
                        setErrors({ ...errors, email: "" });
                      }}
                    />
                    <p style={{ color: "red" }}>{errors.email}</p>
                  </div>
                  <div>
                    <PhoneInput
                      onChangeInput={(value) => {
                        setDetails({ ...details, phoneNumber: value });
                        setErrors({ ...errors, phoneNumber: "" });
                      }}
                    />

                    <p style={{ color: "red" }}>{errors.phoneNumber}</p>
                  </div>

                  {/* <div className={ styles.fieldGrp }>
                    <label htmlFor="businessName">Business Name</label>

                    <input
                      disabled={ disableCustomer }
                      name="businessName"
                      value={ details.businessName }
                      onChange={ ( e ) =>
                      {
                        setDetails( { ...details, businessName: e.target.value } );
                        setErrors( { ...errors, businessName: "" } );
                      } }
                    />

                  </div> */}
                </div>
              </div>
              {!disableCustomer && (
                <button
                  className={styles.Submit}
                  variant="contained"
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  {loading ? "Please Wait..." : "Create Customer"}
                </button>
              )}
            </div>
          ) : null}
        </div>
      </Section>
      <Section title="Receiver’s details">
        {!receiverPhoneNumber || !receiverLastName || !receiverFirstName ? (
          <p style={{ color: "red", marginLeft: "16px" }}>
            {`First name, last name ${
              deliveryTypeId === "HD" ? ", Address " : ""
            }and phone number are required.`}
          </p>
        ) : (
          ""
        )}

        <div className={styles.pad}>
          <div className={styles.flex1}>
            <div className={styles.fieldGrp}>
              <label htmlFor={`receiver.firstName`}>First name</label>
              <Field
                id={`receiver.firstName`}
                name={`receiver.firstName`}
                value={receiverFirstName}
                onChange={(e) =>
                  dispatch(
                    ordersActions.updateOrderValues(
                      "receiverFirstName",
                      e.target.value
                    )
                  )
                }
                style={{ width: "400px", marginRight: "20px" }}
              />
            </div>

            <div className={styles.fieldGrp}>
              <label htmlFor={`receiver.lastName`}>Last name</label>
              <Field
                id={`receiver.lastName`}
                name={`receiver.lastName`}
                value={receiverLastName}
                onChange={(e) =>
                  dispatch(
                    ordersActions.updateOrderValues(
                      "receiverLastName",
                      e.target.value
                    )
                  )
                }
                style={{ width: "400px", marginRight: "20px" }}
              />
            </div>

            <PhoneInput
              onChangeInput={(value) =>
                dispatch(
                  ordersActions.updateOrderValues("receiverPhoneNumber", value)
                )
              }
            />

            <div className={styles.fieldGrp}>
              <label htmlFor={`receiver.email`}>E-mail</label>
              <Field
                id={`receiver.email`}
                name={`receiver.email`}
                value={receiverEmail}
                onChange={(e) =>
                  dispatch(
                    ordersActions.updateOrderValues(
                      "receiverEmail",
                      e.target.value
                    )
                  )
                }
              />
            </div>

            <div className={styles.fieldGrp}>
              <label htmlFor="receiver.businessName">Business Name</label>
              <Field
                id={`receiver.businessName`}
                name={`receiver.businessName`}
                value={receiverBusinessName}
                onChange={(e) =>
                  dispatch(
                    ordersActions.updateOrderValues(
                      "receiverBusinessName",
                      e.target.value
                    )
                  )
                }
              />
            </div>
            {deliveryTypeId === "HD" && (
              <div className={styles.fieldGrp}>
                <label htmlFor={`receiver.address`}>Address</label>
                <Field
                  id={`receiver.address`}
                  name={`receiver.address`}
                  onChange={(e) =>
                    dispatch(
                      ordersActions.updateOrderValues(
                        "receiverAddress",
                        e.target.value
                      )
                    )
                  }
                  style={{ width: "400px", marginRight: "20px" }}
                />
              </div>
            )}
          </div>
        </div>
      </Section>
    </div>
  );
};

export { SenderReceiver };
