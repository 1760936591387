import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from "react-router-dom";

import { formatDate, formatCurrency } from "../../utils";

import styles from './CustomerOrders.module.scss';

import { useDispatch } from "react-redux";

import { customerActions } from "../../redux/actions";

import
{

  Table,
  EmptyState,
} from "../";


const formatData = ( data ) =>
{
  const res = data
    ? data.map( ( d ) => ( {
      ...d,
      createdAt: formatDate( d.createdAt ),
      amount: `${ formatCurrency( d.amount ) }`,
    } ) )
    : [];
  return res;
};

const columns =
  [
    {
      Header: "Waybill no.",
      accessor: "id",
    },
    {
      Header: "Staff",
      accessor: "staff.aspNetUser.userName"
    },
    {
      Header: "Rec. phone",
      accessor: "receiverPhoneNumber"
    },
    {
      Header: "Rec. name",
      accessor: "receiverFirstName"
    },
    {
      Header: "Origin Hub",
      accessor: "originHubId",
    },
    {
      Header: "Destination Hub",
      accessor: "destinationHubId",
    },
    {
      Header: "Amount",
      accessor: "amount",
    },
    {
      Header: "Created",
      accessor: "createdAt",
    },
  ];


const CustomerOrders = (
  {
    dateFrom,
    dateTo,
    hubId,
    customerId
  } ) => 
{
  const dispatch = useDispatch();
  const history = useHistory();


  const [ list, setList ] = useState( {
    loading: false,
    orders: [],
    error: null,
    status: "idle"

  } );

  const getOrderHistory = useCallback(
    () =>
    {

      setList(
        {
          loading: true,
          orders: [],
          error: null
        }
      );

      const body = {
        dateFrom,
        dateTo,
        hubId,
        customerId
      };

      const onSuccess = ( data ) => 
      {
        setList(
          {
            loading: false,
            orders: data,
            error: null,
            status: "resolved"
          }
        );
      };

      const onFail = ( msg = "Unable to retrieve orders" ) =>
      {
        setList(
          {
            loading: false,
            orders: [],
            error: msg
          }
        );
      };

      dispatch( customerActions.fetchOrders( body, onSuccess, onFail ) );


    },
    [ customerId, dateFrom, dateTo, dispatch, hubId ],
  );

  const goTo = ( row ) =>
  {
    history.push( `/dashboard/orders/${ row.id }` );
  };


  useEffect( () =>
  {
    getOrderHistory();
  }, [ getOrderHistory ] );

  return (
    <div>
      <h4>Waybills contracted from { dateFrom } to { dateTo }</h4>
      <br />

      { list.orders.length === 0 ? (
        <EmptyState text="No orders in this range." />
      ) : (
        <Table
          data={ formatData( list.orders ) }
          paginate
          bordered
          rowClickHandler={ goTo }
          wrapperClass={ styles.container }
          columns={ columns }
        />
      ) }
    </div>
  );
};

export { CustomerOrders };
