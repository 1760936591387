import React, { useEffect, useState } from "react";
import { FieldArray } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { ordersActions } from "../../../../redux/actions";
import Section from "./Section";
import ItemRow from "../../../../components/ItemRow/ItemRow";
import { uuid } from "uuidv4";

const Items = ({
  formikProps,
  setShowErr,
  setModalErrMsg,
  setProceed,
  totalCharges,
  setTotalCharges,
  selectedCharges,
  extraCharges,
}) => {
  const dispatch = useDispatch();
  const { shipmentTypes, loadingOrderPrice } = useSelector(
    (state) => state.orders
  );

  const [orderItems, setOrderItems] = useState([]);

  useEffect(() => {
    dispatch(ordersActions.fetchShipmentTypes());
    dispatch(ordersActions.fetchItemStatus());
    dispatch(ordersActions.fetchItemTypes());
    setOrderItems([
      {
        itemId: new Date().getTime() + uuid(),
        itemDescription: null,
        itemName: "Default_Name",
        shipmentTypeId: null,
        itemTypeId: null,
        quantity: 0,
        declared: 0,
        weight: 0,
        statusId: null,
        orderId: "orderone",
        packagingCostId: "ABC_test2",
      },
    ]);
  }, [dispatch]);

  const addItem = () => {
    setProceed(false);
    setOrderItems([
      ...orderItems,
      {
        itemId: new Date().getTime() + uuid(),
        itemDescription: null,
        itemName: "Default_Name",
        shipmentTypeId: null,
        itemTypeId: null,
        quantity: 0,
        declared: 0,
        weight: 0,
        statusId: null,
        orderId: "orderone",
        packagingCostId: "ABC_test2",
      },
    ]);
  };

  const removeOrderItem = (itemId) => {
    setProceed(false);
    let newItems = orderItems.filter((item) => item.itemId !== itemId);
    setOrderItems(newItems);
    dispatch(ordersActions.updateOrderItemValues(newItems));
  };

  const updateOrderItemValues = (itemId, data) => {
    setProceed(false);
    const index = orderItems.findIndex((item) => item.itemId === itemId);
    let editedItems = [...orderItems];
    editedItems[index] = data;
    setOrderItems(editedItems);
    dispatch(ordersActions.updateOrderItemValues(editedItems));
  };

  return (
    <Section title="Items">
      <FieldArray
        name="items"
        render={(arrayHelpers) => {
          // const items = formikProps.values.items;
          return (
            <>
              {orderItems.map((item, index) => (
                <ItemRow
                  key={item.id}
                  item={item}
                  shipmentTypes={shipmentTypes}
                  formikProps={formikProps}
                  index={index}
                  helpers={arrayHelpers}
                  setOrderItems={setOrderItems}
                  removeOrderItem={removeOrderItem}
                  updateOrderItemValues={updateOrderItemValues}
                  setShowErr={setShowErr}
                  setModalErrMsg={setModalErrMsg}
                  setProceed={setProceed}
                  setTotalCharges={setTotalCharges}
                  selectedCharges={selectedCharges}
                  extraCharges={extraCharges}
                />
              ))}
              <button
                disabled={loadingOrderPrice}
                type="button"
                onClick={addItem}
                className={loadingOrderPrice ? "disable-btn" : ""}
              >
                Add item
              </button>
            </>
          );
        }}
      />
    </Section>
  );
};

export { Items };
