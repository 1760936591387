import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Axios from "axios";
import { formatCurrency } from "../../../utils";
import styles from "./CollectionSteps.module.scss";
import classNames from "classnames";

const CollectionItemSteps = ({
  order,
  collectionInfo,
  handleUpdateStatus,
  updating,
  fail,
}) => {
  const [page, setPage] = useState(0);
  const { profile } = useSelector((state) => state.user);
  const [totalAmount, setTotalAmount] = useState(0);

  const [updateColInfo, setUpdateColInfo] = useState({
    error: null,
    status: "pending",
  });

  const [amt, setAmt] = useState({
    value: "",
    channelId: "",
    error: "",
  });

  const [otp, setOtp] = useState({
    value: "",
    error: "",
  });

  useEffect(() => {
    if (collectionInfo) {
      setTotalAmount(collectionInfo.collectionAmount);
    }
  }, [collectionInfo]);

  const handleAmtSubmit = (e) => {
    e.preventDefault();
    //sum of declared
    if (+amt.value < totalAmount) {
      setAmt((state) => ({ ...state, error: "Amount is insuffient" }));
    } else if (!amt.channelId) {
      setAmt((state) => ({ ...state, error: "Select payment channel" }));
    } else {
      setAmt((state) => ({ ...state, error: "" }));
      setPage(1);
    }
  };

  const handleOTPSubmit = (e) => {
    e.preventDefault();
    if (otp.value !== order?.token) {
      setOtp((state) => ({ ...state, error: "Incorrect OTP" }));
    } else {
      setOtp((state) => ({ ...state, error: "" }));
      setPage(2);
      updateCollection();
    }
  };

  const updateCollection = async () => {
    if (profile?.id && order?.orderId) {
      const body = {
        ...collectionInfo,
        //chage total amount = sum of items declared
        totalAmount: +amt.value,
        channelId: amt.channelId,
        deliveredById: profile.id,
        dateDelivered: new Date().toISOString(),
      };

      const token = localStorage.getItem("pos-token");
      setUpdateColInfo({
        error: null,
        status: "pending",
      });

      try {
        await Axios.post(
          `https://api.abccargoxpress.com/api/v1/Collections/Complete`,
          body,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setUpdateColInfo({
          error: null,
          status: "success",
        });

        handleUpdateStatus();
      } catch (error) {
        setUpdateColInfo({
          data: null,
          error: "Unable to update collection info",
          status: "rejected",
        });
      }
    }
  };

  if (page === 1) {
    return (
      <div>
        <header className={styles.heading}>
          <h3 className={styles.text}>Confirm OTP</h3>
        </header>

        <section className={styles.details}>
          <p>
            <strong>Order ID: </strong> {order?.orderId}
          </p>

          <p>
            <strong>Expected amount: </strong> {/*sum of items declared */}
            {formatCurrency(totalAmount)}
          </p>

          <p>
            <strong>Received amount: </strong> {formatCurrency(amt?.value)}
          </p>

          <br />
          <br />

          <div className={styles.formWrapper}>
            <h3 className={styles.title}>Enter OTP below</h3>

            <form className={styles.form} onSubmit={handleOTPSubmit}>
              <input
                onChange={(e) =>
                  setOtp((state) => ({ ...state, value: e.target.value }))
                }
                value={otp.value}
              />

              <small>{otp.error}</small>

              <button type="submit">Validate OTP</button>
            </form>
          </div>
        </section>
      </div>
    );
  }

  if (page === 2) {
    return (
      <div>
        <header className={styles.heading}>
          <h3 className={styles.text}>Collection Status</h3>
        </header>

        <section className={styles.details}>
          <p>
            <strong>Order ID: </strong> {order?.orderId}
          </p>

          <p>
            <strong>Customer name: </strong> {order?.customerFirstName}{" "}
            {order?.customerLastName}
          </p>

          <p>
            <strong>Expected amount: </strong> {/*sum of items declared */}
            {formatCurrency(totalAmount)}
          </p>

          <p>
            <strong>Received amount: </strong> {formatCurrency(amt?.value)}
          </p>

          <br />

          <p
            className={classNames({
              [styles.success]: updateColInfo.status === "success",
              [styles.error]: updateColInfo.status === "rejected",
            })}
          >
            {updateColInfo.status === "success" ? "Collection updated" : null}
            {updateColInfo.status === "pending"
              ? "Collection update in progress..."
              : null}
            {updateColInfo.status === "rejected"
              ? "Collection failed to update"
              : null}
          </p>

          <p
            className={classNames({
              [styles.error]: fail,
            })}
          >
            {updating === "pending"
              ? "Collection status update in progress..."
              : null}
            {fail ? "Collection status failed to update" : null}
          </p>

          <div className={styles.formWrapper}>
            {updateColInfo.status === "rejected" ? (
              <div className={styles.form}>
                <button
                  type="button"
                  className={styles.error}
                  onClick={updateCollection}
                >
                  Try again
                </button>
              </div>
            ) : null}
          </div>
        </section>
      </div>
    );
  }

  return (
    <div>
      <header className={styles.heading}>
        <h3 className={styles.text}>Order Details</h3>
      </header>

      <section className={styles.details}>
        <p>
          <strong>Order ID: </strong> {order?.orderId}
        </p>

        <p>
          <strong>Customer name: </strong> {order?.customerFirstName}{" "}
          {order?.customerLastName}
        </p>

        <p>
          <strong>Expected amount: </strong> {/*sum of items declared */}
          {formatCurrency(totalAmount)}
        </p>

        <br />
        <br />

        <div className={styles.formWrapper}>
          <h3 className={styles.title}>Enter amount received below</h3>

          <form className={styles.form} onSubmit={handleAmtSubmit}>
            <input
              onChange={(e) =>
                setAmt((state) => ({ ...state, value: e.target.value }))
              }
              value={amt.value}
              step="0.01"
              type="number"
              min="0"
            />

            <section className={styles.payment}>
              <p>Payment channel</p>

              <div>
                <label style={{ marginRight: "20px" }}>
                  <input
                    type="radio"
                    name="payment.channel"
                    value="CASH"
                    onChange={(e) =>
                      setAmt((state) => ({
                        ...state,
                        channelId: e.target.value,
                      }))
                    }
                  />
                  &nbsp; Cash
                </label>

                <label style={{ marginRight: "20px" }}>
                  <input
                    type="radio"
                    name="payment.channel"
                    value="CARD"
                    onChange={(e) =>
                      setAmt((state) => ({
                        ...state,
                        channelId: e.target.value,
                      }))
                    }
                  />
                  &nbsp; Card
                </label>

                <label style={{ marginRight: "20px" }}>
                  <input
                    type="radio"
                    name="payment.channel"
                    value="TRAN"
                    onChange={(e) =>
                      setAmt((state) => ({
                        ...state,
                        channelId: e.target.value,
                      }))
                    }
                  />
                  &nbsp; Transfer
                </label>

                {/*   <label style={{ marginRight: "20px" }}>
                  <input
                    type="radio"
                    name="payment.channel"
                    value="WALLET"
                    onChange={(e) =>
                      setAmt((state) => ({
                        ...state,
                        channelId: e.target.value,
                      }))
                    }
                  />
                  &nbsp; Wallet
                </label> */}
              </div>
            </section>

            <small>{amt.error}</small>

            <button type="submit">Confirm</button>
          </form>
        </div>
      </section>
    </div>
  );
};

export default CollectionItemSteps;
