// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Footer_Footer__37hEc {\n  padding: 10px;\n  background-color: #CB392A;\n  height: 100%;\n  color: #fff;\n}", "",{"version":3,"sources":["webpack://src/components/Footer/Footer.module.scss","webpack://src/assets/scss/config/_variables.scss"],"names":[],"mappings":"AAEA;EAEI,aAAA;EACA,yBCJO;EDKP,YAAA;EACA,WAAA;AAFJ","sourcesContent":["@import \"../../assets/scss/index.scss\";\n\n.Footer\n{\n    padding: 10px;\n    background-color: $swatch_2;\n    height: 100%;\n    color:#fff\n}\n","$swatch_1: #CB392A;\n$swatch_2: #CB392A;\n$swatch_3:#03140B;\n$error:#f76060;\n$white:#fff;\n$grey:#F1F1F1;\n$grey2:#CCCCCC;\n$grey3:#E1E0E0;\n$black:#333333;\n\n\n\n$breakpoints: (\n    \"phone-small\":  320px,\n    \"phone\":        400px,\n    \"phone-wide\":   480px,\n    \"phablet\":      560px,\n    \"tablet-small\": 640px,\n    \"tablet\":       768px,\n    \"fluid-out\":    960px,\n    \"tablet-wide\":  1024px,\n    \"desktop\":      1248px,\n    \"desktop-med\":  1350px,\n    \"desktop-wide\": 1440px,\n    \"max\":          1500px,\n    \"excess\":       2000px\n);\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Footer": "Footer_Footer__37hEc"
};
export default ___CSS_LOADER_EXPORT___;
