import { customerTypes } from "../actionTypes";

const initState = {
  customers: [],
  selectedCustomer: null,
  customersTypes: [],
  cities: [],
  loading: false,
  customerGroups: [],
  customerGroupsErr: "",
  customerGroupsStatus: "idle",
};

export const customers = ( state = initState, action ) =>
{
  const { type, payload } = action;
  switch ( type )
  {
    case customerTypes.LOADING:
      return { ...state, loading: payload };

    case customerTypes.FETCH_CUSTOMERS:
      return { ...state, customers: payload };

    case customerTypes.FETCH_CUSTOMER_PHONE:
      return { ...state, selectedCustomer: payload };

    case customerTypes.FETCH_CUSTOMERS_TYPES:
      return { ...state, customersTypes: payload };

    case customerTypes.FETCH_CITIES:
      return { ...state, cities: payload };


    case customerTypes.FETCH_CUSTOMERS_GROUP_PENDING:
      return { ...state, customerGroupsStatus: "pending" };

    case customerTypes.FETCH_CUSTOMERS_GROUP_SUCCESS:
      return { ...state, customerGroupsStatus: "resolved", customerGroups: payload };
    case customerTypes.FETCH_CUSTOMERS_GROUP_FAILURE:
      return { ...state, customerGroupsStatus: "rejected" };

    default:
      return state;
  }
};
