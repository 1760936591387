import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { BsChevronRight } from "react-icons/bs";

import {
  Header,
  Table,
  UpcomingOrders,
  FetchingState,
  EmptyState,
  HeaderSearch,
} from "../../../components";
import { formatDate, formatCurrency } from "../../../utils";
import { transactionActions /*, ordersActions*/ } from "../../../redux/actions";
import styles from "./Home.module.scss";
import format from "date-fns/format";
import add from "date-fns/add";

const columns = [
  {
    Header: "Transaction id",
    accessor: "id",
  },
  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: "Date",
    accessor: "date_ordered",
  },
  {
    Header: "Amount",
    accessor: "amount",
  },
];

const formatData = (data) => {
  const res = data
    ? data.map((d) => ({
        ...d,
        date_ordered: formatDate(d.createdAt),
        date_fulfilled: d.date_fulfilled ? formatDate(d.date_fulfilled) : "-",
        amount: `${formatCurrency(d.amount)}`,
      }))
    : [];
  return res;
};

const t = formatDate(new Date(), "yyyy/LL/dd");
const f = format(add(new Date(), { days: -30 }), "yyyy/LL/dd");

const Home = () => {
  const dispatch = useDispatch();
  const [dateFrom, setDF] = useState(f);
  const [dateTo, setDT] = useState(t);

  const { staffTransactions, CASH, CARD, TRAN, WALLET } = useSelector(
    (state) => state.transactions
  );

  const { loading, profile } = useSelector((state) => state.user);
  const { orders } = useSelector((state) => state.orders);

  useEffect(() => {
    const d = new Date();
    const dateTo = format(d, "yyyy/LL/dd");
    const dateFrom = format(add(d, { days: 1 }), "yyyy/LL/dd");

    //dispatch(ordersActions.fetchOrdersByEndHub(profile.hubId));
    dispatch(
      transactionActions.fetchAllTransactionsByStaff({
        dateFrom,
        dateTo,
        channelId: "POS",
        staffId: profile.id,
      })
    );
    dispatch(
      transactionActions.fetchAllTransactionsTally(
        "CARD",
        profile.id,
        dateTo,
        dateFrom
      )
    );
    dispatch(
      transactionActions.fetchAllTransactionsTally(
        "CASH",
        profile.id,
        dateTo,
        dateFrom
      )
    );
    dispatch(
      transactionActions.fetchAllTransactionsTally(
        "TRAN",
        profile.id,
        dateTo,
        dateFrom
      )
    );
    dispatch(
      transactionActions.fetchAllTransactionsTally(
        "WALLET",
        profile.id,
        dateTo,
        dateFrom
      )
    );
  }, [dispatch, profile]);

  const list = [
    {
      text: "Cash",
      amt: CASH,
    },
    {
      text: "Card",
      amt: CARD,
    },
    {
      text: "Wallet",
      amt: WALLET,
      notCash: true,
    },
    {
      text: "Transfer",
      amt: TRAN,
      notCash: true,
    },
    {
      text: "Total",
      amt: CASH + CARD + WALLET + TRAN,
    },
  ];

  const onStartDateChange = (date) => {
    setDF(formatDate(date, "yyyy/LL/dd"));
  };

  const onEndDateChange = (date) => {
    setDT(formatDate(date, "yyyy/LL/dd"));
  };

  const fetchTallys = () => {
    dispatch(
      transactionActions.fetchAllTransactionsTally(
        "CARD",
        profile.id,
        dateTo,
        dateFrom
      )
    );
    dispatch(
      transactionActions.fetchAllTransactionsTally(
        "CASH",
        profile.id,
        dateTo,
        dateFrom
      )
    );
    dispatch(
      transactionActions.fetchAllTransactionsTally(
        "TRANS",
        profile.id,
        dateTo,
        dateFrom
      )
    );
    dispatch(
      transactionActions.fetchAllTransactionsTally(
        "WALLET",
        profile.id,
        dateTo,
        dateFrom
      )
    );
    dispatch(
      transactionActions.fetchAllTransactionsByStaff({
        dateFrom,
        dateTo,
        channelId: "POS",
        staffId: profile.id,
      })
    );
  };

  return (
    <>
      {loading ? (
        <FetchingState />
      ) : (
        <div>
          <Header
            title={`Hello ${profile?.aspNetUser?.firstName} - ${profile.hubId}`}
          >
            <p className={styles.p}>Choose range for balance</p>
            <HeaderSearch
              onStartDateChange={onStartDateChange}
              onEndDateChange={onEndDateChange}
              useSearch={false}
              disabledDays={{ after: new Date() }}
            />

            <button onClick={fetchTallys} className={styles.go}>
              Go
            </button>
          </Header>
          <section className={styles.grid}>
            <div className={styles.main}>
              <div className={styles.cardList}>
                {list.map((card) => (
                  <div
                    key={card.text}
                    className={classNames(styles.card, {
                      [styles.green]: card.text === "Cash",
                      [styles.purple]: card.text === "Card",
                      [styles.orange]: card.text === "Total",
                      [styles.red]: card.text === "Wallet",
                      [styles.blue]: card.text === "Transfer",
                    })}
                  >
                    <p>{card.text}</p>

                    <h3>
                      {card.notCash ? "" : null} {formatCurrency(card.amt)}
                    </h3>
                  </div>
                ))}
              </div>

              <div className={styles.recent}>
                <p>Recent Transactions</p>
                <NavLink to="dashboard/transaction-history">
                  See all <BsChevronRight />
                </NavLink>
              </div>

              {staffTransactions.length === 0 ? (
                <EmptyState text="No recent transaction." />
              ) : (
                <Table
                  data={formatData(staffTransactions)}
                  paginate
                  bordered
                  wrapperClass={styles.container}
                  columns={columns}
                />
              )}
            </div>
            <aside className={styles.panel}>
              <UpcomingOrders data={orders} />
            </aside>
          </section>
        </div>
      )}
    </>
  );
};

export default Home;
