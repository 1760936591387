export const customerTypes = {
  LOADING: "LOADING",
  FETCH_CUSTOMERS: "FETCH_CUSTOMERS",
  FETCH_CUSTOMERS_TYPES: "FETCH_CUSTOMERS_TYPES",
  FETCH_CITIES: "FETCH_CITIES",
  FETCH_CUSTOMERS_GROUP_IDLE: "FETCH_CUSTOMERS_GROUP_IDLE",
  FETCH_CUSTOMERS_GROUP_SUCCESS: "FETCH_CUSTOMERS_GROUP_SUCCESS",
  FETCH_CUSTOMERS_GROUP_FAILURE: "FETCH_CUSTOMERS_GROUP_FAILURE",
  FETCH_CUSTOMERS_GROUP_PENDING: "FETCH_CUSTOMERS_GROUP_PENDING",


};
