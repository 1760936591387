import React from "react";
import Logo from "../../../assets/images/l00go.png";
import styles from "./MakeOrder.module.scss";
import { FetchingState } from "../../../components";
import { formatCurrency, formatDate } from "../../../utils";
import QRCode from "react-qr-code";

const Waybill = ({ loading, selectedOrder, id, isHH, signatureUrl }) => {
  return (
    <div className={styles.receiptContainer__body}>
      {loading ? (
        <FetchingState />
      ) : (
        <>
          <div className={styles.receiptContainer__body__head}>
            <img alt="logo" src={Logo} />
            <div>
              <p>Track your shipment on www.abccargoxpress.com</p>
              <p>For complaints: 08139862090</p>
            </div>
            <div>
              <QRCode size={100} value={id} />
            </div>
          </div>
          <div className={styles.receiptContainer__body__content}>
            <img alt="abc logo" src={Logo} />
            <div className={styles.receiptContainer__body__content__head}>
              <p>
                Booking Date/Time:{" "}
                {formatDate(selectedOrder.dateCreated, "yyyy-LL-dd HH:mm")}
              </p>
            </div>
            <div className={styles.receiptContainer__body__content__body}>
              <div
                className={styles.receiptContainer__body__content__body__head}
              >
                <h3>
                  Waybill number: &nbsp; &nbsp; &nbsp;
                  {selectedOrder.orderId}
                </h3>
              </div>

              <div
                className={styles.receiptContainer__body__content__body__info}
              >
                <div
                  className={
                    styles.receiptContainer__body__content__body__info__sender
                  }
                >
                  <div
                    className={
                      styles.receiptContainer__body__content__body__info__sender__item
                    }
                  >
                    <h3>Sender Name: </h3>
                    <p>
                      {`${selectedOrder.customerFirstName} ${selectedOrder.customerLastName}`}
                    </p>
                  </div>

                  <div
                    className={
                      styles.receiptContainer__body__content__body__info__sender__item
                    }
                  >
                    <h3>Sender Business Name: </h3>
                    <p>{`${selectedOrder.customerBusinessName || "N/A"}`}</p>
                  </div>

                  {isHH ? (
                    <div
                      className={
                        styles.receiptContainer__body__content__body__info__sender__item
                      }
                    >
                      <h3>Pickup Address: </h3>
                      <p>{selectedOrder.pickupLocation}</p>
                    </div>
                  ) : null}

                  <div
                    className={
                      styles.receiptContainer__body__content__body__info__sender__item
                    }
                  >
                    <h3>Mobile Number: </h3>
                    <p>
                      {selectedOrder.customerPhoneNumber
                        ? `+${selectedOrder.customerPhoneNumber}`
                        : `N/A`}
                    </p>
                  </div>
                </div>
                <div
                  className={
                    styles.receiptContainer__body__content__body__info__sender
                  }
                >
                  <div
                    className={
                      styles.receiptContainer__body__content__body__info__sender__item
                    }
                  >
                    <h3>Receiver Name: </h3>
                    <p>
                      {`${selectedOrder.receiverFirstName} ${selectedOrder.receiverLastName}`}
                    </p>
                  </div>

                  <div
                    className={
                      styles.receiptContainer__body__content__body__info__sender__item
                    }
                  >
                    <h3>Receiver Business Name: </h3>
                    <p>{`${selectedOrder.receiverBusinessName || "N/A"}`}</p>
                  </div>

                  {isHH ? (
                    <div
                      className={
                        styles.receiptContainer__body__content__body__info__sender__item
                      }
                    >
                      <h3>Delivery Address: </h3>
                      <p>
                        {selectedOrder.deliveryLocation ||
                          selectedOrder.pickupLocation}
                      </p>
                    </div>
                  ) : null}

                  <div
                    className={
                      styles.receiptContainer__body__content__body__info__sender__item
                    }
                  >
                    <h3>Mobile Number: </h3>

                    <p>
                      {selectedOrder.receiverPhoneNumber
                        ? `+${selectedOrder.receiverPhoneNumber}`
                        : `N/A`}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div>
              {selectedOrder.itemsList?.map((item, i) => (
                <div key={i} className={styles.itemRow}>
                  <div className={styles.itemRow__item}>
                    <div className={styles.itemRow__item__side}>
                      <h3>Item Description:</h3>
                    </div>
                    <div className={styles.itemRow__item__side__long}>
                      <p>{item.iTemDescription}</p>
                    </div>
                  </div>
                  <div className={styles.itemList}>
                    <div className={styles.itemList__item}>
                      <div className={styles.itemList__item__side}>
                        <h3>Value:</h3>
                      </div>
                      <div className={styles.itemList__item__side}>
                        <p>{item.declared}</p>
                      </div>
                    </div>
                    <div className={styles.itemList__item}>
                      <div className={styles.itemList__item__side}>
                        <h3>Qty:</h3>
                      </div>
                      <div className={styles.itemList__item__side}>
                        <p>{item.quantity}</p>
                      </div>
                    </div>
                    <div className={styles.itemList__item}>
                      <div className={styles.itemList__item__side}>
                        <h3>KG:</h3>
                      </div>
                      <div className={styles.itemList__item__side}>
                        <p>{item.weight}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div
                className={
                  styles.receiptContainer__body__content__body__info__sender__item
                }
              >
                <h3>Freight Pricing: </h3>
                <p>{formatCurrency(selectedOrder.freightPricing)}</p>
              </div>
              <div
                className={
                  styles.receiptContainer__body__content__body__info__sender__item__shrink
                }
              >
                <h3>VAT: </h3>
                <p> {formatCurrency(selectedOrder?.vatPrice)}</p>
              </div>
              <div
                className={
                  styles.receiptContainer__body__content__body__info__sender__item
                }
              >
                <h3>Additional Charges: </h3>

                <div>
                  {selectedOrder?.extraCharges?.map((item, i) => (
                    <p key={i}>
                      {item.chargeTypeId}
                      &nbsp; ({formatCurrency(item.amount)})
                    </p>
                  ))}
                </div>
                <p>
                  <strong> Total &nbsp; = &nbsp;</strong>
                  {formatCurrency(
                    selectedOrder?.extraCharges?.reduce(
                      (sum, item) => (sum += item.amount),
                      0
                    )
                  )}
                </p>
              </div>
            </div>
            <div
              className={
                styles.receiptContainer__body__content__body__info__sender__item
              }
            >
              <h3>Insurance Charge: </h3>
              <p>{formatCurrency(selectedOrder.insurancePrice)}</p>
            </div>
            <div
              className={
                styles.receiptContainer__body__content__body__info__sender__item
              }
            >
              <h3>Total Amount: </h3>
              <p>{formatCurrency(selectedOrder.totalAmount)}</p>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div
                className={
                  styles.receiptContainer__body__content__body__info__sender__item
                }
              >
                <h3>Is collection? </h3>
                <p>{selectedOrder?.isACollection ? "Yes" : "No"}</p>
              </div>
              <div
                className={
                  styles.receiptContainer__body__content__body__info__sender__item
                }
              >
                <h3>Customer group </h3>
                <p>{selectedOrder?.customerGroupId}</p>
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div
                className={
                  styles.receiptContainer__body__content__body__info__sender__item
                }
              >
                <h3>Sender Signature </h3>
                {signatureUrl ? (
                  <div className={styles.sign}>
                    <img alt="signature" src={signatureUrl} />
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <div
                className={
                  styles.receiptContainer__body__content__body__info__sender__item
                }
              >
                <h3>Order Booked By: </h3>
                <p>{selectedOrder.staffName}</p>
              </div>
            </div>

            <h3 style={{ paddingTop: "10px", fontSize: "12px" }}>
              Terms and Conditions
            </h3>
            <p style={{ fontSize: "10px" }}>
              ABC Cargo Express Terms and Conditions Apply
            </p>

            <p style={{ fontSize: "8px" }}>
              <span style={{ fontWeight: "500" }}>Demurrage:</span> All
              consignment must be collected within 3 days of arrival at the
              terminal, failure of which will attract a daily surcharge of ₦150
              for smaller items and ₦300 for bulky items. Consignments abandoned
              at the terminal in excess of one calender year from the date of
              arrival will be disposed off at the discretion of the carrier.
              After second attempt for home delivery, Customer will pickup at
              our terminal.
            </p>
          </div>
        </>
      )}
    </div>
  );
};

export default Waybill;
