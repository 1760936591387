import { hubsTypes } from "../actionTypes";

const initState = {
  hubs: [],
};

export const hubs = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case hubsTypes.FETCH_HUBS:
      return { ...state, hubs: payload };

    default:
      return state;
  }
};
