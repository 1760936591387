import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import { ordersActions } from "../../../../redux/actions";
import styles from "./styles.module.scss";


const ChargeItem = (
    {
        chargeTypes,
        chargeTypesList,
        removeCharge,
        getCharges,
        editList,
        chargeId,
        index
    } ) =>
{
    const [ state, setstate ] = useState( "" );


    const handleTypeChange = ( e ) => 
    {
        const { value } = e.target;
        setstate( value );
        getCharges( value );
    };

    const handleList = id =>
    {
        editList( id, index );
    };

    return (
        <div className={ styles.chargeItem }>

            <div className={ styles.fieldGrp }>
                <label htmlFor={ `chargeTypes` }>Charge types</label>

                <select
                    as="select"
                    id={ `chargeTypes` }
                    name={ `chargeTypes` }
                    value={ state }
                    onChange={ handleTypeChange }
                >
                    <option
                        value="">Select charge type</option>
                    { chargeTypesList.map( ( d, i ) => (
                        <option
                            key={ `${ i }-${ d.name }` }
                            value={ d.id }>
                            { d.name }
                        </option>
                    ) ) }
                </select>
            </div>

            {
                ( state && chargeTypes[ state ] ) ?
                    <>
                        {
                            chargeTypes[ state ].length ?
                                <>
                                    {
                                        chargeTypes[ state ].map( ( item, i ) => (
                                            <>
                                                { item.name && (
                                                    <label key={ item.chargeTypeId } style={ { marginRight: "40px" } }>
                                                        <input
                                                            type="radio"
                                                            name={ `${ index }-${ state }` }
                                                            value={ item.id }
                                                            key={ item.id }
                                                            onChange={ ( e ) =>
                                                            {
                                                                handleList( e.target.value );
                                                            } }
                                                        />
                                                        &nbsp;{ item.name }
                                                    </label>
                                                ) }
                                            </>
                                        ) )
                                    }
                                </>
                                : <p>No charges available</p>
                        }
                    </> : null
            }

            <button
                className={ styles.remove }
                type="button"
                onClick={ removeCharge } >
                Remove Charge
            </button>


        </div>
    );
};

const ChargeTypes = (
    {
        chargeIdList,
        handleChargeIds,
        chargeTypes
    } ) => 
{

    const dispatch = useDispatch();

    const [ charges, setCharges ] = useState( {
        loading: false,
        error: null,
        data: {}
    } );

    const [ dropDwns, setDrpdwns ] = useState( [] );


    const getCharges = ( chargeTypeId ) =>
    {
        if ( !charges.data[ chargeTypeId ] )
        {

            const onSuccess = ( chargeTypeId, data ) =>
            {
                setCharges( {
                    loading: false,
                    error: null,
                    data:
                    {
                        ...charges.data,
                        [ chargeTypeId ]: data
                    }

                } );
            };

            const onFail = ( chargeTypeId, err ) =>
            {
                setCharges( {
                    ...charges,
                    loading: false,
                    status: "rejected",
                    error: `${ err }` || `Unable to get charges for ${ chargeTypeId }`
                } );
            };

            dispatch( ordersActions.fetchChargesByType( chargeTypeId, onSuccess, onFail ) );

        }

    };

    // const addCharge = () =>
    // {
    //     setDrpdwns( list => [ ...list, true ] );
    // };

    const removeCharge = ( index ) =>
    {
        setDrpdwns( list => list.filter( ( _, i ) => i !== index ) );

        const list = [ ...chargeIdList ].filter( ( _, i ) => i !== index );
        handleChargeIds( list );
    };

    const editList = ( chargeId, index ) =>
    {
        const list = [ ...chargeIdList ];
        list[ index ] = chargeId;

        handleChargeIds( list );
    };



    return (
        <div className={ styles.chargeRow }>

            {
                dropDwns.map( ( d, i ) => <ChargeItem
                    chargeTypesList={ chargeTypes }
                    key={ i }
                    chargeTypes={ charges.data }
                    getCharges={ getCharges }
                    removeCharge={ () => removeCharge( i ) }
                    index={ i }
                    editList={ editList }
                /> )
            }

            {/* <button onClick={ addCharge } type="button">
                Add Charges
            </button> */}
        </div>
    );
};

export default ChargeTypes;
