import React, { useEffect, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { useHistory } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import { ordersActions } from "../../../redux/actions";
import SignatureCanvas from "react-signature-canvas";
import { Pane, Dialog, Button } from "evergreen-ui";
import { toast } from "react-hot-toast";

import styles from "./MakeOrder.module.scss";
import Waybill from "./Waybill";

import Axios from "axios";

const Receipt = () => {
  const history = useHistory();
  const [isShown, setIsShown] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);

  const [sign, setSign] = React.useState("");

  const id = window.location.pathname.split("/")[3];
  const { selectedOrder, loading } = useSelector((state) => state.orders);

  const printRef = useRef();
  const sigCanvas = useRef();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(ordersActions.fetchOrderById(id));
  }, [dispatch, id]);

  useEffect(() => {
    setSign((sign) => selectedOrder.customerSignatureUrl || sign);
  }, [selectedOrder.customerSignatureUrl]);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  const handleClear = () => {
    sigCanvas.current.clear();
  };

  const handleSigUpdate = async (signatureUrl = "") => {
    setSubmitting(true);

    toast.loading("Updating signature");

    try {
      const token = localStorage.getItem("pos-token");

      await Axios.post(
        `https://api.abccargoxpress.com/api/v1/Orders/AddSignatureToOrder`,
        {
          orderId: id,
          signatureUrl,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setSign(signatureUrl);
      setIsShown(false);
      setSubmitting(false);

      dispatch(ordersActions.fetchOrderById(id));
      toast.dismiss();

      if (!signatureUrl) {
        toast.success("Removed signature from waybill");
        return;
      }
      toast.success("Added signature to waybill");
    } catch (error) {
      setSubmitting(false);
      toast.dismiss();

      if (!signatureUrl) {
        toast.error("Unable to remove signature from waybill");
      } else {
        toast.error("Unable to add signature to waybill");
      }
    }
  };

  const handleDone = async () => {
    const URL = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");

    await handleSigUpdate(URL);
  };

  const removeExsiting = async () => {
    await handleSigUpdate("");
  };

  const roleName = useSelector(
    (state) => state?.user?.profile?.aspNetUser?.roleName
  );
  const lowercaseUserRole = roleName?.toLowerCase();

  const isHH = id ? (id.startsWith("HH") ? false : true) : false;

  return (
    <div>
      {/* hide the buttons if already signed? */}
      {/* {selectedOrder.customerSignatureUrl ? null : (
        <div className={styles.signActions}>
          <Button
            appearance="primary"
            intent="success"
            onClick={() => setIsShown(true)}
          >
            Add Signature
          </Button>
          <Button appearance="primary" intent="danger" onClick={removeExsiting}>
            Remove Signature
          </Button>
        </div>
      )} */}

      <div className={styles.signActions}>
        <Button
          appearance="primary"
          intent="success"
          onClick={() => setIsShown(true)}
        >
          Add Signature
        </Button>
        {lowercaseUserRole === "sys admin" && (
          <Button appearance="primary" intent="danger" onClick={removeExsiting}>
            Remove Signature
          </Button>
        )}
      </div>

      <div ref={printRef}>
        <Waybill
          selectedOrder={selectedOrder}
          id={id}
          loading={loading}
          isHH={isHH}
          signatureUrl={sign}
        />

        <Waybill
          selectedOrder={selectedOrder}
          id={id}
          loading={loading}
          isHH={isHH}
          signatureUrl={sign}
        />
      </div>
      <Pane>
        <Dialog
          isShown={isShown}
          hasFooter={false}
          title="Draw signature"
          preventBodyScrolling
          onCloseComplete={() => setIsShown(false)}
        >
          {({ close }) => (
            <Pane>
              <SignatureCanvas
                penColor="black"
                canvasProps={{ className: styles.sigCanvas }}
                ref={sigCanvas}
                minWidth={4}
                maxWidth={8}
              />

              <div className={styles.canvasActions}>
                <Button
                  disabled={submitting}
                  intent="success"
                  onClick={handleDone}
                >
                  {submitting ? "Accepting..." : "Accept"}
                </Button>
                <Button
                  disabled={submitting}
                  intent="danger"
                  onClick={handleClear}
                >
                  Clear
                </Button>
              </div>
            </Pane>
          )}
        </Dialog>
      </Pane>
      <br />
      <br />
      <div className={styles.receiptContainer__body__btn}>
        <button onClick={handlePrint}>Print Waybill</button>
      </div>
      <br />
      <hr />
      <br />
      <div className={styles.receiptContainer__body__btn}>
        <button
          style={{ backgroundColor: "#CB392A" }}
          onClick={() => history.push("/dashboard/make-order")}
        >
          Contract waybill
        </button>
      </div>
    </div>
  );
};

export default Receipt;
