export const ordersTypes = {
  LOADING: "LOADING",
  FETCH_ORDERS: "FETCH_ORDERS",
  FETCH_ORDER_BY_ID: "FETCH_ORDER_BY_ID",
  FETCH_DELIVERY_TYPES: "FETCH_DELIVERY_TYPES",
  FETCH_VEHICLE_TYPES: "FETCH_VEHICLE_TYPES",
  FETCH_SHIPMENT_TYPES: "FETCH_SHIPMENT_TYPES",
  FETCH_SERVICE_TYPES: "FETCH_SERVICE_TYPES",
  FETCH_SOURCES: "FETCH_SOURCES",
  FETCH_CHANNELS: "FETCH_CHANNELS",
  FETCH_CHARGES: "FETCH_CHARGES",
  FETCH_CHARGETYPES: "FETCH_CHARGETYPES",
  FETCH_ITEM_STATUS: "FETCH_ITEM_STATUS",
  FETCH_ITEM_TYPES: "FETCH_ITEM_TYPES",
  FETCH_TAXES: "FETCH_TAXES",
  FETCH_INSURANCES: "FETCH_INSURANCES",
  FETCH_PACKAGING_COSTS: "FETCH_PACKAGING_COSTS",
  VIEW_CUSTOMER: "VIEW_CUSTOMER",
  UPDATE_ORDERS_VALUES: "UPDATE_ORDERS_VALUES",
  UPDATE_ORDER_ITEM_VALUES: "UPDATE_ORDER_ITEM_VALUES",
  CLEAR_ORDER: "CLEAR_ORDER",
  FETCH_ORDER_STATUSES: "FETCH_ORDER_STATUSES",
  SET_SEARCHBY: "SET_SEARCHBY",
  UPDATE_ORDER: "UPDATE_ORDER",
  FETCH_ORDER_BY_ID_FAIL:"FETCH_ORDER_BY_ID_FAIL",

  //exp
  UPDATE_ITEM_INSURANCES: "UPDATE_ITEM_INSURANCES",
  CLEAR_ITEM_INSURANCES: "CLEAR_ITEM_INSURANCES",

  START_LOADING_ORDER_PRICE: "START_LOADING_ORDER_PRICE",
  END_LOADING_ORDER_PRICE: "END_LOADING_ORDER_PRICE",
};
