import React, { useEffect, forwardRef } from 'react';
import { useTable, usePagination } from 'react-table';
import styles from './Orders.module.scss';

import { waybillByStaffCols as columns } from "../../../utils";


const POT = ( { orders,
    fieldForTotal = "amt",
    header,
    formatter }, ref ) => 
{
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        setPageSize,

    } = useTable(
        {
            columns,
            data: orders,
            initialState: { pageIndex: 0 },
        },
        usePagination
    );


    const getTotal = () =>
    {

        const total = orders.reduce( ( sum, o ) =>
        {
            sum = sum + ( +o[ fieldForTotal ] );

            return sum;
        }, 0 );

        return formatter ? formatter( total ) : total;
    };


    useEffect( () =>
    {
        if ( orders.length )
        {
            setPageSize( orders.length );
        }
    }, [ orders.length, setPageSize ] );


    return (

        <div ref={ ref } className={ styles.tableToPrint }>

            { header }
            <table
                { ...getTableProps() }
            >
                <thead>
                    { headerGroups.map( ( headerGroup ) => (
                        <tr { ...headerGroup.getHeaderGroupProps() }>
                            { headerGroup.headers.map( ( column ) => (
                                <th { ...column.getHeaderProps() }>{ column.render( "Header" ) }</th>
                            ) ) }
                        </tr>
                    ) ) }
                </thead>
                <tbody { ...getTableBodyProps() }>
                    { page.map( ( row, i ) =>
                    {
                        prepareRow( row );
                        return (
                            <tr
                                { ...row.getRowProps() }
                            >
                                { row.cells.map( ( cell ) =>
                                {
                                    return (
                                        <td { ...cell.getCellProps() }>{ cell.render( "Cell" ) }</td>
                                    );
                                } ) }
                            </tr>
                        );
                    } ) }
                </tbody>
            </table>


            <h3 className={ styles.total }>Total : { getTotal() }</h3>


        </div>
    );
};

const PrintOrderTable = forwardRef( POT );

export { PrintOrderTable };
