import React from 'react';
import styles from "./Layout.module.scss";

const Layout = ( props ) =>
{
    const { footerComp, navComp, mainComp } = props;


    return (
        <div className={ styles.Layout }>
            <aside className={ styles.menu }>
                { navComp }
            </aside>
            <main className={ styles.main }>
                <div className={ styles.content } >
                    { mainComp }
                </div>
            </main>
            <footer className={ styles.footer }>
                { footerComp }

            </footer>
        </div>
    );
};

export { Layout };
