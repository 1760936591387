import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Section from "./Section";
import { Field } from "formik";
import { Searchable } from "../../../../components";
import { ordersActions, hubsActions } from "../../../../redux/actions";
import styles from "./styles.module.scss";

const Delivery = ({ formikProps }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(ordersActions.fetchDeliveryTypes());
    dispatch(hubsActions.fetchAllHubs());
    dispatch(ordersActions.fetchServiceTypes());
  }, [dispatch]);

  const { deliveryTypes, newOrder, serviceTypes } = useSelector(
    (state) => state.orders
  );
  const { hubs } = useSelector((state) => state.hubs);

  const deliveryItems = deliveryTypes.filter((item) => item.id[0] !== "D");

  const serviceItems = serviceTypes; //.filter( ( item ) => item.id !== "LMD" );

  return (
    <Section title="Delivery details">
      <div className={styles.flex}>
        <div className={styles.fieldGrp}>
          <label htmlFor={`delivery.deliveryType`}>Delivery Type</label>
          <Field
            as="select"
            id={`delivery.deliveryType`}
            name={`delivery.deliveryType`}
            value={newOrder.deliveryTypeId}
            onChange={(e) =>
              dispatch(
                ordersActions.updateOrderValues(
                  "deliveryTypeId",
                  e.target.value
                )
              )
            }
          >
            <option value="">Select delivery type</option>
            {deliveryItems.map((d) => (
              <option
                key={d.name}
                value={d.id}
                // onChange={ ( e ) =>
                //   dispatch(
                //     ordersActions.updateOrderValues(
                //       "deliveryTypeId",
                //       e.target.value
                //     )
                //   )
                // }
              >
                {d.name}
              </option>
            ))}
          </Field>
        </div>
        <div className={styles.fieldGrp}>
          <label htmlFor={`Service.service`}>Service Type</label>
          <Field
            as="select"
            id={`Service.service`}
            name={`Service.service`}
            value={newOrder.serviceId}
            onChange={(e) =>
              dispatch(
                ordersActions.updateOrderValues("serviceTypeId", e.target.value)
              )
            }
          >
            <option value="">Select Service type</option>
            {serviceItems.map((d) => (
              <option
                key={d.name}
                value={d.id}
                // onChange={ ( e ) =>
                //   dispatch(
                //     ordersActions.updateOrderValues(
                //       "serviceTypeId",
                //       e.target.value
                //     )
                //   )
                // }
              >
                {d.name}
              </option>
            ))}
          </Field>
        </div>
        <div className={styles.fieldGrp}>
          <label htmlFor={`delivery.destinationHub`}>Destination Hub</label>
          <Field
            id={`delivery.destinationHub`}
            name={`delivery.destinationHub`}
          >
            {() => (
              <Searchable
                placeholder="Select destination hub"
                notFoundText="No result found"
                name={`delivery.destinationHub`}
                options={hubs}
                handleChange={(value, description) => {
                  dispatch(
                    ordersActions.updateOrderValues("destinationHubId", value)
                  );
                  dispatch(
                    ordersActions.updateOrderValues(
                      "destinationHubAddress",
                      description
                    )
                  );
                }}
              />
            )}
          </Field>
        </div>

        {formikProps.values.delivery.deliveryType === "Hub to Door" ? (
          <div className={styles.fieldGrp}>
            <label htmlFor={`delivery.finalAddress`}>Final address</label>
            <Field
              id={`delivery.finalAddress`}
              as="textarea"
              name={`delivery.finalAddress`}
            />

            {formikProps.touched?.delivery?.finalAddress &&
            !formikProps.values.delivery.finalAddress ? (
              <small>Please input destination address</small>
            ) : null}
          </div>
        ) : null}
      </div>
    </Section>
  );
};

export { Delivery };
