import React, { useState } from "react";
import {
  Dialog,
  Pane,
  Button,
  TextInputField,
  TextareaField,
  Spinner,
  Alert,
  SelectField,
} from "evergreen-ui";
import Axios from "axios";

const baseUrl = "https://api.abccargoxpress.com/api/v1/";

const CreateIrregularity = ({ isShown, setIsShown, types, go }) => {
  const [field, setFields] = useState({
    name: "",
    description: "",
    irregularityTypeId: "",
    orderId: "",
  });

  const [state, setState] = useState({
    error: null,
    status: "idle",
  });

  const [fieldErrs, setFieldErrs] = useState({
    name: false,
    description: false,
    orderId: false,
  });

  const handleClose = () => {
    setIsShown(false);
    setFields({
      name: "",
      description: "",
      irregularityTypeId: "",
      orderId: "",
    });
    setFieldErrs({
      name: false,
      description: false,
      orderId: false,
    });
    setState({
      error: null,
      status: "idle",
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFields({
      ...field,
      irregularityTypeId: field.irregularityTypeId || "missing",
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    if (field.name && field.description && field.orderId) {
      setState({
        error: null,
        status: "pending",
      });

      try {
        const token = localStorage.getItem("pos-token");
        const staffId = localStorage.getItem("pos-user");

        const body = {
          ...field,
          irregularityTypeId: field.irregularityTypeId || "missing",
          staffId,
          reportTypeId: "irregularity_report",
        };

        await Axios.post(`${baseUrl}IrregularityReports/Create`, body, {
          headers: { Authorization: `Bearer ${token}` },
        });

        setState({
          error: null,
          status: "resolved",
        });

        go();
      } catch (error) {
        setState({
          status: "rejected",
          error:
            error.response?.data ||
            error.toString() ||
            "Unable to create irregularity",
        });
      }
    } else {
      setFieldErrs({
        name: !field.name,
        description: !field.description,
        orderId: !field.orderId,
      });
    }
  };

  return (
    <Pane>
      <Dialog
        isShown={isShown}
        title="Create Irregularity Report"
        onCloseComplete={handleClose}
        preventBodyScrolling
        hasFooter={false}
      >
        {state.status === "rejected" ? (
          <Alert
            intent="danger"
            title="Error creating report"
            marginBottom={20}
          >
            {state.error}
          </Alert>
        ) : null}

        {state.status === "resolved" ? (
          <Alert
            intent="success"
            title="Created report successfully"
            marginBottom={20}
            isRemoveable
            onRemove={handleClose}
          >
            {state.error}
          </Alert>
        ) : null}

        <TextInputField
          label="Waybill Id"
          value={field.orderId}
          onChange={handleChange}
          isInvalid={fieldErrs.orderId}
          validationMessage={fieldErrs.orderId && "Waybill Id is required."}
          required
          name="orderId"
        />

        <TextInputField
          label="Name"
          placeholder="This is a description name."
          isInvalid={fieldErrs.name}
          required
          value={field.name}
          onChange={handleChange}
          name="name"
          validationMessage={
            fieldErrs.description && "Name of irregularity is required."
          }
        />
        <TextareaField
          isInvalid={fieldErrs.description}
          value={field.description}
          label="Description"
          required
          validationMessage={
            fieldErrs.description && "Description of irregularity is required."
          }
          name="description"
          onChange={handleChange}
          placeholder="Description of irregularity..."
        />

        <SelectField
          value={field.irregularityTypeId}
          onChange={handleChange}
          label="Irregularity type"
          required
          name="irregularityTypeId"
        >
          {types.map((t) => (
            <option key={t.id} value={t.id}>
              {t.name}
            </option>
          ))}
        </SelectField>

        {state.status === "pending" ? (
          <Spinner size={16} />
        ) : (
          <Button onClick={handleSubmit}>Report</Button>
        )}

        <br />
      </Dialog>
    </Pane>
  );
};

export default CreateIrregularity;
