import React, { useState } from "react";

import { formatCurrency } from "../../../utils";
import styles from "./CollectionSteps.module.scss";

const UpdateOrder = ({ order, handleUpdateStatus, updating, fail }) => {
  const [otp, setOtp] = useState({
    value: "",
    error: "",
  });

  const handleOTPSubmit = (e) => {
    e.preventDefault();
    if (otp.value !== order?.token) {
      setOtp((state) => ({ ...state, error: "Incorrect OTP" }));
    } else {
      setOtp((state) => ({ ...state, error: "" }));
      handleUpdateStatus();
    }
  };

  return (
    <div>
      <header className={styles.heading}>
        <h3 className={styles.text}>Confirm OTP</h3>
      </header>

      <section className={styles.details}>
        <p>
          <strong>Order ID: </strong> {order?.orderId}
        </p>

        <p>
          <strong>Amount paid: </strong> {formatCurrency(order?.totalAmount)}
        </p>

        <br />

        <div className={styles.formWrapper}>
          <h3 className={styles.title}>Enter OTP below</h3>

          <form className={styles.form} onSubmit={handleOTPSubmit}>
            <input
              onChange={(e) =>
                setOtp((state) => ({ ...state, value: e.target.value }))
              }
              value={otp.value}
            />

            <small>{otp.error || fail}</small>

            <button disabled={updating} type="submit">
              {updating ? "Updating status" : "Validate OTP"}
            </button>
          </form>
        </div>
      </section>
    </div>
  );
};

export default UpdateOrder;
