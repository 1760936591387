import React from "react";
import Item from "./Item";
import styles from "./UpcomingOrders.module.scss";

const UpcomingOrders = ({ data = [] }) => {
  return (
    <div className={styles.upcoming}>
      <h3 className={styles.title}>Attention</h3>
      {data?.length === 0 ? (
        <div className={styles.empty}>
          <p>No waybills need attention</p>
        </div>
      ) : (
        data.map((d, i) => <Item key={i} {...d} />)
      )}
    </div>
  );
};

export { UpcomingOrders };
