import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import Login from "./Login";

import ForgotPassword from "./ForgotPassword";

const Auth = () =>
{
  let match = useRouteMatch();
  return (
    <Switch>
      <Route path={ match.path + "/forgot-password" }>
        <ForgotPassword />
      </Route>
      <Route path={ match.path }>
        <Login />
      </Route>

    </Switch>
  );
};

export { Auth };
