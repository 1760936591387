import React from "react";
import { format, isAfter } from "date-fns";
import { matchSorter } from "match-sorter";
import currencies from "./currencies";
import { store } from "./redux/reducers";

const dateFormatter = (value) => {
  if (!value) return "N/A";

  const formatter = new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  const date = new Date(value);

  return formatter.format(date);
};

const formatDate = (value, style = "dd/LL/yyyy") => {
  if (!value) return "N/A";
  return format(new Date(value), style);
};

const stripTimeZone = (value) => {
  const p = new Date(value).toISOString();
  return p.substr(0, 19);
};

const getTotal = (charges) => {
  let allCharges = 0;

  for (const item of charges) {
    const itemCharges = item.itemCharges;

    for (const c of itemCharges) {
      if (+c.chargeAmt > 0) {
        allCharges = allCharges + +c.chargeAmt;
      }
    }
  }

  return allCharges ? new Intl.NumberFormat("en-NG").format(allCharges) : 0;
};

// const formatCurrency = (value) => {
//   return value ? new Intl.NumberFormat("en-NG").format(value) : 0;
// };

// const formatCurrency2 = (num) => {
//   return typeof num === "number"
//     ? Math.sign(num) === -1
//       ? `-₦${num
//           .toFixed(2)
//           .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
//           .slice(1)}`
//       : `₦${num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}`
//     : `-`;
// };

const formatNumber = (value) => {
  return value ? new Intl.NumberFormat("en-NG", {}).format(value) : 0;
};

const truncateAndFormat = (integer, fraction) => {
  if (fraction.toString().length) {
    return `${formatNumber(integer)}.${fraction}`;
  }

  return formatNumber(integer);
};

const cleanNumberString = (value) => {
  let normalized = value.toString();

  //remove all chars except number and dot
  normalized = normalized.replace(/[^\d.]/g, "");

  //replace all dots except first
  let index = 0;
  let result = normalized.replace(/\./g, (item) => (!index++ ? item : ""));

  return result;
};

const getSymbol = () => {
  let symbol = "";

  const userCurrency = store?.getState()?.user?.currencyId;

  if (currencies[userCurrency]) {
    symbol = currencies[userCurrency].symbolNative;
  }

  return symbol;
};

const formatCurrency = (amount) => {
  if (isNaN(amount)) {
    return "";
  }

  const prefix = getSymbol();

  const roundedAmount = Math.round(amount * 100) / 100;

  const formattedAmount = roundedAmount.toFixed(2);

  return `${prefix}${formattedAmount.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
};

// const formatCurrency = (result) => {
//   if (!result) {
//     return isNaN(result) ? "" : result;
//   }
//   const val = cleanNumberString(result?.toString());

//   const symbol = getSymbol();

//   if (val.includes(".")) {
//     const split = val.split(".");

//     if (split[1].length) {
//       const formatted = truncateAndFormat(+split[0], split[1]);

//       return `${symbol}${formatted}`;
//     }

//     return `${symbol}${formatNumber(+split[0])}.`;
//   }

//   return `${symbol}${formatNumber(val)}`;
// };

const isAfterDate = (date, dateToCompare) => {
  return isAfter(new Date(date), new Date(dateToCompare));
};

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, {
    keys: [
      (item) => item.values.items.map((i) => i.name),
      (item) => item.values.items.map((i) => i.description),
      (item) => item.values.items.map((i) => i.quantity),
      (item) => item.values.items.map((i) => i.declared),
      (item) => item.values.items.map((i) => i.itemName),
      (item) => item.values.items.map((i) => i.itemDescription),
    ],
    threshold: matchSorter.rankings.CONTAINS,
  });
}

fuzzyTextFilterFn.autoRemove = (val) => !val;

function DefaultColumnFilter({ column: { filterValue, setFilter } }) {
  return (
    <input
      value={filterValue || ""}
      style={{
        display: "block",
        margin: "5px auto",
        padding: "8px",
        fontSize: "14px",
        width: "90%",
      }}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      placeholder={`Search in items...`}
    />
  );
}

const ItemsDesc = ({ item, i }) => {
  return (
    <div key={i}>
      <div>
        <div>
          <h5>Item Name:</h5>
          <p>{item.itemName || item.name}</p>
        </div>

        <div>
          <h5>Description:</h5>
          <p>{item.itemDescription || item.description}</p>
        </div>

        <div>
          <h5>Quantity:</h5>
          <p>{item.quantity || item.quantity}</p>
        </div>
        <div>
          <h5>Weight:</h5>
          <p>{item.weight || item.weight}</p>
        </div>
        <div>
          <h5>Declared Value:</h5>
          <p>{formatCurrency(item.declared)}</p>
        </div>
      </div>
    </div>
  );
};

const waybillColumns = [
  {
    Header: "Waybill no",
    accessor: "id",
  },
  {
    Header: "Status",
    accessor: "orderStatusId",
  },
  {
    Header: "Item Description",
    accessor: "items",
    Filter: DefaultColumnFilter,
    filter: "fuzzyText",
    Cell: ({ value }) => value.map((v, i) => <ItemsDesc item={v} i={i} />),
  },
  {
    Header: "Source",
    accessor: "sourceId",
  },
  {
    Header: "Channel",
    accessor: "channelId",
  },
  {
    Header: "Date contracted",
    accessor: "date_ordered",
  },
  {
    Header: "Amount",
    accessor: "amount",
  },
];

const waybillByStaffCols = [
  {
    Header: "Waybill no",
    accessor: "orderId",
  },
  {
    Header: "Freight Base price",
    accessor: "freightPrice",
  },
  {
    Header: "Item description",
    accessor: "items",
    Filter: DefaultColumnFilter,
    filter: "fuzzyText",
    Cell: ({ value }) => value.map((v, i) => <ItemsDesc item={v} i={i} />),
  },
  {
    Header: "Charges",
    accessor: "totalCharges",
  },
  {
    Header: "VAT",
    accessor: "vatPrice",
  },
  {
    Header: "Insurance",
    accessor: "insurancePrice",
  },
  {
    Header: "Amount",
    accessor: "totalAmount",
  },
  {
    Header: "Date contracted",
    accessor: "dateCreated",
  },
  {
    Header: "Customer name",
    accessor: "customerName",
  },
  {
    Header: "Customer phone",
    accessor: "customerPhoneNumber",
  },
  {
    Header: "Receiver name",
    accessor: "receiverName",
  },
  {
    Header: "Receiver phone",
    accessor: "receiverPhoneNumber",
  },
];

const map = {
  "delivered item": "Arrived",
  "Picked Up": "Delivered",
  delivered: "Arrived",
};

const formatData = (data) => {
  return data.map((d) => ({
    ...d,
    date_ordered: formatDate(d.createdAt),
    date_fulfilled: d.date_fulfilled ? formatDate(d.date_fulfilled) : "-",
    amt: d.amount,
    amount: formatCurrency(d.amount),
    orderStatus: map[d] || d,
  }));
};

const formatDataStaff = (data) => {
  return data.map((d) => ({
    ...d,
    amt: d.totalAmount,
    totalAmount: formatCurrency(d.totalAmount),
    insurancePrice: formatCurrency(d.insurancePrice),
    freightPrice: formatCurrency(d.freightPrice),
    totalCharges: formatCurrency(d.totalCharges),
    packagingCost: formatCurrency(d.packagingCost),
    vatPrice: formatCurrency(d.vatPrice),
    dateCreated: formatDate(d.dateCreated),
  }));
};

const addUpInsurance = (newOrder) => {
  let sum = 0;

  for (const key in newOrder?.insuranceList) {
    if (Object.hasOwnProperty.call(newOrder?.insuranceList, key)) {
      sum = +newOrder?.insuranceList[key] + sum;
    }
  }

  return sum;
};

const addUpCharges = (charges = []) => {
  let sum = charges.reduce((total, c) => {
    total += c.amount;
    return total;
  }, 0);

  return sum;
};

export {
  addUpCharges,
  dateFormatter,
  addUpInsurance,
  stripTimeZone,
  getTotal,
  formatDate,
  formatCurrency,
  isAfterDate,
  waybillColumns,
  waybillByStaffCols,
  formatData,
  formatDataStaff,
  fuzzyTextFilterFn,
  DefaultColumnFilter,
};
