import Axios from "axios";
import { customerTypes } from "../actionTypes";

const baseUrl = "https://abcapi.fusionintel.io/api/v1/";

const fetchAllCustomers = () => async (dispatch) => {
  dispatch({
    type: customerTypes.LOADING,
    payload: true,
  });
  try {
    const token = localStorage.getItem("pos-token");
    const response = await Axios.get(
      `${baseUrl}Customers/ViewAllCustomers`,

      { headers: { Authorization: `Bearer ${token}` } }
    );

    dispatch({
      type: customerTypes.FETCH_CUSTOMERS,
      payload: response.data.filter((p) => p.isActive),
    });
    dispatch({
      type: customerTypes.LOADING,
      payload: false,
    });
  } catch (error) {
    dispatch({
      type: customerTypes.LOADING,
      payload: false,
    });
  }
};

const fetchCustomerByPhone =
  (number, onSuccess, onFail) => async (dispatch) => {
    try {
      const token = localStorage.getItem("pos-token");
      const response = await Axios.get(
        `${baseUrl}Customers/ViewCustomerByPhoneNumber?phoneNumber=${number}`,

        { headers: { Authorization: `Bearer ${token}` } }
      );

      return onSuccess(response.data);
    } catch (err) {
      return onFail(
        err.response?.data || err.toString() || "Unable to find customer"
      );
    }
  };

const fetchCustomersTypes = () => async (dispatch) => {
  try {
    const token = localStorage.getItem("pos-token");
    const response = await Axios.get(
      `${baseUrl}CustomerTypes/ViewAllCustomerTypes`,

      { headers: { Authorization: `Bearer ${token}` } }
    );
    dispatch({
      type: customerTypes.FETCH_CUSTOMERS_TYPES,
      payload: response.data,
    });
  } catch (err) {
    console.log(err);
  }
};

const createCustomerAccount = (body, onSuccess, onFail) => async (dispatch) => {
  try {
    const token = localStorage.getItem("pos-token");
    const response = await Axios.post(
      `${baseUrl}Customers/CreateCustomerAccount`,
      { ...body, sourceId: "POS" },

      { headers: { Authorization: `Bearer ${token}` } }
    );

    onSuccess(response.data);

    return;
  } catch (err) {
    return onFail(err.response?.data || err);
  }
};

const updateCustomerAccount = (body, onSuccess, onFail) => async (dispatch) => {
  try {
    const token = localStorage.getItem("pos-token");
    const response = await Axios.post(
      `${baseUrl}Customers/UpdateCustomerAccount`,
      body,

      { headers: { Authorization: `Bearer ${token}` } }
    );

    onSuccess(response.data);

    return;
  } catch (err) {
    return onFail(err.response?.data || err);
  }
};

// const fetchCities = () => async ( dispatch ) =>
// {
//   try
//   {
//     const token = localStorage.getItem( "pos-token" );
//     const response = await Axios.get(
//       `${ baseUrl }Cities/ViewAllCities`,

//       { headers: { Authorization: `Bearer ${ token }` } }
//     );
//     dispatch( {
//       type: customerTypes.FETCH_CITIES,
//       payload: response.data,
//     } );
//   } catch ( err )
//   {
//     console.log( err );
//   }
// };

const fetchTransactions = (body, onSuccess, onFail) => async (dispatch) => {
  try {
    const token = localStorage.getItem("pos-token");
    const response = await Axios.post(
      `${baseUrl}Transactions/ListByCustomer`,
      body,
      { headers: { Authorization: `Bearer ${token}` } }
    );

    onSuccess(response.data);
  } catch (err) {
    onFail(err.response.data);
  }
};

const fetchOrders = (body, onSuccess, onFail) => async (dispatch) => {
  try {
    const token = localStorage.getItem("pos-token");
    const response = await Axios.post(`${baseUrl}Orders/ListByCustomer`, body, {
      headers: { Authorization: `Bearer ${token}` },
    });

    onSuccess(response.data);
  } catch (err) {
    onFail(err.response.data);
  }
};

const topWallet = (body, onSuccess) => async (dispatch) => {
  try {
    const token = localStorage.getItem("pos-token");
    const response = await Axios.post(
      `${baseUrl}Customers/CreditCustomer`,
      body,
      { headers: { Authorization: `Bearer ${token}` } }
    );

    onSuccess(response);
  } catch (err) {}
};

const fetchCustomerGroups = () => async (dispatch) => {
  dispatch({
    type: customerTypes.FETCH_CUSTOMERS_GROUP_PENDING,
  });

  try {
    const token = localStorage.getItem("pos-token");
    const response = await Axios.get(`${baseUrl}CustomerGroups/ListAll`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    dispatch({
      type: customerTypes.FETCH_CUSTOMERS_GROUP_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: customerTypes.FETCH_CUSTOMERS_GROUP_FAILURE,
      payload:
        error.response?.data ||
        error.toString() ||
        "Unable to retrieve customer groups",
    });
  }
};

export const customerActions = {
  fetchAllCustomers,
  fetchCustomerByPhone,
  fetchCustomersTypes,
  createCustomerAccount,
  fetchTransactions,
  topWallet,
  fetchOrders,
  updateCustomerAccount,
  fetchCustomerGroups,
};
