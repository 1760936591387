import React, { useState } from "react";
import styles from "./styles.module.scss";

import { uuid } from "uuidv4";

const ExtraChargeItem = ({
  chargeTypesList,
  removeCharge,
  editExtra,
  index,
}) => {
  const [state, setstate] = useState({
    id: uuid(),
    amount: "",
    chargeTypeId: "",
  });

  const handleTypeChange = (e) => {
    const { value } = e.target;
    const newVal = {
      ...state,
      chargeTypeId: value,
    };

    setstate(newVal);
    editExtra(newVal, index);
  };

  const handleEC = (e) => {
    let { value } = e.target;

    if (value && value.includes("-")) {
      value = value.replace(/-/gi, "");
    }

     let newVal = {
       ...state,
       amount: value,
     };

    setstate(newVal);
    editExtra(newVal, index);
  };

  // const [inputValue, setInputValue] = useState("");

  // const validateInput = () => {
  //   if (inputValue < 0) {
  //     return (
  //       <span style={{ color: "red" }}>
  //         Value must be greater than or equal to 0
  //       </span>
  //     );
  //   }
  //   return null;
  // };

  return (
    <div className={styles.chargeItem}>
      <div className={styles.fieldGrp}>
        <label htmlFor={`chargeTypes`}>Charge types</label>

        <select
          as="select"
          id={`chargeTypes`}
          name={`chargeTypes`}
          value={state.chargeTypeId}
          onChange={handleTypeChange}
        >
          <option value="">Select charge type</option>
          {chargeTypesList.map((d, i) => (
            <option key={`${d.name}-${i}`} value={d.id}>
              {d.name}
            </option>
          ))}
        </select>

        <input
          type="number"
          onChange={handleEC}
          disabled={!state.chargeTypeId}
          min="0.0001"
          value={state.amount}
        />

        {state.amount && state.amount < 0.001 ? (
          <span style={{ color: "red" }}>
            Value must be greater than or equal to 0.0001
          </span>
        ) : null}
      </div>

      <button className={styles.remove} type="button" onClick={removeCharge}>
        Remove Charge
      </button>
    </div>
  );
};

const ExtraCharges = ({ extraCharges, chargeTypes, editCharges }) => {
  const [charges, setCharges] = useState([]);
  const [dropDwns, setDrpdwns] = useState([]);

  const go = () => {
    editCharges(charges.map(c => ({...c,amount:+c.amount})));
  };

  const addCharge = () => {
    setDrpdwns((list) => [...list, true]);
  };

  const editChargeList = (list) => {
    setCharges(list);
  };

  const removeCharge = (index) => {
    setDrpdwns((list) => list.filter((_, i) => i !== index));

    const list = [...extraCharges].filter((_, i) => i !== index);
    //editCharges( list );
    editChargeList(list);
    editCharges(list);
  };

  const editList = (charge, index) => {
    const list = [...charges];
    list[index] = charge;
    setDrpdwns(list);
    editChargeList(list);
  };

  return (
    <div className={styles.chargeRow}>
      {dropDwns.map((d, i) => (
        <ExtraChargeItem
          key={i}
          chargeTypesList={chargeTypes}
          removeCharge={() => removeCharge(i)}
          editExtra={editList}
          index={i}
        />
      ))}
      <button type="button" onClick={addCharge}>
        Add extra charge
      </button>
      <br /> <br /> <br />
      {charges.length ? (
        <button type="button" onClick={go}>
          Calculate charges
        </button>
      ) : null}
    </div>
  );
};

export { ExtraCharges };
