import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  Header,
  Table,
  HeaderSearch,
  EmptyState,
  FetchingState,
} from "../../../components";
import { transactionActions } from "../../../redux/actions";
import { formatCurrency, formatDate } from "../../../utils";

import styles from "../styles.module.scss";

const columns = [
  {
    Header: "Operation ID",
    accessor: "operationId",
  },
  {
    Header: "ID",
    accessor: "id",
  },
  {
    Header: "Type",
    accessor: "transactionTypeId",
  },
  {
    Header: "Channel",
    accessor: "channelId",
  },

  {
    Header: "Source",
    accessor: "sourceId",
  },
  {
    Header: "Date ordered",
    accessor: "createdAt",
    Cell: ({ cell }) => {
      return formatDate(cell?.value);
    },
  },
  {
    Header: "Amount",
    accessor: "amount",
    Cell: ({ cell }) => {
      return formatCurrency(cell?.value);
    },
  },
  {
    Header: "Balance",
    accessor: "customerBalance",
    Cell: ({ cell }) => {
      return formatCurrency(cell?.value);
    },
  },
  {
    Header: "Credit limit",
    accessor: "customer.creditLimit",
    Cell: ({ cell }) => {
      return formatCurrency(cell.value) || "-";
    },
  },
];

const Transactions = () => {
  const { staffTransactions: transactions, loading } = useSelector(
    (state) => state.transactions
  );
  const dispatch = useDispatch();

  const { profile } = useSelector((state) => state.user);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    if (profile.id) {
      const d = new Date();

      dispatch(
        transactionActions.fetchAllTransactionsByStaff({
          dateFrom: `${d.getFullYear()}/${1 + d.getMonth()}/01`,
          dateTo: `${d.getFullYear()}/${1 + d.getMonth()}/${d.getDate()}`,
          channelId: "POS",
          staffId: profile.id,
        })
      );
    }
  }, [dispatch, profile]);

  useEffect(() => {
    if (!startDate || !endDate || !profile.id) return;
    dispatch(
      transactionActions.fetchAllTransactionsByStaff({
        dateFrom: formatDate(startDate, "yyyy/LL/dd"),
        dateTo: formatDate(endDate, "yyyy/LL/dd"),
        channelId: "POS",
        staffId: profile.id,
      })
    );
  }, [startDate, endDate, dispatch, profile]);

  return (
    <div>
      <Header title="Your transactions">
        <HeaderSearch
          useSearch={false}
          onStartDateChange={(date) => setStartDate(date)}
          onEndDateChange={(date) => setEndDate(date)}
        />
      </Header>

      {loading ? (
        <FetchingState />
      ) : transactions.length === 0 ? (
        <EmptyState text="No recent transactions." />
      ) : (
        <Table
          data={transactions}
          paginate
          bordered
          wrapperClass={styles.container}
          columns={columns}
        />
      )}
    </div>
  );
};

export default Transactions;
