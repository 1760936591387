import Axios from "axios";
import { hubsTypes } from "../actionTypes";

const baseUrl = "https://abcapi.fusionintel.io/api/v1/";

const fetchAllHubs = () => async (dispatch) => {
  try {
    const token = localStorage.getItem("pos-token");
    const response = await Axios.get(
      `${baseUrl}Hubs/ViewAllHubs`,

      { headers: { Authorization: `Bearer ${token}` } }
    );

    dispatch({
      type: hubsTypes.FETCH_HUBS,
      payload: response.data.filter((p) => p.isActive),
    });
  } catch (error) {
    console.log(error);
  }
};

export const hubsActions = {
  fetchAllHubs,
};
