import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import Section from "./Section";
//import { Field } from "formik";
import { ordersActions } from "../../../../redux/actions";
import {
  addUpCharges,
  addUpInsurance,
  formatCurrency,
} from "../../../../utils";

import ChargeTypes from "./ChargeTypes";

import styles from "./styles.module.scss";
import DistanceMatrixCal from "./DistanceMatrixCal";

const Others = ({
  totalCharges,
  setTotalCharges,
  setProceed,
  mapsLoaded,
  extraCharges,
  selectedCharges,
  handleSelectedCharges,
  discountInfo,
}) => {
  const taxId = localStorage.getItem("pos-vat") || "VAT";

  //const [ chargeId, setChargeId ] = useState( null );
  const [packagingCostId /*setpackagingCostId*/] = useState(null);
  const [cT /*setCT*/] = useState("");
  const [charges /*setCharges*/] = useState({
    loading: true,
    status: "idle",
    error: "",
  });

  const [totalKM, setTotalKM] = useState(0);
  const {
    chargeTypes,
    // packagingCosts,
    loadingOrderPrice,
    newOrder,
  } = useSelector((state) => state.orders);

  // let [ VAT, setVAT ] = useState( "V.A.T" );
  // let [ insurance, setInsur ] = useState( "Insurance2" );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(ordersActions.fetchChargeTypes());
    dispatch(ordersActions.fetchPackagingCosts());
    //dispatch( ordersActions.fetchInsurances() );
  }, [dispatch]);

  const refreshCharges = useCallback(
    () => {
      setProceed(false);
      if ((selectedCharges.length === 0, !packagingCostId)) return;
      const onSuccess = (value) => {
        setTotalCharges(
          value.taxValue + addUpInsurance(newOrder) + addUpCharges(extraCharges)
        );

        dispatch(ordersActions.updateOrderValues("taxId", value.taxValue));
      };

      const onFail = () => {
        console.log("failed");
      };

      const calculateAmount = () => {
        let totalItemsAmount = 0;
        if (newOrder.items.length === 0) return 0;

        newOrder.items.map((item) => (totalItemsAmount += item.total));

        return totalItemsAmount;
      };

      const calculateDec = () => {
        let totalItemsAmount = 0;
        if (newOrder.items.length === 0) return 0;

        newOrder.items.map((item) => (totalItemsAmount += item.declared));

        return totalItemsAmount;
      };

      const body = {
        taxId,
        insuranceId: newOrder.insuranceTypeId
          ? newOrder.insuranceTypeId
          : "Insurance_2",
        chargeIds: selectedCharges,
        packagingCostId: packagingCostId || "ABC_test2",
        deliveryTypeId: newOrder.deliveryTypeId,
        totalKilometers:
          newOrder.deliveryTypeId === "HH" ? undefined : +totalKM,
        freightPricingId: newOrder.freightPricingId,
        total: calculateAmount(),
        declared: calculateDec(),
        extraCharges,
      };

      dispatch(ordersActions.updateOrderValues("chargeIds", selectedCharges));

      dispatch(ordersActions.calculateOrdersPrice(body, onSuccess, onFail));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      selectedCharges,
      packagingCostId,
      extraCharges,
      newOrder.deliveryTypeId,
      newOrder.serviceTypeId,
      newOrder.destinationHubId,
      newOrder.receiverAddress,
    ]
  );

  useEffect(() => {
    refreshCharges();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCharges, packagingCostId]);

  const insuranceTotal = addUpInsurance(newOrder);

  return (
    <>
      <Section title="Other details">
        <div className={styles.flex}>
          <div className={styles.fieldGrp}>
            <label htmlFor={`tax.tax`}>Tax</label>
            <input
              style={{ fontWeight: +newOrder.taxId >= 0 ? "bold" : "" }}
              type="text"
              disabled
              value={newOrder.taxId}
            />
          </div>

          <div className={styles.fieldGrp}>
            <label htmlFor={`insuranceId`}>Insurances</label>
            <input
              style={{ fontWeight: insuranceTotal >= 0 ? "bold" : "" }}
              type="text"
              disabled
              value={insuranceTotal}
            />
          </div>

          {/* 
          <div className={ styles.fieldGrp }>
            <label htmlFor={ `packaging.packaging` }>Packaging Cost</label>
            <Field
              as="select"
              id={ `packaging.packaging` }
              name={ `packaging.packaging` }
              value={ newOrder.packagingCostId }
              onChange={ ( e ) => handleChange( "packagingCostId", e.target.value ) }
            >
              <option value="">Select cost</option>
              { packagingCosts.map( ( d ) => (
                <option key={ d.name } value={ d.id }>
                  { d.name }
                </option>
              ) ) }
            </Field>
          </div> */}

          {newOrder.deliveryTypeId === "HD" && (
            <div className={styles.fieldGrp}>
              <label htmlFor={``}>Total Kilometers</label>
              <input type="text" disabled value={totalKM} />
            </div>
          )}

          {loadingOrderPrice ? <p>Calculating...</p> : null}

          {/* 
          <button
            type="button"
            style={ { alignSelf: "center" } }
            onClick={ refreshCharges }>
            Refresh charges (vat,insurance)
          </button> */}
        </div>

        <ChargeTypes
          chargeTypes={chargeTypes}
          handleChargeIds={handleSelectedCharges}
          chargeIdList={selectedCharges}
          cT={cT}
          charges={charges}
        />

        <p className={styles.total}>
          Total charge: {formatCurrency(totalCharges)} (This is not total
          amount)
        </p>
      </Section>

      {mapsLoaded &&
        newOrder.deliveryTypeId === "HD" &&
        newOrder.destinationHubAddress &&
        newOrder.receiverAddress && (
          <DistanceMatrixCal
            destinationHubAddress={newOrder.destinationHubAddress}
            receiverAddress={newOrder.receiverAddress}
            setTotalKM={setTotalKM}
          />
        )}
    </>
  );
};

export { Others };
