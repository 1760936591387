import Axios from "axios";
import React, { useEffect, useState } from "react";
import { Dialog } from "evergreen-ui";
import { BiArrowBack } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { FetchingState } from "../../../components";
import { ordersActions } from "../../../redux/actions";
import { formatCurrency, formatDate } from "../../../utils";
import styles from "./Orders.module.scss";
import CollectionItemSteps from "./CollectionItemSteps";
import UpdateOrder from "./UpdateOrder";
import UpdateItemWeight from "./UpdateItemWeight";
import SecondaryReceiverModal from "./SecondaryReceiverModal";
import UpdateDescription from "./UpdateDescription";

const formatString = (value = "") => {
  let val = value?.replace(/\s/gi, "");
  if (val?.length) {
    return value?.trim();
  }

  return "N/A";
};

const labels = [
  {
    text: "Date Ordered",
    key: "dateCreated",
    render: (data) => formatDate(data),
  },
  {
    text: "Customer First Name",
    key: "customerFirstName",
    render: (data) => data,
  },
  {
    text: "Customer Last Name",
    key: "customerLastName",
    render: (data) => data,
  },
  {
    text: "Customer Phone Number",
    key: "customerPhoneNumber",
    render: (data) => data,
  },
  {
    text: "Customer Business Name",
    key: "customerBusinessName",
    render: formatString,
  },
  {
    text: "Customer Type",
    key: "customerTypeId",
    render: formatString,
  },
  {
    text: "Customer Group",
    key: "customerGroupId",
    render: formatString,
  },
  {
    text: "Waybill ID",
    key: "orderId",
    render: (data) => data,
  },

  {
    text: "Insurance Value",
    key: "insurancePrice",
    render: (data) => formatCurrency(data),
  },
  {
    text: "VAT",
    key: "vatPrice",
    render: (data) => formatCurrency(data),
  },
  {
    key: "freightPricing",
    text: "Freight Pricing",
    render: (data) => formatCurrency(data),
  },

  {
    text: "Receiver's First Name",
    key: "receiverFirstName",
    render: formatString,
  },
  {
    text: "Receiver's Last Name",
    key: "receiverLastName",
    render: formatString,
  },
  {
    text: "Receiver's Phone Number",
    key: "receiverPhoneNumber",
    render: (data) => data,
  },

  {
    text: "Total amount",
    key: "totalAmount",
    render: (data) => formatCurrency(data),
  },

  {
    text: "Pickup Location",
    key: "pickupLocation",
    render: (data) => data,
  },
  {
    text: "Waybill Status",
    key: "orderStatus",
    render: (data) => (
      <span style={{ textTransform: "capitalize" }}>{data?.toLowerCase()}</span>
    ),
  },
  {
    text: "Waybill source",
    key: "source",
    render: (data) => data,
  },
  {
    text: "Waybill Contracted By",
    key: "staffName",
    render: (data) => data,
  },
];

const formatStatus = (list, userRole) => {
  let statuses = list.filter(
    (l) =>
      !(
        l.id.toLowerCase().includes("transit") ||
        l.id.toLowerCase().includes("booked") ||
        l.id.toLowerCase().includes("picked") ||
        l.id.toLowerCase().includes("confirmed")
      )
  );

  if (userRole.toLowerCase() !== "sys admin") {
    statuses = statuses.filter(
      (l) =>
        !(
          l.id.toLowerCase().includes("cancelled") ||
          l.id.toLowerCase().includes("rideraccepted")
        )
    );
  }

  return statuses;
};

const OrderDetails = () => {
  const history = useHistory();
  const id = window.location.pathname.split("/")[3];
  const { selectedOrder, loading, orderStatuses, error } = useSelector(
    (state) => state.orders
  );

  const roleName = useSelector(
    (state) => state?.user?.profile?.aspNetUser?.roleName
  );
  const dispatch = useDispatch();
  const [fail, setFail] = useState("");

  const [showModal, setShowModal] = useState(false);
  const [showItemModal, setShowItemModal] = useState(false);
  const [showDescModal, setShowDescModal] = useState(false);

  const [showSuccess, setShowSuccess] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [showRecModal, setShowRecModal] = useState(false);

  const [statusId, setStatusId] = useState(null);

  const [colInfo, setColInfo] = useState({
    data: null,
    error: null,
    status: "idle",
  });

  useEffect(() => {
    if (selectedOrder?.isACollection) {
      (async () => {
        setColInfo({
          data: null,
          error: null,
          status: "pending",
        });

        const token = localStorage.getItem("pos-token");

        try {
          const resp = await Axios.get(
            `https://api.abccargoxpress.com/api/v1/Collections/GetByOrderId?orderId=${selectedOrder?.orderId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setColInfo({
            data: resp?.data?.orderId ? resp.data : null,
            error: null,
            status: "resolved",
          });
        } catch (error) {
          setColInfo({
            data: null,
            error: "Unable to get collection info",
            status: "rejected",
          });
        }
      })();
    }
  }, [selectedOrder?.orderId, selectedOrder?.isACollection]);

  const formatData = () => {
    return {
      ...selectedOrder,
      vatAmount: selectedOrder.vatPrice,
    };
  };

  const handleUpdateStatus = () => {
    if (selectedOrder?.orderId) {
      setUpdating(true);
      setFail("");
      const body = {
        orderId: selectedOrder.orderId,
        statusId,
        receiverPhoneNumber: selectedOrder.receiverPhoneNumber,
        customerPhoneNumber: selectedOrder.customerPhoneNumber,
        totalAmount: selectedOrder.totalAmount,
      };

      const onSuccess = () => {
        setShowModal(false);
        setShowSuccess(true);
        setUpdating(false);
      };
      const onFail = (msg) => {
        setFail(msg);
        setUpdating(false);
      };

      dispatch(ordersActions.setOrderStatus(body, onSuccess, onFail));
    } else {
      setFail("Unable to update order status");
    }
  };

  const onChangeStatus = (statusId) => () => {
    setStatusId(statusId);
  };

  const handleCloseUpdate = () => {
    setShowModal(false);
    setStatusId("");
    setUpdating(false);
    setFail("");
  };

  useEffect(() => {
    dispatch(ordersActions.fetchOrderByCode(id));
    dispatch(ordersActions.fetchOrderStatuses());
  }, [dispatch, id]);

  const filteredOrderStatuses = formatStatus(orderStatuses, roleName);
  if (loading) {
    return (
      <div>
        <FetchingState />
      </div>
    );
  }
  if (!selectedOrder?.orderId) {
    return (
      <div>
        <div className={styles.Header}>
          <div className={styles.Header__top}>
            <BiArrowBack
              size={32}
              color="#626266"
              onClick={() => history.push("/dashboard/orders")}
            />
            <h3 className={styles.title}>Waybill Details</h3>
          </div>
        </div>
        <div className={styles.noBill}>
          <h1>Waybill not found</h1>
          <p>{error}</p>
        </div>
      </div>
    );
  }
  return (
    <div>
      <div className={styles.Header}>
        <div className={styles.Header__top}>
          <BiArrowBack
            size={32}
            color="#626266"
            onClick={() => history.push("/dashboard/orders")}
          />
          <h3 className={styles.title}>Waybill Details</h3>
        </div>
        <Link to={`/dashboard/receipt/${selectedOrder.orderId}`}>
          Print Waybill
        </Link>
      </div>
      {loading ? (
        <FetchingState />
      ) : selectedOrder ? (
        <>
          <div className={styles.grid}>
            {labels?.map((l) => (
              <div className={styles.group} key={l.text}>
                <p className={styles.label}>{l.text}</p>
                <div>{l.render(formatData()[l.key])}</div>
              </div>
            ))}

            <div>
              <button
                className={styles.recBtn}
                onClick={() => setShowRecModal(true)}
                type="button"
              >
                Update receiver
              </button>
            </div>
          </div>

          <div className={styles.section}>
            <h2>
              Cash on collection ?{" "}
              {selectedOrder?.isACollection ? (
                <strong>Yes</strong>
              ) : (
                <strong>No</strong>
              )}
            </h2>
          </div>

          {selectedOrder?.extraCharges?.length ? (
            <div className={styles.section}>
              <p className={styles.label}>Additional Charges</p>

              {selectedOrder?.extraCharges?.map((item, i) => (
                <div className={styles.group} key={i}>
                  <p>
                    {" "}
                    <strong>{item.chargeTypeId}</strong> :{" "}
                    <span>{formatCurrency(item.amount)}</span>{" "}
                  </p>
                </div>
              ))}
            </div>
          ) : null}
          <div className={styles.itemContainer}>
            <h3>Items</h3>
            {selectedOrder.itemsList?.map((item, i) => (
              <div key={i} className={styles.itemContainer__content}>
                <h4>{i + 1}</h4>
                <div>
                  <div className={styles.itemContainer__content__item}>
                    <h5>Description:</h5>
                    <p>
                      {item.iTemDescription}
                      <button
                        onClick={() => setShowDescModal(true)}
                        type="button"
                        className={styles.updateBtn}
                      >
                        Update Description
                      </button>{" "}
                    </p>
                  </div>
                  <div className={styles.itemContainer__content__item}>
                    <h5>Item Type Id:</h5>
                    <p>{item.itemTypeId}</p>
                  </div>

                  <div className={styles.itemContainer__content__item}>
                    <h5>Item Type Name:</h5>
                    <p>{item.itemTypeName}</p>
                  </div>

                  <div className={styles.itemContainer__content__item}>
                    <h5>Quantity:</h5>
                    <p>{item.quantity}</p>
                  </div>
                  <div className={styles.itemContainer__content__item}>
                    <h5>Weight:</h5>
                    <p>
                      {item.weight}{" "}
                      <button
                        onClick={() => setShowItemModal(true)}
                        type="button"
                        className={styles.updateBtn}
                      >
                        Update weight
                      </button>{" "}
                    </p>
                  </div>
                  <div className={styles.itemContainer__content__item}>
                    <h5>Declared Value:</h5>
                    <p>{formatCurrency(item.declared)}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className={styles.section}>
            <p className={styles.label}>Summary</p>
            <p>{selectedOrder.summary}</p>
          </div>
          <div className={styles.btn}>
            <button onClick={() => setShowModal(!showModal)}>
              Update Order Status
            </button>
          </div>

          {showRecModal ? (
            <SecondaryReceiverModal
              show={showRecModal}
              selectedOrder={selectedOrder}
              close={() => setShowRecModal(false)}
            />
          ) : null}

          {showItemModal ? (
            <UpdateItemWeight
              items={selectedOrder?.itemsList}
              show={showItemModal}
              onClose={() => setShowItemModal(false)}
              channel={selectedOrder?.channel}
              oldPrice={selectedOrder?.totalAmount}
            />
          ) : null}

          {showDescModal ? (
            <UpdateDescription
              show={showDescModal}
              items={selectedOrder?.itemsList}
              onClose={() => setShowDescModal(false)}
            />
          ) : null}

          <Dialog
            isShown={showModal}
            title={
              statusId === "delivered" &&
              selectedOrder?.isACollection &&
              !selectedOrder?.isCollectionSettled
                ? "Update Collection"
                : "Select Order Status"
            }
            onCloseComplete={handleCloseUpdate}
            hasFooter={false}
          >
            <div>
              {/* {statusId === "delivered" && !selectedOrder?.isACollection ? (
                <UpdateOrder
                  order={selectedOrder}
                  fail={fail}
                  handleUpdateStatus={handleUpdateStatus}
                  updating={updating}
                />
              ) : null} */}

              {statusId === "delivered" &&
              (!selectedOrder?.isACollection ||
                selectedOrder?.isCollectionSettled) ? (
                <UpdateOrder
                  order={selectedOrder}
                  fail={fail}
                  handleUpdateStatus={handleUpdateStatus}
                  updating={updating}
                />
              ) : null}

              {statusId === "delivered" &&
              selectedOrder?.isACollection &&
              !selectedOrder?.isCollectionSettled ? (
                <CollectionItemSteps
                  collectionInfo={colInfo?.data}
                  order={selectedOrder}
                  fail={fail}
                  handleUpdateStatus={handleUpdateStatus}
                  updating={updating}
                />
              ) : null}

              {statusId !== "delivered" ? (
                <>
                  {filteredOrderStatuses.map((item, i) => (
                    <label
                      key={i}
                      className={styles.radioGroup}
                      htmlFor={item.id}
                    >
                      <input
                        type="radio"
                        name="status"
                        id={item.id}
                        onChange={onChangeStatus(item.id)}
                      />
                      <p>{item.name}</p>
                    </label>
                  ))}

                  <p>{fail ? fail : null}</p>

                  <div className={styles.btn}>
                    <button
                      onClick={handleUpdateStatus}
                      disabled={statusId === null || updating}
                    >
                      {!updating ? "Update Status" : "Please Wait..."}
                    </button>
                  </div>
                </>
              ) : null}
            </div>
          </Dialog>

          <Dialog
            isShown={showSuccess}
            title="Successful"
            onCloseComplete={() => history.push("/dashboard/orders")}
            hasFooter={false}
          >
            <p>Order Status Updated Successfully.</p>
          </Dialog>
        </>
      ) : (
        <p>Error fetching data</p>
      )}
    </div>
  );
};

export default OrderDetails;
