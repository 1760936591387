import { smsTypes } from "../actionTypes";

const setMsg = ( msg ) => ( dispatch ) =>
{
    dispatch( {
        type: smsTypes.SET_MSG,
        payload: msg
    } );
};


const toggle = ( state ) => ( dispatch ) =>
{

    dispatch( {
        type: smsTypes.TOGGLE,
        payload: state
    } );
};


export const smsActions =
{
    setMsg,
    toggle
};
