import React, { useState, useEffect, useCallback, memo } from "react";
import { Field, ErrorMessage, isNaN } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { ordersActions } from "../../redux/actions";
import { addUpCharges, addUpInsurance, formatCurrency } from "../../utils";
import styles from "./ItemRow.module.scss";
import { Searchable } from "../SearchableDropDown/SearchableDropDown";

const itemConditions = ["Good", "Damaged", "Partially damaged"];

const eventTypes = {
  desc: "desc",
  qty: "qty",
  declared: "declared",
  shipmentTypeId: "shipmentTypeId",
  itemCondition: "itemCondition",
  itemType: "itemType",
  weight: "weight",
};

const minimum = 1;
const maximum = Number.MAX_SAFE_INTEGER;
const clamp = (num, min = minimum, max = maximum) => {
  if (isNaN(Number(num))) {
    return min;
  }

  return Math.min(Math.max(num, min), max);
};

const ItemRow = ({
  item,
  formikProps,
  shipmentTypes,
  index,
  removeOrderItem,
  updateOrderItemValues,
  setShowErr,
  setModalErrMsg,
  setProceed,
  setTotalCharges,
  extraCharges,
  selectedCharges,
}) => {
  const dispatch = useDispatch();

  const [description, setDescription] = useState(null);
  const [shipmentTypeId, setShipmentTypeId] = useState(null);
  const [condition, setCondition] = useState(null);
  const [itemType, setItemType] = useState(null);
  const [weight, setWeight] = useState(null);
  const [declared, setDeclared] = useState(null);
  const [quantity, setQuantity] = useState(null);
  const [warning, setWarning] = useState(true);
  const [total, setTotal] = useState(0);
  const [pricingError, setPricingError] = useState("");
  const [loading, setLoading] = useState(false);

  const [enableRisk, setEnableRisk] = useState(false);

  const { /*itemStatus,*/ itemTypes, newOrder } = useSelector(
    (state) => state.orders
  );

  const list = itemTypes.map((it) => ({ ...it, description: it.id }));
  const taxId = localStorage.getItem("pos-vat") || "VAT";
  const { profile } = useSelector((state) => state.user);

  const updateAllow = () => {
    const riskEnabled = !enableRisk;
    setEnableRisk(riskEnabled);

    const normDeclared = clamp(+declared);

    if (
      !description ||
      !shipmentTypeId ||
      !condition ||
      !itemType ||
      !+weight ||
      normDeclared < 1 ||
      !+quantity ||
      !newOrder.deliveryTypeId
    ) {
      return;
    }

    const calculateAmount = () => {
      let totalItemsAmount = 0;
      if (newOrder.items.length === 0) return 0;

      totalItemsAmount = newOrder.items.reduce((sum, item, i) => {
        sum = sum + Number(item.total);

        return sum;
      }, totalItemsAmount);

      return totalItemsAmount;
    };

    // const calculateDec = () => {
    //   let totalItemsDeclaredAmt = 0;
    //   if (newOrder.items.length === 0) return 0;

    //   totalItemsDeclaredAmt = newOrder.items.reduce((sum, item, i) => {
    //     sum = sum + clamp(Number(item.declared));

    //     return sum;
    //   }, totalItemsDeclaredAmt);

    //   return totalItemsDeclaredAmt;
    // };

    const orderBody = {
      ...newOrder,
      taxId,
      insuranceId: `Insurance_${riskEnabled ? 3 : 2}`,
      chargeIds: selectedCharges,
      packagingCostId: newOrder.packagingCostId || "ABC_test2",
      deliveryTypeId: newOrder.deliveryTypeId,
      totalKilometers:
        newOrder.deliveryTypeId === "HH"
          ? undefined
          : +localStorage.getItem("totalKilometers"),
      total: calculateAmount(),
      declared: normDeclared,
      extraCharges,
    };

    dispatch(
      ordersActions.calculateOrdersPrice(
        orderBody,
        (value) => {
          dispatch(ordersActions.updateOrderValues("taxId", value.taxValue));

          const subtract = newOrder.insuranceList[item.itemId] ?? 0;

          setTotalCharges(
            value.taxValue +
              value.insuranceValue -
              subtract +
              addUpInsurance(newOrder) +
              addUpCharges(extraCharges)
          );

          dispatch(
            ordersActions.setItemInsurance({
              itemId: item.itemId,
              insuranceValue: value.insuranceValue,
              insuranceId: `Insurance_${riskEnabled ? 3 : 2}`,
            })
          );

          // dispatch(
          //   ordersActions.updateOrderValues(
          //     "insuranceId",
          //     value.insuranceValue
          //   )
          // );
        },
        () => {}
      )
    );
  };

  const onSuccess = useCallback(
    (data) => {
      setTotal(data.value);
      setLoading(false);

      const {
        description,
        shipmentTypeId,
        condition,
        itemType,
        weight,
        declared,
        quantity,
      } = data;

      updateOrderItemValues(item.itemId, {
        itemId: item.itemId,
        itemName: "Default_name",
        itemDescription: description,
        shipmentTypeId,
        condition,
        itemTypeId: itemType,
        statusId: "delivered",
        quantity: +quantity,
        declared: clamp(+declared),
        weight: +weight,
        total: data.value,
        freightPricingId: data.freightPriceId,
        orderId: "orderone",
        packagingCostId: "ABC_test2",
      });

      const items = [...newOrder.items];

      items[index] = {
        declared: clamp(+declared),
        total: +data.value,
        itemDescription: description,
      };

      const calculateAmount = () => {
        let totalItemsAmount = 0;
        if (items.length === 0) return 0;

        totalItemsAmount = items.reduce((sum, item, i) => {
          sum = sum + Number(item.total);

          return sum;
        }, totalItemsAmount);

        return totalItemsAmount;
      };

      const orderBody = {
        taxId,
        insuranceId: `Insurance_${enableRisk ? 3 : 2}`,
        chargeIds: selectedCharges,
        packagingCostId: newOrder.packagingCostId || "ABC_test2",
        deliveryTypeId: newOrder.deliveryTypeId,
        totalKilometers:
          newOrder.deliveryTypeId === "HH"
            ? undefined
            : +localStorage.getItem("totalKilometers"),
        freightPricingId: data.freightPriceId,
        total: calculateAmount(),
        // declared: calculateDec(),
        declared,
        extraCharges,
      };

      dispatch(
        ordersActions.calculateOrdersPrice(
          orderBody,
          (value) => {
            dispatch(ordersActions.updateOrderValues("taxId", value.taxValue));

            const subtract = newOrder.insuranceList[item.itemId] ?? 0;

            setTotalCharges(
              value.taxValue +
                value.insuranceValue -
                subtract +
                addUpInsurance(newOrder) +
                addUpCharges(extraCharges)
            );

            dispatch(
              ordersActions.setItemInsurance({
                itemId: item.itemId,
                insuranceValue: value.insuranceValue,
                insuranceId: `Insurance_${enableRisk ? 3 : 2}`,
              })
            );

            // dispatch(
            //   ordersActions.updateOrderValues(
            //     "insuranceId",
            //     value.insuranceValue
            //   )
            // );
          },
          () => {}
        )
      );
    },
    [
      enableRisk,
      updateOrderItemValues,
      newOrder,
      selectedCharges,
      index,
      dispatch,
      extraCharges,
      item.itemId,
      setTotalCharges,
    ]
  );

  const getItemPrice = (state) => {
    const {
      description,
      shipmentTypeId,
      condition,
      itemType,
      weight,
      declared,
      quantity,
    } = state;

    const normDeclared = clamp(+declared);

    if (
      !description ||
      !shipmentTypeId ||
      !condition ||
      !itemType ||
      !+weight ||
      normDeclared < 1 ||
      !+quantity ||
      !newOrder.deliveryTypeId
    ) {
      return;
    } else {
      setProceed(false);
      setPricingError("");
      setWarning(false);
      setLoading(true);
      const body = {
        customerTypeId: newOrder.customerTypeId,
        deliveryTypeId: newOrder.deliveryTypeId,
        itemTypeId: itemType,
        departureHubId: profile.hubId,
        destinationHubId: newOrder.destinationHubId,
        serviceTypeId: newOrder.serviceTypeId,
        shipmentTypeId,
        quantity: +quantity,
        declared: clamp(+declared),
        weight: +weight,
        packagingCostId: "ABC_test2",
        totalKilometers:
          newOrder.deliveryTypeId === "HH"
            ? undefined
            : +localStorage.getItem("totalKilometers"),
      };

      dispatch(
        ordersActions.calculateItemPrice(
          body,
          (data) =>
            onSuccess({
              ...state,
              ...data,
              declared: clamp(+state.declared),
              quantity: +state.quantity,
              weight: +state.weight,
            }),
          onFail
        )
      );
    }
  };

  const onFail = useCallback(() => {
    setPricingError("This pricing does not exist");
    setLoading(false);

    dispatch(ordersActions.updateOrderValues("taxId", taxId));
    dispatch(ordersActions.updateOrderValues("insuranceId", "Insurance_2"));
  }, [dispatch]);

  const handleChange = (type) => (e) => {
    const val = e.target.value;
    const body = {
      description,
      shipmentTypeId,
      condition,
      itemType,
      weight,
      declared,
      quantity,
    };

    switch (type) {
      case eventTypes.desc:
        body.description = val;
        setDescription(val);

        break;

      case eventTypes.qty:
        body.quantity = +val;
        setQuantity(val);
        break;

      case eventTypes.declared:
        body.declared = val;
        setDeclared(val);
        break;

      case eventTypes.shipmentTypeId:
        body.shipmentTypeId = val;
        setShipmentTypeId(val);
        break;

      case eventTypes.itemCondition:
        body.condition = val;
        setCondition(val);
        break;

      case eventTypes.itemType:
        body.itemType = val;
        setItemType(val);
        break;

      case eventTypes.weight:
        body.weight = val;
        setWeight(val);
        break;

      default:
        break;
    }

    getItemPrice(body);
  };

  const handleRemove = () => {
    const items = newOrder.items.filter((x) => x.itemId !== item.itemId);

    const calculateAmount = () => {
      let totalItemsAmount = 0;
      if (items.length === 0) return 0;

      totalItemsAmount = items.reduce((sum, item, i) => {
        sum = sum + Number(item.total);

        return sum;
      }, totalItemsAmount);

      return totalItemsAmount;
    };

    const orderBody = {
      ...{
        ...newOrder,
        items,
      },
      taxId,
      insuranceId: `Insurance_${enableRisk ? 3 : 2}`,
      chargeIds: selectedCharges,
      packagingCostId: newOrder.packagingCostId || "ABC_test2",
      deliveryTypeId: newOrder.deliveryTypeId,
      totalKilometers:
        newOrder.deliveryTypeId === "HH"
          ? undefined
          : +localStorage.getItem("totalKilometers"),
      total: calculateAmount(),

      //just send 0, the important thing is to get tax value
      declared: 0,
      extraCharges,
    };

    dispatch(
      ordersActions.setItemInsurance({
        itemId: item.itemId,
        insuranceValue: 0,
        insuranceId: null,
      })
    );

    removeOrderItem(item.itemId);

    dispatch(
      ordersActions.calculateOrdersPrice(
        orderBody,
        (value) => {
          dispatch(ordersActions.updateOrderValues("taxId", value.taxValue));

          setTotalCharges(
            value.taxValue +
              addUpInsurance({
                ...newOrder,
                items,
              }) +
              addUpCharges(extraCharges)
          );
        },
        () => {}
      )
    );
  };

  useEffect(() => {
    const body = {
      description,
      shipmentTypeId,
      condition,
      itemType,
      weight,
      declared,
      quantity,
    };

    getItemPrice(body);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    newOrder.customerId,
    newOrder.destinationHubId,
    newOrder.serviceTypeId,
    newOrder.totalKilometers,
    newOrder.deliveryTypeId,
    extraCharges,
  ]);

  return (
    <div style={{ marginBottom: "20px" }}>
      {warning && <p style={{ color: "red" }}>All fields are required</p>}
      {pricingError && <p style={{ color: "red" }}>{pricingError}</p>}

      <br />
      <label
        className={{
          cursor: "pointer",
        }}
      >
        <input onChange={updateAllow} type="checkbox" checked={enableRisk} />
        &nbsp;
        <span style={{ color: "red" }}>
          <strong>NOT INSURED</strong>
        </span>
      </label>

      <p>
        <small>
          *Minimum declared value defaults to 1, when field is 0 or empty
        </small>
      </p>

      <br />
      <div className={styles.flex}>
        <div className={styles.fieldGrp}>
          <label htmlFor={`items.${index}.description`}>Description</label>
          <Field
            id={`items.${index}.description`}
            name={`items.${index}.description`}
            onChange={handleChange(eventTypes.desc)}
            value={description}
            style={{ width: "400px", marginRight: "20px" }}
          />
          <ErrorMessage component="small" name={`items.${index}.description`} />
        </div>

        <div className={styles.fieldGrp}>
          <label htmlFor={`items.${index}.shipmentTypeId`}>Shipment type</label>
          <Field
            as="select"
            id={`items.${index}.shipmentTypeId`}
            name={`items.${index}.shipmentTypeId`}
            value={shipmentTypeId}
            onChange={handleChange(eventTypes.shipmentTypeId)}
          >
            <option value="">Select shipment type</option>
            {shipmentTypes.map((d) => (
              <option key={d.id} value={d.id}>
                {d.name}
              </option>
            ))}
          </Field>
          <ErrorMessage
            component="small"
            name={`items.${index}.shipmentTypeId`}
          />
        </div>

        <div className={styles.fieldGrp}>
          <label htmlFor={`items.${index}.itemCondition`}>Condition</label>
          <Field
            as="select"
            id={`items.${index}.itemCondition`}
            name={`items.${index}.itemCondition`}
            onChange={handleChange(eventTypes.itemCondition)}
            value={condition}
          >
            <option value="">Select item condition</option>
            {itemConditions.map((d) => (
              <option key={d} value={d}>
                {d}
              </option>
            ))}
          </Field>
          {/* <ErrorMessage
            component="small"
            name={`items.${index}.itemCondition`}
          /> */}
        </div>

        <div className={styles.fieldGrp}>
          <label htmlFor={`items.${index}.itemType`}>Item Type</label>
          <Field
            // as="select"
            id={`items.${index}.itemType`}
            name={`items.${index}.itemType`}
            //  onChange={handleChange(eventTypes.itemType)}
            //  value={itemType}
          >
            {() => (
              <Searchable
                notFoundText="No result found"
                options={list}
                name={`items.${index}.itemType`}
                handleChange={(val) => {
                  handleChange(eventTypes.itemType)({
                    target: { value: val },
                  });
                }}
              />
            )}
            {/* <option value="">Select item type</option>
            {itemTypes.map((d) => (
              <option key={d.id} value={d.id}>
                {d.name}
              </option>
            ))} */}
          </Field>
          {/* <ErrorMessage component="small" name={`items.${index}.itemStatus`} /> */}
        </div>
      </div>

      <div className={styles.flex}>
        <div className={styles.fieldGrp}>
          <label htmlFor={`items.${index}.weight`}>Weight (KG)</label>
          <Field
            type="number"
            id={`items.${index}.weight`}
            name={`items.${index}.weight`}
            onChange={handleChange(eventTypes.weight)}
            value={weight}
          />
          <ErrorMessage component="small" name={`items.${index}.weight`} />
        </div>

        <div className={styles.fieldGrp}>
          <label htmlFor={`items.${index}.value`}>Declared Value</label>
          <Field
            type="number"
            id={`items.${index}.value`}
            name={`items.${index}.value`}
            onChange={handleChange(eventTypes.declared)}
            value={declared}
          />
          {/* <ErrorMessage component="small" name={`items.${index}.value`} /> */}
        </div>

        <div className={styles.fieldGrp}>
          <label htmlFor={`items.${index}.quantity`}>Quantity</label>
          <Field
            type="number"
            id={`items.${index}.quantity`}
            name={`items.${index}.quantity`}
            onChange={handleChange(eventTypes.qty)}
            value={quantity}
          />
          {/* <ErrorMessage component="small" name={`items.${index}.quantity`} /> */}
        </div>

        <button className={styles.remove} type="button" onClick={handleRemove}>
          X
        </button>
      </div>
      <p className={styles.total}>
        {loading ? (
          <> Calculating item price... </>
        ) : (
          <>
            Freight Price: {formatCurrency(total)} (This is not the total
            amount)
          </>
        )}
      </p>
    </div>
  );
};

export default memo(ItemRow);
