import React, { useCallback } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { ordersActions } from "../../../redux/actions";
import { Dialog } from "evergreen-ui";
import Axios from "axios";
import { formatCurrency } from "../../../utils";

import styles from "./Orders.module.scss";

const UpdateDescription = ({ items = [], show, onClose }) => {
  //normal spread did not affect the nested objects, so had to map
  const [modalItems, setModalItems] = useState(
    items.map((it) => ({ ...it, description: it.description || "" }))
  );
  const [status, setStatus] = useState("idle");
  const [error, setError] = useState(undefined);
  const dispatch = useDispatch();

  const changeItemDesc = async () => {
    setError(undefined);

    const items = modalItems.map((it) => ({
      itemId: it.itemId,
      description: it.description,
    }));

    const body = {
      items,
    };

    setStatus("pending");

    try {
      const token = localStorage.getItem("pos-token");
      const resp = await Axios.post(
        `https://api.abccargoxpress.com/api/v1/Orders/ChangeItemDescription`,
        body,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      dispatch(ordersActions.updateDesc(resp.data));
      setStatus("resolved");
    } catch (error) {
      const message =
        JSON.stringify(error?.response?.data) || "Unable to update description";
      setError(message);
      setStatus("rejected");
    }
  };

  return (
    <Dialog
      isShown={show}
      title={"Update Description"}
      onCloseComplete={onClose}
      hasFooter={false}
    >
      <div className={styles.moveUp}>
        <section>
          {modalItems.map((item, index) => (
            <div key={index} className={styles.itemContainer__content}>
              <h4>{index + 1}</h4>
              <div>
                <div className={styles.itemContainer__content__item}>
                  <h5>Description:</h5>
                  <p>{item.iTemDescription}</p>
                </div>

                <div className={styles.itemContainer__content__item}>
                  <h5>Item Type Id:</h5>
                  <p>{item.itemTypeId}</p>
                </div>

                <div className={styles.itemContainer__content__item}>
                  <h5>Item Type Name:</h5>
                  <p>{item.itemTypeName}</p>
                </div>

                <div className={styles.itemContainer__content__item}>
                  <h5>Update Description:</h5>
                  <input
                    type="text"
                    value={item.description}
                    className={styles.updateInp}
                    onChange={(e) => {
                      const val = e.target.value;
                      setModalItems((list) => {
                        const newList = [...list];
                        newList[index].description = val;
                        return newList;
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          ))}

          <div className={styles.btn}>
            <button disabled={status === "pending"} onClick={changeItemDesc}>
              Update Description
            </button>
          </div>
          <p className={styles.error}>
            <small>{error ? error : null}</small>
          </p>
        </section>
      </div>
    </Dialog>
  );
};

export default UpdateDescription;
