import React, { useEffect, memo } from "react";

const useDebouncedEffect = (effect, deps, delay) => {
  useEffect(() => {
    const handler = setTimeout(() => effect(), delay);

    return () => clearTimeout(handler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...(deps || []), delay]);
};

const DistanceMatrixCalculator = ({
  destinationHubAddress,
  receiverAddress,
  delay = 800,
  setTotalKM,
}) => {
  // useDebouncedEffect(
  //   () => {
  //     const service = new window.google.maps.DistanceMatrixService();

  //     service.getDistanceMatrix(
  //       {
  //         origins: [receiverAddress],
  //         destinations: [destinationHubAddress],
  //         travelMode: "DRIVING",
  //       },
  //       (response) => {
  //         const val = response?.rows[0]?.elements[0]?.distance?.value / 1000;

  //         //using dispatch caused infinite re-renders, so local storage was the way
  //         //it's being unset after order is successful
  //         localStorage.setItem("totalKilometers", val);

  //         setTotalKM(val);
  //       }
  //     );
  //   },
  //   [destinationHubAddress, receiverAddress, setTotalKM],
  //   delay
  // );


  useDebouncedEffect(() => 
  {
    setTotalKM(0);
  },[setTotalKM])

  return <></>;
};

export default memo(DistanceMatrixCalculator);
