import { smsTypes } from "../actionTypes";

const initState =
{
    show: false,
    msg: ""
};

export const sms = ( state = initState, action ) =>
{
    const { type, payload } = action;

    switch ( type ) 
    {
        case smsTypes.SET_MSG:
            return {
                msg: payload,
                show: true,
            };

        case smsTypes.TOGGLE:
            return {
                msg: "",
                show: payload,
            };


        default:
            return state;
    }
};
