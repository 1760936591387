import React, { useState } from "react";
import { Dialog } from "evergreen-ui";
import Axios from "axios";
import { Form, Formik } from "formik";
import { updateRecSchema } from "../MakeOrder/orderSchema";
import { useDispatch } from "react-redux";

import "react-phone-number-input/style.css";
import styles from "./CollectionSteps.module.scss";
import PhoneInput, { formatPhoneNumberIntl } from "react-phone-number-input";
import { ordersActions } from "../../../redux/actions";

const init = {
  orderId: "",
  secondaryReceiverPhoneNumber: "",
  //secondaryReceiverEmail: "",
  secondaryReceiverFirstName: "",
  secondaryReceiverLastName: "",
  //secondaryReceiverBusinessName: "",
};

const getInit = (order = {}) => {
  const body = {
    ...init,
    orderId: order?.orderId,
    secondaryReceiverPhoneNumber: order?.receiverPhoneNumber?.startsWith("+")
      ? order?.receiverPhoneNumber
      : formatPhoneNumberIntl(`+${order?.receiverPhoneNumber}`) ?? "",
    //secondaryReceiverEmail: order?.secondaryReceiverEmail ?? "",
    secondaryReceiverFirstName: order?.receiverFirstName ?? "",
    secondaryReceiverLastName: order?.receiverLastName ?? "",
    //secondaryReceiverBusinessName: order?.secondaryReceiverBusinessName ?? "",
  };

  return body;
};

const regex = /[+-.,]/gi;

const SecondaryReceiverModal = ({ show, selectedOrder, close }) => {
  const [loading, setLoading] = useState(false);

  const [err, setErr] = useState(null);

  const [done, setDone] = useState(false);

  const dispatch = useDispatch();

  const handleClose = () => {
    close();
    if (done) {
      dispatch(ordersActions.fetchOrderByCode(selectedOrder.orderId));
    }
  };

  const updateReceiver = async (values) => {
    try {
      setLoading(true);
      //console.log(values);
      setDone(false);
      const body = {
        ...values,
        secondaryReceiverPhoneNumber:
          values.secondaryReceiverPhoneNumber.replace(regex, ""),
      };

      const token = localStorage.getItem("pos-token");
      await Axios.patch(
        `https://api.abccargoxpress.com/api/v1/Orders/UpdateSecondaryDetails`,
        body,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setLoading(false);
      setDone(true);
    } catch (error) {
      setDone(false);
      setErr(error?.toString() ?? "Unable to update details");
    }
  };

  return (
    <Dialog
      onCloseComplete={handleClose}
      title="Update secondary recipient"
      isShown={show}
      hasFooter={false}
    >
      <Formik
        onSubmit={updateReceiver}
        validationSchema={updateRecSchema}
        initialValues={getInit(selectedOrder)}
      >
        {({
          values,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          touched,
          isValid,
          setFieldValue,
        }) => (
          <div className={styles.formWrapper2}>
            <Form className={styles.form}>
              <div className={styles.field}>
                <label htmlFor="orderId">Order ID:</label>
                <input
                  name="orderId"
                  onChange={handleChange}
                  disabled
                  value={values.orderId}
                />
                <small>
                  {errors.orderId && touched.orderId && errors.orderId}
                </small>
              </div>

              <div className={styles.field}>
                <label htmlFor="secondaryReceiverFirstName">
                  Receiver's first name
                </label>
                <input
                  id="secondaryReceiverFirstName"
                  name="secondaryReceiverFirstName"
                  onChange={handleChange}
                  value={values.secondaryReceiverFirstName}
                />
                <small>
                  {errors.secondaryReceiverFirstName &&
                    touched.secondaryReceiverFirstName &&
                    errors.secondaryReceiverFirstName}
                </small>
              </div>

              <div className={styles.field}>
                <label htmlFor="secondaryReceiverLastName">
                  Receiver's last name
                </label>
                <input
                  name="secondaryReceiverLastName"
                  onChange={handleChange}
                  id="secondaryReceiverLastName"
                  value={values.secondaryReceiverLastName}
                />
                <small>
                  {errors.secondaryReceiverLastName &&
                    touched.secondaryReceiverLastName &&
                    errors.secondaryReceiverLastName}
                </small>
              </div>

              {/* <div className={styles.field}>
                <label htmlFor="secondaryReceiverEmail">Receiver's email</label>
                <input
                  id="secondaryReceiverEmail"
                  name="secondaryReceiverEmail"
                  onChange={handleChange}
                  value={values.secondaryReceiverEmail}
                />
                <small>
                  {errors.secondaryReceiverEmail &&
                    touched.secondaryReceiverEmail &&
                    errors.secondaryReceiverEmail}
                </small>
              </div>

              <div className={styles.field}>
                <label htmlFor="secondaryReceiverBusinessName">
                  Receiver's business Name
                </label>
                <input
                  id="secondaryReceiverBusinessName"
                  name="secondaryReceiverBusinessName"
                  onChange={handleChange}
                  value={values.secondaryReceiverBusinessName}
                />
                <small>
                  {errors.secondaryReceiverBusinessName &&
                    touched.secondaryReceiverBusinessName &&
                    errors.secondaryReceiverBusinessName}
                </small>
              </div> */}

              <div className={styles.field}>
                <label htmlFor="secondaryReceiverPhoneNumber">
                  Receiver's phone number
                </label>

                <PhoneInput
                  defaultCountry="NG"
                  countryCallingCodeEditable={false}
                  international
                  onChange={(value) => {
                    setFieldValue("secondaryReceiverPhoneNumber", value);
                  }}
                  value={values.secondaryReceiverPhoneNumber}
                />
                {/* <input
                  id="secondaryReceiverPhoneNumber"
                  name="secondaryReceiverPhoneNumber"
                  onChange={handleChange}
                  value={values.secondaryReceiverPhoneNumber}
                /> */}

                <small>
                  {errors.secondaryReceiverPhoneNumber &&
                    touched.secondaryReceiverPhoneNumber &&
                    errors.secondaryReceiverPhoneNumber}
                </small>
              </div>

              <div className={styles.submit}>
                <button disabled={loading} className="" type="submit">
                  Updat{loading ? "ing" : "e"}
                </button>
              </div>

              {err ? (
                <>
                  <p className={styles.err}>{err}</p>
                </>
              ) : null}

              {done ? (
                <>
                  <p className={styles.done}>Details updated successfully</p>
                </>
              ) : null}
            </Form>

            <br />
            <br />
          </div>
        )}
      </Formik>
    </Dialog>
  );
};

export default SecondaryReceiverModal;
