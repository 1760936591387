import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { formatDate } from "../../../utils";

import { userActions, ordersActions } from "../../../redux/actions";

import
{
    Header,
    Table,
    EmptyState,
    FetchingState,
} from "../../../components";

import CreateIrregularity from "./CreateIrregularity";
import EditIrregularity from "./EditIrregularity";
import ResolveIrregularity from "./ResolveIrregularity";

import { FaClipboardCheck, FaEdit, FaCheck } from "react-icons/fa";

import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";

import styles from './Irregularity.module.scss';

const formatData = data =>
{
    return data.map( d => (
        {
            ...d,
            createdAt: formatDate( d.createdAt ),
            dateResolved: d.isResolved ? formatDate( d.dateResolved ) : "N/A",
            isRes: d.isResolved,
            isResolved: d.isResolved ? "Yes" : "No",
        }
    ) );
};

const filterStatus = ( statuses ) =>
{
    return statuses.filter( s => s.id === "missing" );
};

const Irregularity = () =>
{
    const dispatch = useDispatch();
    const { orderStatuses } = useSelector( ( state ) => state.orders );

    const [ startDate, setStartDate ] = useState( null );
    const [ endDate, setEndDate ] = useState( null );
    //const [ irregularityType, setIrregularityType ] = useState( "" );
    const [ showEdit, setShowEdit ] = useState( false );
    const [ irregularity, setIrregularity ] = useState( null );
    const [ showResolve, setShowResolve ] = useState( null );
    const [ showCreate, setShowCreate ] = useState( false );

    const [ irrList, setIrrList ] = useState(
        {
            irregularities: [],
            error: null,
            status: "idle"
        }
    );

    const handleIrreg = ( irr, isResolve = false ) =>
    {
        setIrregularity( irr );
        setShowResolve( isResolve );
        setShowEdit( !isResolve );
    };

    const columns = useMemo( () => (
        [
            {
                Header: "ID",
                accessor: "id",
            },
            {
                Header: "Waybill Id",
                accessor: "orderId",
            },
            {
                Header: "Description",
                accessor: "description",
            },
            {
                Header: "Irregularity Type",
                accessor: "irregularityTypeId",
            },
            {
                Header: "Reported on",
                accessor: "createdAt",
            },

            {
                Header: "Staff",
                accessor: "staffName",
            },
            {
                Header: "Is Resolved",
                accessor: "isResolved",
            },
            {
                Header: "Resolution",
                accessor: "resolution",
            },
            {
                Header: "Resolved on",
                accessor: "dateResolved",
            },
            {
                Header: "Action(s)",
                Cell: ( { cell } ) => 
                {
                    const { original } = cell.row;


                    return (

                        <>

                            <button className={ styles.edit } onClick={ () => 
                            {
                                handleIrreg( original );
                            } }>
                                Edit &nbsp; <FaEdit />
                            </button>
                            &nbsp;

                            { !original.isRes ?
                                <button className={ styles.resolve } onClick={ () => 
                                {
                                    handleIrreg( original, true );
                                } }>
                                    Resolve &nbsp; <FaCheck />
                                </button> : null }

                        </>

                    );
                }
            },
        ]
    ), [] );



    const go = () =>
    {
        //console.log( 66 );




        if ( startDate && endDate )
        {

            setIrrList( {
                status: "pending",
                irregularities: [],
                error: null
            } );
            dispatch( userActions.getIrregularities(
                {

                    dateFrom: formatDate( startDate, "yyyy/LL/dd" ),
                    dateTo: formatDate( endDate, "yyyy/LL/dd" ),
                    irregularityType: "",
                    setState: setIrrList
                }
            ) );

        }

    };


    useEffect( () =>
    {
        if ( !orderStatuses.length )
        {
            dispatch( ordersActions.fetchOrderStatuses() );
        }
    }, [ orderStatuses, dispatch ] );

    const { status, irregularities, error } = irrList;

    return (
        <>
            <Header title="Irregularity" />

            <div className={ styles.row }>

                <div className={ styles.dropDown }>
                    <DayPickerInput
                        placeholder="Date From"
                        onDayChange={ ( date ) => setStartDate( date ) }
                    />
                </div>

                <div className={ styles.dropDown }>
                    <DayPickerInput
                        placeholder="Date To"
                        onDayChange={ ( date ) => setEndDate( date ) }
                    />
                </div>

                {/* <select
                    name="irregularityType"
                    id=""
                    value={ irregularityType }
                    className={ styles.select }
                    onChange={ ( e ) => setIrregularityType( e.target.value ) }
                >
                    <option value="">Choose...</option>
                </select> */}

                <button
                    onClick={ go }
                    className={ styles.go }>
                    Go
                </button>

            </div>

            <button
                onClick={ () => setShowCreate( true ) }
                className={ styles.create }>
                Create Irregularity report &nbsp;

                <FaClipboardCheck />
            </button>

            {
                ( status === "resolved" ) && ( !irregularities.length ) ?
                    <EmptyState text="No irregularities" />
                    : null
            }

            {
                ( status === "resolved" ) && ( irregularities.length ) ?



                    <section className={ styles.tableView }>

                        <Table
                            data={ formatData( irregularities ) }
                            paginate
                            bordered
                            columns={ columns }
                        />
                    </section>

                    : null
            }

            {
                ( status === "pending" ) ? <FetchingState />
                    : null
            }

            {
                ( status === "rejected" ) ?
                    <>
                        <h3 style={ { color: "red", padding: "10px" } }> Error: { error }</h3>

                        <button className={ styles.retry } onClick={ go } >
                            Retry
                        </button>
                    </>
                    : null
            }


            <EditIrregularity
                setIsShown={ setShowEdit }
                isShown={ showEdit }
                setIrregularity={ setIrregularity }
                types={ filterStatus( orderStatuses ) }
                irregularity={ irregularity }
                go={ go }
            />


            <CreateIrregularity
                setIsShown={ setShowCreate }
                isShown={ showCreate }
                types={ filterStatus( orderStatuses ) }
                go={ go }
            />

            <ResolveIrregularity
                setIrregularity={ setIrregularity }
                setIsShown={ setShowResolve }
                isShown={ showResolve }
                irregularity={ irregularity }
                go={ go }
            />

        </>
    );
};

export default Irregularity;
