import { Switch, Route, Redirect } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import { Auth, Dashboard } from "./pages";
import { Toaster } from "react-hot-toast";

function ErrorFallback({ error }) {
  return (
    <div role="alert">
      <h3
        style={{
          textAlign: "center",
        }}
      >
        Something went wrong:
      </h3>
      <pre>{error.message}</pre>
    </div>
  );
}

function App() {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Switch>
        <Route path="/auth">
          <Auth />
        </Route>
        <Route path="/dashboard">
          <Dashboard />
        </Route>
        <Route exact path="/">
          <Redirect to="/auth" />
        </Route>
      </Switch>
      <Toaster position="top-right" />
    </ErrorBoundary>
  );
}

export default App;
