import React from "react";
import { Autocomplete, Pane, TextInput } from "evergreen-ui";

function Searchable ( { value, handleChange, options, placeholder } )
{
  const list = options.map( ( o ) =>
  {
    return o.name;
  } );

  const onChange = ( value ) =>
  {
    let selected = options.filter( ( item ) => item.name === value );
    handleChange( selected[ 0 ].id, selected[ 0 ].description );
  };
  return (
    <Autocomplete
      onChange={ ( changedItem ) => onChange( changedItem ) }
      items={ list }
    >
      { ( {
        key,
        getInputProps,
        getToggleButtonProps,
        getRef,
        inputValue,
        openMenu,
        toggleMenu,
      } ) => (
        <Pane key={ key } ref={ getRef } display="flex">
          <TextInput
            flex="1"
            placeholder={ placeholder }
            value={ inputValue }
            onFocus={ openMenu }
            height="40px"
            { ...getInputProps() }
          />
        </Pane>
      ) }
    </Autocomplete>
  );
}

export { Searchable };
