// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_container__2KhW1 {\n  max-width: 1500px;\n  padding: 10px 20px;\n  margin: auto;\n}\n\n.styles_tableHeader__3ceMQ {\n  padding: 20px 25px 10px;\n  font-weight: 500;\n  font-size: 21px;\n}", "",{"version":3,"sources":["webpack://src/pages/Dashboard/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,kBAAA;EACA,YAAA;AACF;;AAEA;EACE,uBAAA;EACA,gBAAA;EACA,eAAA;AACF","sourcesContent":[".container {\n  max-width: 1500px;\n  padding: 10px 20px;\n  margin: auto;\n}\n\n.tableHeader {\n  padding: 20px 25px 10px;\n  font-weight: 500;\n  font-size: 21px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "styles_container__2KhW1",
	"tableHeader": "styles_tableHeader__3ceMQ"
};
export default ___CSS_LOADER_EXPORT___;
