import Axios from "axios";
import { transactionTypes } from "../actionTypes";

const baseUrl = "https://api.abccargoxpress.com/api/v1/";

const fetchAllTransactions = () => async (dispatch) => {
  dispatch({
    type: transactionTypes.LOADING,
    payload: true,
  });
  try {
    const token = localStorage.getItem("pos-token");
    const response = await Axios.get(
      `${baseUrl}Transactions/ViewAllTransactions`,

      { headers: { Authorization: `Bearer ${token}` } }
    );

    dispatch({
      type: transactionTypes.FETCH_TRANSACTIONS,
      payload: response.data,
    });
    dispatch({
      type: transactionTypes.LOADING,
      payload: false,
    });
  } catch (error) {
    dispatch({
      type: transactionTypes.LOADING,
      payload: false,
    });
  }
};

const fetchAllTransactionsByStaff = (body) => async (dispatch) => {
  dispatch({
    type: transactionTypes.LOADING,
    payload: true,
  });
  try {
    const token = localStorage.getItem("pos-token");
    const response = await Axios.post(
      `${baseUrl}Transactions/ListTransactionsByStaff`,
      body,
      { headers: { Authorization: `Bearer ${token}` } }
    );

    dispatch({
      type: transactionTypes.FETCH_TRANSACTIONS_STAFF,
      payload: response.data,
    });
    dispatch({
      type: transactionTypes.LOADING,
      payload: false,
    });
  } catch (error) {
    dispatch({
      type: transactionTypes.LOADING,
      payload: false,
    });
  }
};

const fetchTransactionsByDate = (date) => async (dispatch) => {
  dispatch({
    type: transactionTypes.LOADING,
    payload: true,
  });
  try {
    const token = localStorage.getItem("pos-token");
    const response = await Axios.post(
      `${baseUrl}Transactions/ListByDate`,
      date,
      { headers: { Authorization: `Bearer ${token}` } }
    );

    dispatch({
      type: transactionTypes.FETCH_TRANSACTIONS,
      payload: response.data,
    });
    dispatch({
      type: transactionTypes.LOADING,
      payload: false,
    });
  } catch (error) {
    dispatch({
      type: transactionTypes.LOADING,
      payload: false,
    });
  }
};

const fetchAllTransactionsTally =
  (channelId, staffId, dateTo, dateFrom) => async (dispatch) => {
    try {
      const token = localStorage.getItem("pos-token");
      // let today = new Date();
      // let dd = String( today.getDate() ).padStart( 2, "0" );
      // let mm = String( today.getMonth() + 1 ).padStart( 2, "0" );
      // let yyyy = today.getFullYear();
      // today = mm + "/" + dd + "/" + yyyy;
      const response = await Axios.post(
        `${baseUrl}Transactions/ViewRevenueByChannel`,
        {
          dateFrom,
          dateTo,
          channelId,
          staffId,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      dispatch({
        type: transactionTypes.FETCH_TRANSACTIONS_TALLY,
        payload: { channelId, data: response.data },
      });
    } catch (error) {
      console.log(error);
    }
  };

export const transactionActions = {
  fetchAllTransactions,
  fetchTransactionsByDate,
  fetchAllTransactionsTally,
  fetchAllTransactionsByStaff,
};
