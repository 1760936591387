import React from 'react';
import styles from "./Footer.module.scss";

function Footer () 
{
    return (
        <p className={ styles.Footer }>
            Copyright &copy; 2021 ABC Cargo Express
        </p>
    );
}

export { Footer };
