import Axios from "axios";
import { sessionsTypes } from "../actionTypes";

const baseUrl = "https://abcapi.fusionintel.io/api/v1/";

const fetchAllSessions = (id) => async (dispatch) => {
  dispatch({
    type: sessionsTypes.LOADING,
    payload: true,
  });
  try {
    const token = localStorage.getItem("pos-token");
    const response = await Axios.get(
      `${baseUrl}Sessions/ViewSessionsByStaff?staffId=${id}`,

      { headers: { Authorization: `Bearer ${token}` } }
    );

    dispatch({
      type: sessionsTypes.FETCH_SESSIONS,
      payload: response.data,
    });
    dispatch({
      type: sessionsTypes.LOADING,
      payload: false,
    });
  } catch (error) {
    dispatch({
      type: sessionsTypes.LOADING,
      payload: false,
    });
  }
};

export const sessionsActions = {
  fetchAllSessions,
};
