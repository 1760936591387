import React, { memo, forwardRef } from 'react'
import Icon from '../src/Icon'

const svgPaths16 = [
  'M14 3a1 1 0 11-2 0 1 1 0 012 0zm-3.726 1.254a3 3 0 10-.17-2.039 5.467 5.467 0 00-.51.158c-1.076.394-2.237 1.242-2.575 2.93-.161.809-.664 1.211-1.293 1.443a3 3 0 100 2.508c.629.232 1.132.634 1.293 1.442.338 1.69 1.499 2.537 2.575 2.93.17.063.342.115.51.159a3.001 3.001 0 10.17-2.04c-.629-.231-1.132-.633-1.293-1.441C8.765 9.228 8.216 8.494 7.568 8c.648-.493 1.197-1.228 1.413-2.304.161-.808.664-1.21 1.293-1.442zM13 14a1 1 0 110-2 1 1 0 010 2zM4 8a1 1 0 10-2 0 1 1 0 002 0z'
]
const svgPaths20 = [
  'M18 3a1 1 0 11-2 0 1 1 0 012 0zm-3.82 1.028a6.243 6.243 0 00-1.667.347c-.947.352-1.773 1-2.032 2.318C10.158 8.337 9.247 9.368 8.217 10c1.03.632 1.941 1.663 2.264 3.307.259 1.318 1.085 1.966 2.032 2.318.581.217 1.18.308 1.668.347a3.001 3.001 0 11-.019 2.004c-.633-.042-1.491-.158-2.347-.476-1.402-.523-2.868-1.625-3.296-3.807-.259-1.318-1.085-1.966-2.032-2.318a5.314 5.314 0 00-.722-.21 3 3 0 110-2.33c.238-.052.481-.12.722-.21.947-.352 1.773-1 2.032-2.318.428-2.182 1.894-3.284 3.296-3.807.856-.318 1.714-.434 2.347-.476A3.001 3.001 0 0120 3a3 3 0 01-5.82 1.028zM4 10a1 1 0 100 .002v-.002zM17 18a1 1 0 100-2 1 1 0 000 2z'
]

export const OneToManyIcon = memo(
  forwardRef(function OneToManyIcon(props, ref) {
    return <Icon svgPaths16={svgPaths16} svgPaths20={svgPaths20} ref={ref} name="one-to-many" {...props} />
  })
)
